// Reject common values.
const REJECT_LIST = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999'
]

const verifierDigit = numbers => {
  const numberList = numbers.split('').map(number => parseInt(number, 10))

  const modulus = numberList.length + 1

  const multiplied = numberList.map(
    (number, index) => number * (modulus - index)
  )

  const mod = multiplied.reduce((buffer, number) => buffer + number) % 11

  return mod < 2 ? 0 : 11 - mod
}

export default (cpf, required = true) => {
  if (!cpf && !required) {
    return true
  }

  const stripped = cpf.replace(/[^\d]+/gi, '')

  /**
   * CPF must have 11 chars
   * CPF can't be blacklisted
   */
  if (stripped.length !== 11 || REJECT_LIST.includes(stripped)) {
    return false
  }

  let numbers = stripped.substr(0, 9)
  numbers += verifierDigit(numbers)
  numbers += verifierDigit(numbers)

  return numbers.substr(-2) === stripped.substr(-2)
}
