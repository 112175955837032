var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{ref:"label",class:[
    'checkbox',
    {
      'is--disabled': _vm.disabled,
      'is--checked': _vm.isChecked
    }
  ],attrs:{"disabled":_vm.disabled,"tabindex":_vm.disabled ? false : 0},on:{"click":_vm.focus,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();return _vm.$refs.label.click()}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.computedValue),expression:"computedValue"}],ref:"input",attrs:{"type":"checkbox","id":_vm.$attrs.id,"name":_vm.name,"disabled":_vm.disabled,"required":_vm.required,"true-value":_vm.trueValue,"false-value":_vm.falseValue},domProps:{"value":_vm.nativeValue,"checked":Array.isArray(_vm.computedValue)?_vm._i(_vm.computedValue,_vm.nativeValue)>-1:_vm._q(_vm.computedValue,_vm.trueValue)},on:{"click":function($event){$event.stopPropagation();},"change":[function($event){var $$a=_vm.computedValue,$$el=$event.target,$$c=$$el.checked?(_vm.trueValue):(_vm.falseValue);if(Array.isArray($$a)){var $$v=_vm.nativeValue,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.computedValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.computedValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.computedValue=$$c}},_vm.onHandleChange]}}),_c('span',{staticClass:"check"}),_c('span',{staticClass:"control-label"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }