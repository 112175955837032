<template lang="html">
  <section class="text-input-time input--wrapper">
    <label v-if="label">
      {{ label }} <span class="is--required" v-if="required">*</span>
    </label>

    <div class="input--content">
      <input
        type="time"
        autocomplete="off"
        :disabled="disabled"
        :maxlength="maxlength"
        :placeholder="placeholder"
        v-bind="$attrs"
        v-model="innerValue"
      />

      <i
        v-if="errorMsg"
        :title="errorMsg"
        class="input--warning fas fa-exclamation-circle"
      />
    </div>
  </section>
</template>

<script>
import InputMixin from '../InputMixin'

export default {
  name: 'TextInputTime',
  mixins: [InputMixin],
  inheritAttrs: false
}
</script>
