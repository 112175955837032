// npm i agora-rtc-sdk agora-rtm-sdk
import AgoraRTC from 'agora-rtc-sdk'

import axios from 'axios'
// **************************************************
// **************************************************
const createVideoView = (id, big, isInitial) => {
  let div = document.createElement('div')
  div.className = 'video-view'
  div.setAttribute('id', 'remote_video_panel_' + id)
  div.setAttribute('expand', false)
  if (isInitial) {
    div.setAttribute(
      'style',
      `display: flex;
      min-height: 120px;
      min-width: 50vw;
      max-width: 50vw;
      flex-direction: column;`
    )
    return div
  }
  if (!big) {
    div.setAttribute(
      'style',
      `display: flex;
      width: 140px;
      height: 120px;
      min-height: 120px;
      min-width: 90vw;
      margin: 8px;
      flex-direction: column;
      background-color: #F2F2F2F2;
      border-radius: 4px;
      border: 2px solid #f2f2f2;`
    )
  } else {
    div.setAttribute(
      'style',
      `display: flex;
      min-height: 120px;
      min-width: 90vw;
      flex-direction: column;`
    )
  }

  return div
}

const createVideoPlaceholder = id => {
  let div = document.createElement('div')
  div.className = 'video-placeholder'
  div.setAttribute('id', 'remote_video_' + id)
  div.setAttribute(
    'style',
    `display: flex;
    flex: 1;
    background-color: #808080;`
  )

  return div
}

// **********************************************
// **********************************************
// **********************************************
const join = (obj, rtc, appID, callback) => {
  if (rtc.client) {
    console.error('nao pode fazer 2 join')
  }

  let option = {
    appID: appID,
    channel: obj.canal,
    uid: null,
    token: obj.token
  }
  
  // Create a client
  rtc.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
  
  // Initialize the client
  rtc.client.init(
    option.appID,
    () => {
      rtc.client.join(
        option.token ? option.token : null,
        option.channel,
        option.uid ? option.uid : null,
        uid => {
          rtc.params.uid = uid

          // Create a local stream
          rtc.localStream = AgoraRTC.createStream({
            streamID: rtc.params.uid,
            audio: true,
            video: true,
            screen: false
          })

          // Initialize the local stream
          rtc.localStream.init(
            () => {
              rtc.localStream.play('local_stream')
              rtc.client.publish(rtc.localStream, err => {
                console.error('[AGORA.join - INIT] publish failed')
                console.error(err)
              })

              callback({
                msg: 'inicializou com sucesso',
                code: 'success'
              })
            },
            err => {
              console.error('[AGORA.join - INIT] local stream failed ', err)
              let msg = 'Erro indefinido'
              if (err.info === 'Permission denied') {
                msg =
                  'Não foi concedido permissão para acessar a câmera e/ou microfone'
              } else if (err.info === 'Could not start video source') {
                msg = 'Não foi possível inicializar a câmera e/ou microfone'
              } else if (err.info.includes('Requested device not found')) {
                msg = 'Não foi possível localizar uma câmera e/ou microfone'
              }
              callback({
                code: 'error',
                error: err,
                msg: msg
              })
            }
          )
        }
      )
    },
    err => {
      console.error(err)
      callback({
        msg: 'Erro no join',
        err: err,
        code: 'error'
      })
    }
  )
}
// **********************************************
// **********************************************
const getToken = async obj => {
  try {
    const url = `${process.env.VUE_APP_BACKEND_AGORA_ENDPOINT}/telemedicina/gerar-token`
    const tokenClient = process.env.VUE_APP_PEP_CLI_TOKEN
    const tokenUser = process.env.VUE_APP_PEP_USU_TOKEN

    const body = {
      cli_token: tokenClient,
      usu_token: tokenUser,
      ...obj,
    }
    const response = await axios.post(url, body)
    let token = response.data.token
    return token
  } catch (error) {
    return null
  }
}
// **********************************************
// **********************************************
const cpfClean = (cpf) => {
  let cpfTmp = cpf.replace('.', '')
  cpfTmp = cpfTmp.replace('-', '')
  cpfTmp = cpfTmp.replace('.', '')
  cpfTmp = cpfTmp.trim()


  return cpfTmp
}
// **********************************************
// **********************************************
export default {
  createVideoView,
  createVideoPlaceholder,
  join,
  cpfClean,
  getToken
}
