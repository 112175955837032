<template>

<svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5131 9.67664V4.84004C14.5023 3.44968 13.8938 2.13113 12.8428 1.22107C11.7916 0.31096 10.3996 -0.102868 9.02208 0.0853444C7.64452 0.273552 6.41451 1.04548 5.646 2.20405L14.3436 10.926C14.4578 10.5194 14.5149 10.0989 14.5129 9.67659L14.5131 9.67664Z" fill="#6C6C6C"/>
<path d="M17.7374 9.6766C17.7374 9.38853 17.5838 9.12242 17.3344 8.97847C17.085 8.83453 16.7777 8.83453 16.5283 8.97847C16.2789 9.12242 16.1252 9.38854 16.1252 9.6766C16.1236 10.541 15.9482 11.3964 15.6094 12.1917L16.8185 13.4009C17.4249 12.2533 17.7405 10.9745 17.7374 9.6766Z" fill="#6C6C6C"/>
<path d="M20.7283 19.5833L4.95273 3.80815L1.3818 0.237042C1.17722 0.0326384 0.879255 -0.047253 0.599976 0.027599C0.32054 0.102451 0.102451 0.320531 0.0275992 0.599976C-0.0472529 0.879232 0.0326374 1.17719 0.237042 1.3818L4.83992 5.98467V9.67652C4.84082 10.8648 5.27931 12.0112 6.07136 12.897C6.8636 13.7826 7.95418 14.3458 9.13491 14.4788C10.3158 14.6119 11.5043 14.3057 12.4737 13.6183L13.6264 14.7709V14.7711C12.3488 15.7609 10.7462 16.235 9.1356 16.0996C7.5252 15.9641 6.02419 15.2287 4.9302 14.0392C3.83602 12.8496 3.22841 11.2927 3.22767 9.67651C3.22767 9.38844 3.07401 9.12233 2.82462 8.97838C2.57523 8.83444 2.26791 8.83444 2.01852 8.97838C1.76914 9.12233 1.61547 9.38845 1.61547 9.67651C1.61547 11.6749 2.35768 13.6019 3.6982 15.0838C5.03872 16.5659 6.88193 17.4971 8.8706 17.697V20.9617H5.6462C5.35813 20.9617 5.09202 21.1153 4.94807 21.3647C4.80413 21.6141 4.80413 21.9214 4.94807 22.1708C5.09202 22.4202 5.35814 22.5739 5.6462 22.5739H13.7072C13.9953 22.5739 14.2614 22.4202 14.4053 22.1708C14.5493 21.9214 14.5493 21.6141 14.4053 21.3647C14.2614 21.1153 13.9953 20.9617 13.7072 20.9617H10.4828V17.697C12.0581 17.5404 13.5523 16.9238 14.7792 15.9236L19.6158 20.7602C19.7673 20.9127 19.9733 20.9986 20.1882 20.9986C20.4032 20.9986 20.6092 20.9127 20.7606 20.7602C20.9193 20.6033 21.0058 20.3877 20.9997 20.1647C20.9936 19.9416 20.8953 19.7311 20.7284 19.5832L20.7283 19.5833Z" fill="#6C6C6C"/>
</svg>

</template>

<script>
export default {
  name: 'IconBtnMicroOff',
  components: {
  },

  data: () => {
    return {
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>
