<template>
  <section class="terms-section">
    <iframe v-if="consentForm" :src="consentForm" frameborder="0"></iframe>
    <div v-else>Termo não encontrado</div>
  </section>
</template>

<script>
export default {
  name: 'ConsentTermIframe',
  data() {
    return {
    };
  }
};
</script>

<style src="./Term.scss" lang="scss"></style>
