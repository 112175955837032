<style src="./PatientUserInfo.scss" lang="scss" scoped></style>
<template>
  <section class="patient-schedule">
    <!-- HEADER -->
    <PatientHeader ref="PatientHeader" />
    <!-- CONTENT -->

    <section class="title">
      <span
        :style="{ 'text-decoration': 'underline', cursor: 'pointer' }"
        @click="onClickBack"
      >
        Início
      </span>
      <IconArrow />
      <span>Editar meus dados</span>
    </section>

    <section class="content">
      <section class="inputs">
        <InputFileProfile
          class="ui-input"
          @change="onChangeBase64"
          @remove="onRemoveImg"
          :fileBase64="form.file"
        />
      </section>
      <section class="inputs">
        <TextInput
          class="ui-input"
          label="Nome Social"
          required
          maxlength="100"
          v-model="form.name"
        />
        <TextInputDate
          class="ui-input"
          label="Data de Nascimento"
          mask="##/##/####"
          required
          :today="false"
          v-model="form.date"
        />
      </section>
      <section class="inputs">
        <SelectInput
          class="ui-input"
          required
          ref="SelectSex"
          label="Sexo"
          :options="optSex"
          v-model="form.sex"
        />
        <TextInput
          class="ui-input"
          label="CPF"
          disabled
          mask="###.###.###-##"
          v-model="form.cpf"
        />
      </section>
      <section class="inputs">
        <TextInput
          class="ui-input"
          label="E-mail"
          required
          maxlength="100"
          v-model="form.email"
        />
        <TextInput
          class="ui-input"
          label="Telefone"
          mask="(##) #####-####"
          required
          v-model="form.phone"
        />
      </section>
    </section>
    <!--  -->
    <section class="footer">
      <div>
        <PlButton label="" primary arrowLeft @click="onClickBack" />
      </div>
      <div class="footer-flex">
        <PlButton label="Cancelar" gray cross @click="onClickBack" />
        <PlButton label="Salvar" :disabled="disableButton" primary save @click="onClickUpdate" />
      </div>
      <!--  -->
    </section>
  </section>
</template>

<script>
import moment from "moment";
import { ServicoHttp } from "@/axios/servico-http";
import { ServicoHttpCallingCircle } from "@/axios/servico-http-calling-circle";
import { ServicoAll } from "@/axios/servico-all";
import MovementLogs from "@/helpers/movement_logs";

import IconArrow from "@/assets/icons/IconArrow";
import PatientHeader from "../components/header/PatientHeader";

import { TextInput, TextInputDate } from "@/components/inputs";
import PlButton from "@/components/button/Button";
import InputFileProfile from "@/components/input/file-profile/InputFileProfile";
import SelectInput from "@/components/select-input/SelectInput.vue";

export default {
  name: "PatientFiles",
  components: {
    InputFileProfile,
    TextInputDate,
    SelectInput,
    PlButton,
    TextInput,
    PatientHeader,
    IconArrow,
  },

  watch: {},

  data() {
    return {
      optSex: [
        { name: "Feminino", value: "f" },
        { name: "Masculino", value: "m" },
      ],
      msg: null,
      patient: null,
      form: {
        name: null,
        date: null,
        sex: null,
        cpf: null,
        email: null,
        phone: null,
        file: null,
      },
      disableButton: false,
    };
  },

  async mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.servicoHttpCallingCircle = new ServicoHttpCallingCircle(this);

    this.sPepPacUpdate = new ServicoAll(
      "/api/pep/paciente/update",
      this.servicoHttp
    );

    this.apiCCUpdatePatientSpecificKey = new ServicoAll(
      "/paciente/update/cpf",
      this.servicoHttpCallingCircle
    );

    this.sGetInfoPatientByPatientId = new ServicoAll(
      "/api/pep/paciente/get-by-id",
      this.servicoHttp
    );

    this.patient = await this.$store.dispatch("getLocalStoragePatient");

    let infoPatient = await this.sGetInfoPatientByPatientId.get({
      pac_id: this.patient.pac_id
    })
    if (infoPatient.data.length > 0) {
      infoPatient = infoPatient.data[0]
      await this.$store.dispatch("setLocalStoragePatient", infoPatient);
      this.patient = { ...infoPatient }
    }

    const objSex =
      this.patient.usu_genero === "f"
        ? { name: "Feminino", value: "f" }
        : { name: "Masculino", value: "m" };

    this.form = {
      name: this.patient.pac_nome,
      date: moment(this.patient.usu_nascimento, `YYYY-MM-DD`),
      sex: objSex,
      cpf: this.patient.usu_cpf,
      email: this.patient.usu_email,
      phone: this.patient.usu_telefone,
      file: this.removeCacheFromImage(this.patient.usu_imagem_url),
    };

    // ! Firebase LOG
    const objLog = { ...this.patient }
    const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
    MovementLogs.saveLog(1500, timeAction, objLog)
    // this.fetchAndEncode(this.form.file)
  },

  methods: {
    removeCacheFromImage(url) {
      if (!url) {
        return null
      }
      
      const img = document.getElementById('myImage');
      const timestamp = new Date().getTime();
      return url + `?cache_buster=${timestamp}`
    },

    async onClickUpdate() {
      try {
        if (!this.form.name) {
          this.msg = "O campo Nome Social é obrigatório";
          this.$toast.info(this.msg);
          return false;
        }

        if (this.form.name.length < 3) {
          this.msg = "O campo Nome Social deve ter mais de duas letras";
          this.$toast.info(this.msg);
          return false;
        }

        const regex = /[^0-1a-zA-ZÀ-ÖØ-öø-ÿçÇ\s]/g
        const regexEmoji = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g

        const hasEspecialCharacters = regex.test(this.form.name)
        const hasEmoji = regexEmoji.test(this.form.name)

        const isNotValidString = hasEspecialCharacters || hasEmoji

        if (isNotValidString) {
          this.msg = "O campo Nome Social não aceita caracteres especiais";
          this.$toast.info(this.msg);
          return false;
        }

        if (!this.form.date) {
          this.msg = "O campo Data de Nascimento é obrigatório";
          this.$toast.info(this.msg);
          return false;
        }
        if (!moment(this.form.date, `YYYY-MM-DD`).isValid()) {
          this.msg = "O campo Data de Nascimento está inválido";
          this.$toast.info(this.msg);
          return false;
        }
        if (
          moment(this.form.date, `YYYY-MM-DD`).isValid() &&
          !moment(this.form.date, `YYYY-MM-DD`).isAfter("1900-01-01")
        ) {
          this.msg = "O campo Data de Nascimento está inválido";
          this.$toast.info(this.msg);
          return false;
        }
        if (!this.form.sex) {
          this.msg = "O campo Sexo é obrigatório";
          this.$toast.info(this.msg);
          return false;
        }
        if (!this.form.cpf) {
          this.msg = "O campo CPF é obrigatório";
          this.$toast.info(this.msg);
          return false;
        }
        if (!this.validCPF(this.form.cpf)) {
          this.msg = "O campo CPF está inválido";
          this.$toast.info(this.msg);
          return false;
        }
        if (!this.form.email) {
          this.msg = "O campo E-mail é obrigatório";
          this.$toast.info(this.msg);
          return false;
        }
        if (!this.validEmail(this.form.email)) {
          this.msg = "O campo E-mail está inválido";
          this.$toast.info(this.msg);
          return false;
        }
        if (!this.form.phone) {
          this.msg = "O campo Telefone é obrigatório";
          this.$toast.info(this.msg);
          return false;
        }
        if (!this.validPhone(this.form.phone)) {
          this.msg = "O campo Telefone está inválido";
          this.$toast.info(this.msg);
          return false;
        }

        this.disableButton = true;

        const obj = {
          pac_id: this.patient.pac_id,
          pac_nome: this.form.name,
          usu_id: this.patient.usu_id,
          usu_nascimento: moment(this.form.date).format(`YYYY-MM-DD`),
          usu_genero: this.form.sex.value,
          usu_cpf: this.form.cpf.replace(/\D/g, ""),
          usu_email: this.form.email,
          usu_telefone: this.form.phone.replace(/\D/g, ""),
          file: this.form.file,
          usu_alterado_em: moment().format("YYYY-MM-DD HH:mm:ss"),
        };

        const ret = await this.sPepPacUpdate.post(obj);
        // ! Firebase LOG
        const objLog = { ...obj }
        const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
        MovementLogs.saveLog(1501, timeAction, objLog)

        this.apiCCUpdatePatientSpecificKey.post({
          cpf: this.patient.usu_cpf,
          key: 'pac_nome',
          value: obj.pac_nome
        })
        this.apiCCUpdatePatientSpecificKey.post({
          cpf: this.patient.usu_cpf,
          key: 'usu_cpf',
          value: obj.usu_cpf
        })
        this.apiCCUpdatePatientSpecificKey.post({
          cpf: this.patient.usu_cpf,
          key: 'pac_genero',
          value: this.form.sex.name
        })
        const birthDate = moment(this.form.date, 'YYYY-MM-DD');
        const age = moment().diff(birthDate, 'years');
        console.log(moment(this.form.date).format(`YYYY-MM-DD`))
        this.apiCCUpdatePatientSpecificKey.post({
          cpf: this.patient.usu_cpf,
          key: 'pac_idade',
          value: age
        })

        if (ret.data.status === true) {
          this.msg = "Informações atualizadas com sucesso";
          this.$toast.success(this.msg);
          this.patient = {
            ...this.patient,
            ...obj,
          };
          if (this.form.file) {
            this.patient.usu_imagem = this.form.file;
          }
          await this.$store.dispatch("setLocalStoragePatient", this.patient);
          this.$refs.PatientHeader.update();
        } else {
          this.msg = "Não foi possível atualizar informações";
        }
      } catch (err) {
        this.$toast.error(this.getErrorMessage(err), { duration: 3000 });
      } finally {
        setTimeout(()=> {
          this.disableButton = false;
        }, 1600)
      }
    },
    onClickBack() {
      // ! Firebase LOG
      const objLog = { ...this.patient }
      const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
      MovementLogs.saveLog(1502, timeAction, objLog)

      this.$router.push({ name: "pacienteHome" });
    },
    // *********
    // * ARQUIVOS
    onChangeBase64(val) {
      this.form.file = val;
    },
    onRemoveImg(objRemove) {
      this.form.files = this.form.files.filter(
        (_, index) => objRemove.idx !== index
      );
    },
    fetchAndEncode(img) {
      fetch(img)
        .then((response) => response.blob())
        .then((blob) => this.blobToBase64(blob))
        .then((base64) => (this.form.file = base64))
        .catch((error) =>
          console.error("Error fetching or encoding image:", error)
        );
    },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(",")[1]);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },
    // *** *** ***
    // *** *** ***
    // *** *** ***
    validCPF(cpf) {
      const cleanCpf = cpf.replace(/\D/g, "");
      if (cleanCpf.length !== 11) {
        return false;
      }
      if (/^(\d)\1+$/.test(cleanCpf)) {
        return false;
      }
      let sum = 0;
      for (let i = 0; i < 9; i++) {
        sum += parseInt(cleanCpf.charAt(i)) * (10 - i);
      }
      let rest = 11 - (sum % 11);
      rest = rest === 10 || rest === 11 ? 0 : rest;
      if (rest !== parseInt(cleanCpf.charAt(9))) {
        return false;
      }
      sum = 0;
      for (let i = 0; i < 10; i++) {
        sum += parseInt(cleanCpf.charAt(i)) * (11 - i);
      }
      rest = 11 - (sum % 11);
      rest = rest === 10 || rest === 11 ? 0 : rest;
      return rest === parseInt(cleanCpf.charAt(10));
    },

    validEmail(email) {
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regexEmail.test(email);
    },

    validPhone(phone) {
      const cleanNum = phone.replace(/\D/g, "");
      if (cleanNum.length !== 11) {
        return false;
      }
      const ddd = cleanNum.substring(0, 2);
      const num = cleanNum.substring(2);
      if (num.charAt(0) !== "9") {
        return false;
      }
      return true;
    },
  },
};
</script>
