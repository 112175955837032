export const html = `
<div>
<p><h2>TERMOS E CONDIÇÕES DE USO – MEDICAR</h2></p>
<p>Estes Termos e Condições de Uso que dispõem acerca da utilização da Plataforma MEDICAR devem ser lidas com atenção.


	<p></p>•	CONCORDÂNCIA COM OS TERMOS E CONDIÇÕES DE USO

	<p></p>•	O uso da Plataforma MEDICAR, ainda que parcialmente ou a título de teste, significará que o Usuário está plenamente ciente de todas as disposições, significando também plena e integral concordância com os Termos e Condições de Uso aqui apresentadas.


	<p></p>•	Uma vez que o uso da Plataforma MEDICAR apenas pode ser utilizada após a leitura destes Termos e Condições de Uso, restando claro que sua utilização significará a concordância integral com os Termos e Condições de Uso apresentados. Caso o Usuário não concorde com qualquer uma das disposições nestes Termos e Condições de Uso, ele não deverá utilizar a Plataforma MEDICAR. Sendo assim, fica claro e evidente que o uso da Plataforma MEDICAR não está autorizado em caso de não concordância com estes Termos e Condições de Uso.


	<p></p>•	Caso o Usuário venha a discordar dos Termos e Condições de Uso posteriormente ao início da utilização da Plataforma MEDICAR, o uso deste deverá ser imediatamente interrompido, sendo certo que estes Termos e Condições de Uso terão vigência durante todo o período de uso, não cabendo ao Usuário alegar o desconhecimento destes Termos e Condições de Uso.


	<p></p>•	DEFINIÇÕES
<p>
<p></p>Os termos aqui relacionados, no singular ou no plural terão o seguinte significado:

<<p></p>Assinatura Mensal: modalidade de adesão do Usuário aos Serviços da MEDICAR, cujos valores estarão dispostos em local visível na Plataforma MEDICAR.

<p></p>MEDICAR: Plataforma de telemedicina já qualificada no início destes Termos e Condições de Uso.

<p></p>Instituições: instituições da área de saúde, tais como consultórios, clínicas, hospitais e convênios que firmaram contrato com a MEDICAR, de modo a disponibilizar a utilização da Plataforma MEDICAR aos seus Profissionais de Saúde e a outras pessoas que a eles se vinculem.


<p></p>Plataforma MEDICAR: é o Software as a Service (SaaS) de titularidade da MEDICAR, denominado “MEDICAR”, disponibilizado em forma de plataforma web ou de Aplicativo, que possibilita aos Profissionais de Saúde vinculados às Instituições a prática da Telemedicina no atendimento aos Usuários, por meio de Teleconsultas de vídeo, voz e chat.


<p></p>Profissionais de Saúde: Médicos e demais profissionais da saúde devidamente registrados em seus respectivos órgãos/conselhos de classe, vinculados a uma Instituição que contratam com a MEDICAR a fim de prestar serviços de Teleconsulta aos Usuários.


<p></p>Serviços: Serviços de licenciamento e intermediação voltados à facilitação da interconexão online entre Instituições, Profissionais de Saúde a elas vinculados e os Usuários, por meio da Plataforma MEDICAR para a oferta de Teleconsulta na modalidade de Assinatura Mensal ou de Teleconsulta Avulsa.

<p></p>Teleconsulta: modalidade de consulta médica realizada à distância, com o auxílio da tecnologia da MEDICAR.

<p></p>Teleconsulta Avulsa: modalidade de consulta médica realizada à distância, com o auxílio da tecnologia da MEDICAR, por meio dos Profissionais de Saúde diretamente vinculados à Instituições, de modo alternativo e não vinculado à Assinatura Mensal, cujos valores estarão dispostos em local visível na Plataforma MEDICAR.

<p></p>Telemedicina: o exercício da medicina mediado por Tecnologias Digitais, de Informação e de Comunicação (TDICs), para fins de assistência, educação, pesquisa, prevenção de doenças e lesões, gestão e promoção de saúde, nos termos do art. 1º, Resolução CFM nº 2.314, de 20 de Abril de 2022.

<p></p>Termos e Condições de Uso: o presente documento, que regula o uso da Plataforma MEDICAR, por meio do qual o Usuário expressa sua concordância, bem como contém informações diversas.

<p></p>Usuário: é a pessoa física que possui acesso à Plataforma MEDICAR.

	<p></p>•	MODALIDADE DE SERVIÇOS

	<p></p>•	Os Serviços prestados pela MEDICAR são realizados e oferecidos na modalidade de Assinatura Mensal e de Teleconsulta Avulsa ao Usuário, cujas condições e valores estarão disponíveis em local apropriado e acessíveis ao Usuário na Plataforma MEDICAR.

	<p></p>•	A MEDICAR poderá incluir nos Serviços, produtos ou serviços adicionais e/ou complementares aos pactuados, durante a vigência da Assinatura Mensal.

	<p></p>•	Os serviços adicionais e/ou complementares poderão ser gratuitos ou não, sendo certo que aqueles não gratuitos somente poderão ser inclusos nos Serviços mediante a concordância expressa do Usuário.

	<p></p>•	O acesso, pelo Usuário, às Teleconsultas oferecidas pelos Profissionais de Saúde vinculados às Instituições, na modalidade de Assinatura Mensal, se dará de forma ilimitada, ressalvado o direito de a MEDICAR advertir o Usuário que utilizar a plataforma MEDICAR de forma indevida ou com abuso de direito.

	<p></p>•	LICENÇA DE USO

	<p></p>•	Estes Termos e Condições de Uso regulamentam a forma pela qual a MEDICAR concede ao Usuário uma licença de uso da Plataforma MEDICAR, em caráter não exclusivo e intransferível, o qual deverá ser utilizado em conformidade com as regras presentes destes Termos e Condições de Uso.


	<p></p>•	A Plataforma MEDICAR não é, em hipótese alguma, vendida para o Usuário, e sim licenciada para o uso do Usuário, em conformidade com a legislação brasileira. Sua propriedade não será transferida a quem quer que seja sem consentimento prévio, expresso e específico da MEDICAR. Assim sendo, é vedada a cessão e/ou comercialização da Plataforma MEDICAR, seja total ou parcialmente, a terceiros, sob pena de responsabilização por perdas e danos.

	<p></p>•	Por adquirir apenas uma licença de uso, o Usuário em nenhuma hipótese, terá acesso ao código fonte dos Softwares da Plataforma MEDICAR.

	<p></p>•	O uso da Plataforma MEDICAR deverá observar estritamente todos os termos e disposições dos presentes Termos e Condições de Uso. Em caso de uso não autorizado nestes Termos e Condições de Uso, o Usuário estará sujeito a todas as penalidades previstas nestas condições gerais e na legislação brasileira, assim como ao pagamento de eventuais perdas e danos que a MEDICAR vier a sofrer.


	<p></p>•	O uso da Plataforma MEDICAR está condicionado a uma conexão de internet ativa do Usuário, sendo que a MEDICAR não se responsabiliza pela prestação de serviços de acesso da internet, sendo de total responsabilidade do Usuário possuir internet com capacidade de conexão.


	<p></p>•	A MEDICAR não se responsabilizará por impossibilidade de utilização da Plataforma MEDICAR em caso de falta de conexão com a internet, seja esta falha momentânea ou não.

	<p></p>•	CADASTRO

    <p></p>5.1 O acesso à Plataforma MEDICAR será realizado pelo Usuário através de uma conta, com login e senha, a qual será criada pelo próprio Usuário, sendo a conta criada de uso pessoal e intransferível.

	<p></p>•	Ao se cadastrar, você autoriza o tratamento dos seus dados pessoais, apenas os necessários para a execução dos serviços, além disso ao anexar sua imagem, esta poderá ser utilizada para fins de identificação dentro do aplicativo. Seus dados de saúde somente serão compartilhados com a sua permissão e serão armazenados com segurança, em conformidade com nossa Política de Privacidade e a Lei Geral de Proteção de Dados Pessoais
– Lei nº 13.709/2018.

	<p></p>•	O cadastro e a utilização da Plataforma MEDICAR deverão ser realizados com informações, declarações e documentos válidos, completos e atualizados, responsabilizando-se a Instituição e o Usuário, civil e criminalmente, pela veracidade e precisão das informações, isentando a MEDICAR e os parceiros desta por qualquer dano ou prejuízo que a falta de informações corretas causar.

	<p></p>•	O Usuário sempre será integralmente responsável pela utilização de sua conta na Plataforma MEDICAR, devendo adotar as medidas de segurança necessárias para impedir o uso por terceiros não autorizados. Caso suspeitem que um terceiro esteja utilizando indevidamente sua conta na Plataforma MEDICAR, deverá efetuar a imediata troca de sua senha pessoal e comunicar tal fato à MEDICAR.

	<p></p>•	O uso da Plataforma MEDICAR de forma indevida, irregular, ilegal ou em desacordo às condições previstas neste Termos e Condições de Uso implicará a exclusão do cadastro e a rescisão imediata do acesso do Usuário à Plataforma MEDICAR, sujeito ainda às cominações legais.

	<p></p>•	O Usuário ou a Instituição deverá indenizar a MEDICAR seus sócios, colaboradores, empregados, clientes e/ou terceiros prejudicados por qualquer perda, reclamação ou demanda, bem como por todos e quaisquer prejuízos resultantes da utilização indevida da Plataforma MEDICAR em discordância com o disposto no presente instrumento e na legislação aplicável.

	<p></p>•	MEIOS DE PAGAMENTO

	<p></p>•	A MEDICAR aceita pagamentos através de cartões de crédito, dentre outros de livre escolha da MEDICAR que poderão estar disponíveis no site ou Plataforma MEDICAR no momento da realização do pagamento pelo Usuário.


	<p></p>•	O acesso à Plataforma MEDICAR estará disponível a partir do momento em que a MEDICAR verificar o pagamento feito pelo Usuário. Se o pagamento não puder ser verificado, por qualquer motivo, a MEDICAR poderá, a seu critério, decidir não permitir o acesso do Usuário à Plataforma MEDICAR até que possa verificar o pagamento efetuado pelo Usuário. No caso de não confirmação do pagamento, a MEDICAR poderá comunicar o Usuário por e- mail, SMS ou “push”, ferramenta de comunicação direta do app com o usuário.

	<p></p>•	REQUISITOS MÍNIMOS PARA UTILIZAÇÃO DA PLATAFORMA CLICK LIFEE

	<p></p>•	Para utilizar a Plataforma MEDICAR, o Usuário deverá atender as exigências mínimas de configuração  de  equipamento  (hardware),  de  sistema  operacional  (software),  devendo ainda possuir uma conexão ativa com a Internet.

	<p></p>•	A exigência para utilização do Software é o acesso à Plataforma MEDICAR por plataforma web ou por Aplicativo de propriedade da MEDICAR em uma Loja de Aplicativos Móveis, seja a Apple Store, Google Play ou qualquer outra loja onde o Aplicativo do Usuário esteja disponível para download .

	<p></p>•	SUPORTE TÉCNICO

	<p></p>•	A MEDICAR dará suporte técnico relativo ao Software remotamente, por telefone e pela Internet, no horário comercial, isto é, das 08h00min às 18h00min, em dias úteis, de segunda à sexta-feira, excluídos vésperas de feriados e feriados.


	<p></p>•	Para os fins destes Termos e Condições de Uso, serão considerados os dias úteis do Município de Vitória/ES.


	<p></p>•	Eventuais atendimentos solicitados fora do horário padrão de atendimento, isto é, dias úteis,  das  08h00min  às  18h00min,  estarão  sujeitos  à  disponibilidade  da  MEDICAR  e  ao pagamento de quantia adicional, a ser devidamente informada na ocasião da solicitação do serviço.


	<p></p>•	O serviço de suporte técnico consistirá em técnicos treinados para solucionar dúvidas e orientar o Usuário na operação da Plataforma MEDICAR.

	<p></p>•	PROPRIEDADE INTELECTUAL

	<p></p>•	O Usuário não adquire, pela utilização da Plataforma MEDICAR, qualquer direito de propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, marcas, direitos autorais ou quaisquer direitos sobre informações confidenciais ou segredos de negócio, bem como todo o conteúdo disponibilizado no Site, incluindo, mas não se limitando a textos, gráficos, imagens, logotipos, ícones, fotografias, conteúdo editorial e qualquer outro material relacionado a Plataforma MEDICAR.

	<p></p>•	Todo o conteúdo disponibilizado na Plataforma MEDICAR, incluindo, mas não se limitando a, textos, gráficos, imagens, logos, ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material é de propriedade exclusiva da MEDICAR ou está devidamente licenciado por esta, não podendo ser copiado, plagiado, reproduzido ou publicado em qualquer meio (físico ou virtual).


	<p></p>•	É vedado ao Usuário modificar, copiar, distribuir, transmitir, exibir, realizar, reproduzir, publicar, disponibilizar, licenciar ou criar obras derivadas a partir das informações coletadas na Plataforma MEDICAR, bem como transferir ou utilizar para fins comerciais tais informações,

softwares, produtos ou serviços, sob pena de violação deste instrumento e caracterização de infração legal.

	<p></p>•	A MEDICAR reserva a si todos os direitos da Plataforma MEDICAR.

	<p></p>•	É expressamente proibida a utilização de ferramentas, scripts, softwares, robôs ou meios que contenham qualquer vírus, worms, malwares, trojans e/ou outros programas de computador que possam causar danos a Plataforma MEDICAR.

	<p></p>•	ATUALIZAÇÃO

	<p></p>•	A Plataforma MEDICAR, embora com garantia de bom funcionamento, está sujeita a constantes modificações, decorrentes de otimizações e aperfeiçoamentos, que visam facilitar e tornar mais segura e eficiente a sua operação, melhor aproveitamento dos recursos disponibilizados, bem como efetuar as alterações pertinentes por conta das modificações na legislação.

	<p></p>•	O Usuário, enquanto durar sua licença de uso, terá direito de acessar as novas versões da Plataforma MEDICAR.

	<p></p>•	O Usuário desde já concorda com as modificações, melhorias ou correções no modelo de Software utilizado pela MEDICAR, ficando a critério da MEDICAR o gerenciamento e implementação dessas atualizações.

	<p></p>•	ACESSO E RESTRIÇÕES DE FUNCIONALIDADE

	<p></p>•	Não é permitido acessar as áreas de programação da Plataforma MEDICAR, seu banco de dados ou qualquer outro conjunto de informações que faça parte da programação do sistema, realizar ou permitir que se realize qualquer tipo de engenharia reversa, tradução, decompilação, cópia, modificação, reprodução, locação, sublocação, sublicenciamento, publicação, divulgação, transmissão, empréstimo, distribuição ou, de qualquer outra forma, a disposição de ferramentas de consulta desta plataforma e de suas funcionalidades para terceiros sem a prévia e expressa autorização da MEDICAR, ficando sujeito quem o fizer à responsabilização criminal e civil sob a legislação brasileira, sem prejuízo da obrigação de reparar os danos que causarem. Esta restrição inclui qualquer tentativa de incorporar quaisquer informações da Plataforma MEDICAR em qualquer outro diretório, produto ou serviço.


	<p></p>•	É proibida a utilização de programas do tipo spider/crawler ou de mineração de dados, que tenham como finalidade a quebra de sistemas de segurança, senha, captcha, ou ainda de qualquer tipo ou espécie correlato, além de outros aqui não tipificados, mas que atue de modo automatizado, tanto para realizar operações massificadas ou para quaisquer outras finalidades, sob pena de aplicação da legislação correlata de proteção dos interesses da MEDICAR, sem prejuízo da obrigação de reparar quaisquer perdas e danos eventualmente apurados e/ou suportados pela MEDICAR e que decorrerem desta utilização indevida.

	<p></p>•	É proibida ainda a coleta de quaisquer informações de identificação pessoal e cadastro de outros Usuários da Plataforma MEDICAR para quaisquer fins.

	<p></p>•	OBRIGAÇÕES DO USUÁRIO

São obrigações do Usuário, sem prejuízo das demais previstas nestes Termos e Condições de Uso e na legislação pertinente:

	<p></p>•	O Usuário se obriga a:

	<p></p>•	respeitar às disposições deste Termo e as leis que regem a utilização deste, exclusivamente empregando-os para a finalidade para qual foi destinado o serviço;

	<p></p>•	não divulgar a terceiros seus dados de acesso, nem permitir o uso de tais informações por terceiros, responsabilizando-se pelas consequências do uso de seu cadastro, uma vez que estes são pessoais e intransferíveis, devendo notificar a MEDICAR imediatamente, através de meio seguro, a respeito de qualquer uso ou acesso não autorizado de sua conta por terceiros;

	<p></p>•	para que ocorra a Teleconsulta com segurança, o Usuário deverá estar conectado à rede de internet segura, num local iluminado, silencioso e privado. A relação do Profissional de Saúde com o Usuário deve ser preservada e, caso haja quebra desta relação, seja por comportamento ou ambiente inadequado, o Profissional de Saúde poderá interromper a Teleconsulta a qualquer momento.

	<p></p>•	Utilizar a Plataforma MEDICAR exclusivamente ao fim a que ele se destina, abstendo-se de trafegar e armazenar dados e informações que importem em prejuízo a terceiros; violem a lei, a moral, os bons costumes; a propriedade intelectual; que incorporem vírus ou outros elementos físicos ou eletrônicos que possam danificar ou impedir o seu uso normal;


	<p></p>•	Utilizar somente equipamentos hábeis e capazes de atender às exigências mínimas de configuração necessárias à utilização do software, bem como pelo manuseio correto do programa;


	<p></p>•	Não acessar a Plataforma MEDICAR sob informações de outra pessoa, utilizando CPF e número de Celular de outro Usuário que não seja responsável legal. Caso tenha acesso a dados de Usuário, vedar o uso da Plataforma MEDICAR, saindo imediatamente da área restrita do Usuário.


	<p></p>•	Não acessar, desconstituir, alterar, modificar, seccionar ou de qualquer forma adulterar os códigos fonte da Plataforma MEDICAR.


	<p></p>•	Manter o cadastro de seu CPF e Número de Celular atualizados, de forma a impossibilitar o acesso de outra pessoa ao seu cadastro de Usuário;


	<p></p>•	Manter seu computador ou dispositivo móvel livre de vírus ou similares, propiciando assim maior segurança ao tráfego e armazenamento dos dados/informações;

	<p></p>•	Manter os seus dados cadastrais devidamente atualizados, sob pena de, não o fazendo, considerarem-se válidos todos os avisos e notificações enviados para os endereços constantes do referido cadastro;

	<p></p>•	Autorizar que a MEDICAR proceda as necessárias modificações, reparações e implementações de novos recursos à Plataforma MEDICAR, caso este apresente alguma anomalia ou irregularidade, de modo a torná-la mais segura, eficiente e eficaz, mesmo que acarrete sua indisponibilidade temporária;

	<p></p>•	Reconhecer que todas as modificações, melhorias e correções efetuadas na Plataforma MEDICAR serão de propriedade da MEDICAR, sendo, portanto, objeto de licença de uso, nos mesmos termos e sujeitando-se aos presentes Termos e Condições de Uso, podendo inclusive ser disponibilizadas pela MEDICAR a outros Usuários que utilizem a Plataforma MEDICAR;

	<p></p>•	GARANTIAS E RESPONSABILIDADES

	<p></p>•	A MEDICAR assegura a funcionalidade da Plataforma MEDICAR, desde que devidamente utilizado pelo Usuário de acordo com as instruções da MEDICAR, pelo período que durar a licença de uso contratada pelo Usuário


	<p></p>•	A mencionada garantia não será aplicável nos casos em que qualquer não conformidade ou defeito for exclusivamente causado por:


	<p></p>•	Qualquer programa de computador associado ou complementar cujo uso conjunto não tenha sido expressamente autorizado pela MEDICAR;

	<p></p>•	Modificação não autorizada ou uso da Plataforma MEDICAR em desacordo com as regras e disposições destes Termos e Condições de Uso e/ou das instruções que sejam fornecidas pela MEDICAR;

	<p></p>•	A MEDICAR não se responsabiliza por danos causados ao sistema por vírus de computador, falhas de energia elétrica, interrupções na conexão da Internet, atualizações ou falhas inerentes ao servidor de hospedagem terceirizado ou qualquer outra causa que não seja associada diretamente à MEDICAR.


	<p></p>•	A MEDICAR não garante que a Plataforma MEDICAR está livre de erros. A MEDICAR, no entanto, envidará seus maiores esforços para sanar qualquer erro de programação que a Plataforma MEDICAR venha a apresentar.

	<p></p>•	Assume-se, para efeito destas condições gerais, e o Usuário expressamente concorda, a existência de um consenso mundial acerca de não haver programa de computador totalmente isento de erros ou totalmente invulnerável; à luz de tal consenso, na hipótese da ocorrência de qualquer defeito no software, a MEDICAR se reserva o direito de ser chamada para tentar resolvê-lo antes que lhe seja atribuída qualquer responsabilidade ou se lhe requeira qualquer ressarcimento ou indenização.

	<p></p>•	Esta garantia pressupõe que a plataforma MEDICAR, objeto deste contrato, seja operada em ambiente de software e hardware de origem idônea, em sistema de rede e instalações elétricas adequadas e isentas de problemas, e dentro da configuração mínima exigida pela MEDICAR. Considera-se hardware não idôneo aquele originário de fabricante desconhecido ou não legalmente constituído, ou montado em instalações clandestinas, originário de contrabando ou qualquer outro em que não exista garantia expressa e válida de fabricação de seu todo ou partes.


	<p></p>•	As garantias estipuladas na presente cláusula não abrangem problemas, erros, danos ou prejuízos advindos de decisões tomadas com base em informações, quaisquer que sejam fornecidas pelo(s) programa(s), assim como não abrangem defeitos ou erros decorrentes de negligência, imprudência ou imperícia do Usuário na utilização da Plataforma MEDICAR, e também problemas provenientes de caso fortuito ou força maior.

	<p></p>•	O Usuário é integralmente responsável pelas informações inseridas na Plataforma MEDICAR. Toda e qualquer informação inserida na Plataforma MEDICAR pelo Usuário não será revisada pela MEDICAR, que em hipótese alguma será responsável pelo conteúdo inserido pelo Usuário na Plataforma MEDICAR.


	<p></p>•	Em nenhuma circunstância a MEDICAR será considerada responsável por quaisquer danos, diretos ou indiretos, físicos, técnicos, econômicos, ou fiscais, tais como, sem se resumir a, perdas e danos, lucros cessantes, danos emergentes, interrupção de negócios ou outros prejuízos decorrentes de uso ou da impossibilidade do uso da Plataforma MEDICAR.


	<p></p>•	Os documentos médicos eventualmente gerados na Teleconsulta (receitas, atestados, pedidos de exames e encaminhamentos) estarão disponíveis para download imediatamente após a consulta, na área do Usuário dentro da Plataforma MEDICAR.


	<p></p>•	É de responsabilidade da MEDICAR garantir ao Usuário, durante 24 (vinte e quatro) horas, 07 (sete) dias por semana, a disponibilidade dos Profissionais de Saúde vinculados às Instituições.


	<p></p>•	As Instituições oferecerão ao Usuário, pelos Profissionais de Saúde a elas vinculados, por meio da Plataforma MEDICAR, especialidades médicas dispostas em locais visíveis na Plataforma MEDICAR.


	<p></p>•	A Plataforma MEDICAR ficará disponível pelo período de 24 (vinte e quatro) horas por dia para a realização das consultas médicas à distância e online prestadas pelas Instituições, por meio dos Profissionais de Saúde a elas vinculados.

	<p></p>•	LIMITAÇÃO DE RESPONSABILIDADE DA MEDICAR

    <p></p>14.1. O Usuário desde já declara conhecer e concordar que a Plataforma MEDICAR é tão somente uma plataforma web ou por Aplicativo, que facilita a interconexão entre as Instituições, os Profissionais de Saúde a ela vinculados e os Usuários, a quem disponibiliza funcionalidades para auxiliar a realização de Teleconsulta pelos Profissionais de Saúde.


    <p></p>14.2 A Plataforma MEDICAR não é responsável pela disponibilidade, qualidade e manutenção de tais serviços de telecomunicação e o Usuário entende que as condições do serviço de telecomunicações poderão afetar a experiência do Serviço. A MEDICAR não será responsabilizada por qualquer problema relacionado ao Serviço decorrente direta ou indiretamente de inconsistências ou falhas nos dispositivos de telefonia móvel e/ou nos serviços de telecomunicação. Além disso, a MEDICAR não será responsabilizada por eventuais erros e inconsistências das informações oriundas de terceiros relativas ao geoposicionamento via sistema GPS e aos mapas integrados ao sistema.

	<p></p>•	Em função do disposto no item supra a MEDICAR não se responsabilizará:

	<p></p>•	Pela falha de operação, operação por pessoas não autorizadas ou qualquer outra causa em que não exista culpa da MEDICAR;

	<p></p>•	ocorrências por “caso fortuito” ou “força maior”;


	<p></p>•	problemas decorrentes de ações de terceiros que possam interferir na qualidade do serviço;


	<p></p>•	danos causados a terceiros em razão de serviços contratados e conteúdo  gerado através da Plataforma MEDICAR;

	<p></p>•	revisar as informações fornecidas pelos Usuários, Instituições e Profissionais de Saúde, seja no que tange à veracidade, exatidão ou completude dos dados, legalidade ou violação legal em função do fornecimento destas informações;

	<p></p>•	danos pessoais ou qualquer prejuízo incidental, especial, indireto ou consequente, incluindo, sem limitação, prejuízos por perda de lucro, corrupção ou perda de dados, falha de transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo decorrentes ou relacionados ao seu uso ou inabilidade do Usuário, Instituições e Profissionais de Saúde a elas vinculados que utilizarem a Plataforma MEDICAR;



	<p></p>•	qualquer fraude, declaração fraudulenta ou violação do dever, ou violação de qualquer uma das condições destes Termos e Condições de Uso pelo Usuário, Instituições e Profissionais de Saúde a elas vinculados;

	<p></p>•	falhas de conexões, especialmente no que tange a impossibilidade de acesso à internet pelos Usuários; e

	<p></p>•	quaisquer problemas relacionados à prestação de serviços realizada pelas Instituições e os Profissionais de Saúde a elas vinculados aos Usuários.

	<p></p>•	A MEDICAR irá se esforçar para manter o seu conteúdo atualizado e completo, livre de quaisquer defeitos ou vírus, não se responsabilizando, contudo, por esses e quaisquer outros possíveis problemas que não sejam causadas diretamente e exclusivamente pela MEDICAR. Fica desde já acordado que a MEDICAR poderá alterar o conteúdo a qualquer momento, sem prévio aviso.

	<p></p>•	A MEDICAR declara inexistência de relação de trabalho, vínculo de emprego e outros entre as Instituições e os Profissionais de Saúde a elas vinculados com a MEDICAR, bem como inexistência de qualquer vínculo de natureza societária, empregatícia e/ou econômica. A MEDICAR não possui uma rede de Profissionais de Saúde ou Instituições, prestando exclusivamente os serviços de licenciamento e intermediação voltados à interconexão online entre as Instituições, os Profissionais de Saúde a elas vinculados e os Usuários que tiverem acesso à Plataforma MEDICAR.


	<p></p>•	A MEDICAR não se responsabiliza por quaisquer perdas, prejuízos ou danos de qualquer natureza que sejam decorrentes da relação entre Usuário, Instituições e os Profissionais de Saúde a elas vinculados. O Usuário entende e concorda que a MEDICAR não será responsável por quaisquer danos ou prejuízos que venham a lhe ser causados pelas Instituições e os Profissionais de Saúde a elas vinculados, uma vez que a relação entre os Profissionais de Saúde e os Usuários é regida pelos preceitos e normas estabelecidos pelo CFM (Conselho Federal de Medicina).

	<p></p>•	A Teleconsulta será realizada por Profissional de Saúde vinculado à Instituições, que se responsabilizam integral e solidariamente pelo atendimento realizado, respondendo civilmente, criminalmente e perante os respectivos conselhos de classe por eventuais danos causados.

	<p></p>•	CANCELAMENTO

	<p></p>•	O cancelamento dos Serviços poderá ser requerido pelo Usuário, observado o prazo mínimo de 12 (doze) meses a partir da liberação do acesso pelo Usuário à Plataforma MEDICAR, para que o cancelamento se dê sem quaisquer ônus ou multa para o Usuário.

	<p></p>•	Se o Usuário, sem justa causa, decidir por cancelar os Serviços antes do prazo a que se refere o item 15.1., ficará obrigado a pagar, a título de multa por quebra contratual, o equivalente a 04 (quatro) Assinaturas Mensais.

	<p></p>•	Se o Usuário decidir cancelar os Serviços da MEDICAR após 03 (três) meses do seu início, ficará obrigada a pagar, em favor da outra, os valores proporcionais até o fim do prazo a que se refere o item 15.1.


	<p></p>•	Os pagamentos efetuados pelos Serviços não são passíveis de reembolso, inclusive pelo cancelamento unilateral do Usuário.

	<p></p>•	POLÍTICA DE PRIVACIDADE

    <p></p>16.1. Favor consultar a Política de Privacidade da MEDICAR no seguinte link: http://www.medicar.com.br/politica-de-privacidade, que constitui parte integrante destes Termos e Condições de Uso.

	<p></p>•	DEMAIS CONDIÇÕES

	<p></p>•	O Usuário concorda que a MEDICAR lhe envie ou simplesmente disponibilize, por meio da Plataforma MEDICAR, artigos, textos, notícias ou quaisquer outras formas de comunicação, desenvolvidas pela própria MEDICAR ou por parceiros desta, sempre que julgar que tais comunicações sejam de interesse do Usuário.

	<p></p>•	A MEDICAR poderá modificar estes Termos de Condições e Uso a qualquer momento, publicando a nova versão em seu website. Os novos Termos e Condições de Uso revisados entrarão em vigor dentro de 48 (quarenta e oito) horas contados da data de sua publicação ou entrega ao Usuário, exceto se o Usuário expressamente aceitar o contrato revisado antes disso ao clicar no botão de aceitar. A aceitação expressa ou o uso contínuo da Plataforma MEDICAR depois da expiração do prazo de 48 (quarenta e oito) horas do aviso constituirá aceitação integral destes Termos e Condições de Uso.


	<p></p>•	As disposições destes Termos e Condições de Uso são irrevogáveis e irretratáveis e obrigam as partes e seus sucessores a qualquer título. As partes reconhecem, para todos os fins e efeitos de direito, que os presentes Termos e Condições de Uso constituem título executivo extrajudicial, estando sujeito à execução específica, nos termos do disposto no Código de Processo Civil.


	<p></p>•	Fica eleito o foro da Comarca de Vitória/ES, com renúncia de qualquer outro, por mais privilegiado que o seja, para resolver qualquer questão resultante dos presentes Termos e Condições de Uso.


    <p>
    (https://app.medicar.com.br/)
    
    
    
    Política de Privacidade (https://app.medicar.com.br/politica-de-privacidade)
    
    
    Termos de Uso (https://app.medicar.com.br/termos-de-uso)
    
    
    
    telemedicina@medicar.com.br medicar.com.br (https://www.aplicamed.com.br/)
    </p>
    <p></p>
    <p></p>
    <p></p>

<p>(https://app.medicar.com.br/)</p>
<p><h2>POLITICA DE PRIVACIDADE MEDICAR</h2></p>
<p>
A Política de Privacidade da MEDICAR foi atualizada em 16 de setembro de 2022.
Sua privacidade é importante para a MEDICAR, por isso criamos uma Política de Privacidade que abrange a maneira como lidamos com as suas informações pessoais, com sua imagem e principalmente os dados de saúde, que são considerados Dados Sensíveis. Dedique um momento para se familiarizar com as nossas práticas de privacidade e avise-nos se tiver dúvidas. Todos os tratamentos de Dados Pessoais dispostos a seguir possuem finalidades próprias e bases legais definidas, conforme a Lei Geral de Proteção de Dados Pessoais – Lei nº 13.709/2018.
Acreditamos que você sempre deve saber quais dados coletamos e como os usamos, bem como que você deve ter um controle significativo sobre isso. Queremos capacitá-lo para tomar as melhores decisões sobre as informações que você compartilha conosco.
A garantia da proteção dos dados pessoais dos usuários do nosso site é importante para nós.
A Política de Privacidade da MEDICAR foi criada para mostrar o nosso compromisso em sempre tratar os seus dados pessoais com segurança, privacidade e transparência.
Esta Política de Privacidade descreve os dados pessoais que coletamos, como eles são usados, armazenados, compartilhados e os seus direitos desse tratamento. Recomendamos a leitura atenta.
A aceitação da nossa Política será feita quando você permitir através do cadastro em nosso aplicativo. Tal aceitação se dará mediante o momento em que você, de forma afirmativa, concordar com o uso dos seus dados para cada uma das finalidades por nós aqui descritas. Isso indicará que você está ciente e em total acordo com a forma como utilizaremos as suas informações e os seus dados. Caso não concorde com esta Política, por favor, informe-nos a sua discordância para que possamos melhorá-la.
</p>
<h1>INTRODUÇÃO</h1>
<p>Quando você contrata os serviços da MEDICAR, você nos fornece os seus dados pessoais para que possamos viabilizar a prestação dos nossos serviços e também para te entregar a melhor experiência possível. Na MEDICAR, privacidade, segurança e transparência são valores fundamentais e sempre adotaremos as melhores práticas para garantir a confidencialidade e integridade dos seus dados pessoais.
Além disso, burocracia, letras miúdas e asteriscos não fazem parte da nossa missão. Por isso, desenvolvemos essa Política de Privacidade para explicar, de uma forma clara, simples e objetiva, as nossas práticas, como os seus dados pessoais são tratados e o porquê pedimos.
Ao aceitar os termos dessa Política de Privacidade, você está ciente de que a controladora dos seus dados pessoais, ou seja, a empresa responsável por tomar as decisões sobre o tratamento dos seus dados pessoais será a CLICK LIFE TECNOLOGIA S/A, inscrita no CNPJ sob nº 39.549.271/0001-36, com sede na Cidade de Vitoria – ES, na Avenida Jeronimo Monteiro, nº 1.000, sala 1501, Centro, CEP 29.010-004.
Após a leitura desta Política de Privacidade, se você tiver dúvidas, reclamações, quiser exercer seus direitos relacionados aos seus dados pessoais ou se comunicar conosco sobre esse assunto, você pode entrar em contato pelos nossos canais de atendimento ou contatar o nosso encarregado de dados (DPO) pelo e- mail telemedicina@medicar.com.br ou WhatsApp (16) 3512-4400.
</p>
<p>•	APLICAÇÃO DESTA POLÍTICA
Disponibilizamos ao público geral nosso aplicativo e website com o objetivo de trazer informações sobre os nossos serviços e como contratá-los; disponibilizar conteúdos gratuitos para os usuários; permitir a inscrição em nossa newsletter; divulgar produtos, eventos e demais materiais produzidos pela ou pelos nossos parceiros, criando um canal de comunicação entre nós.
Esta política se aplica aos tratamentos de dados pessoais realizados pela para prestar seus serviços e disponibilizar o aplicativo.
Para fins de esclarecimento, dado pessoal é qualquer informação que possa identificar direta ou indiretamente alguém, como, por exemplo, seu nome, telefone, endereço de IP e e-mail (“Dados Pessoais”). Também existem os dados sensíveis que são dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural. Entre eles, de acordo com o contexto da nossa plataforma, poderão ser considerados como sensíveis os dados de identificação pessoal; dados de autenticação; localização do dispositivo; inventário de outros apps no dispositivo e conteúdo do microfone e da câmera (imagem).
A aceitação desta Política será feita quando você acessar nosso aplicativo e/ou usufruir dos nossos serviços, mesmo que de forma gratuita. Isso indicará que você está ciente e em total acordo com a forma como utilizaremos as suas informações e seus dados.
</p>	
<p>
•	DADOS PESSOAIS COLETADOS
A realizará a coleta de dados que o usuário do aplicativo voluntariamente fornecer, registros de acesso e informações geradas quando o usuário acessar a plataforma. A quantidade e o tipo de informações coletadas pela variam conforme o contexto, tipo de serviço ou conteúdo que o usuário busca.
</p>	
<p>
•	A declara que realiza a coleta e tratamento dos dados através dos seguintes meios, divididos por categorias:
A – SE VOCÊ É UM PACIENTE:
Dados pessoais informados pelo titular no cadastro:
 Dados cadastrais: nome, CPF, data de nascimento, sexo, endereço e uma foto;  Dados de contato: número de telefone e e-mail;
  Dados do cartão de crédito para pagamento no momento da consulta;  Dados para a Carteirinha do paciente : Upload da sua imagem.
Dados coletados para iniciar a consulta médica:
  Habilitação da câmera e do microfone para realização da consulta;  Os sintomas que está apresentando;
 Informação se já utiliza alguma medicação;
  Poderá anexar um exame ou um documento adicional para a análise do médico.
Dados de navegação e do dispositivo:
 Endereço IP do dispositivo móvel utilizado para acessar os serviços ou produtos da ;  Câmera e microfone apenas durante a consulta;
 Interações realizadas e perfil de uso de sites, blogs e aplicativo da ;
 Dados técnicos, como informações de URL, de conexão de rede, do provedor, e do dispositivo;  Cookies;
  Atributos do dispositivo, tais como ID, sistema operacional, navegador e modelo;
B – SE VOCÊ ESTÁ REGISTRADO CONOSCO COMO UM MÉDICO PROFISSIONAL DA SAÚDE
Dados pessoais informados pelo titular no cadastro:
 Dados cadastrais: nome completo, data de nascimento, sexo e CPF;  Número de CRM e UF;
 Número de telefone;  Endereço de e-mail;
  Informações bancárias para pagamento: banco, agência, conta e chave PIX;
 Endereço;
 Suas especializações;  Sua imagem;
Dados de navegação e do dispositivo:
 Endereço IP do dispositivo móvel utilizado para acessar os serviços ou produtos da ;  Câmera e microfone apenas durante a consulta;
 Interações realizadas e perfil de uso de sites, blogs e aplicativo da ;
 Dados técnicos, como informações de URL, de conexão de rede, do provedor, e do dispositivo;  Cookies;
  Atributos do dispositivo, tais como ID, sistema operacional, navegador e modelo;
</p>
<p>  	
•	FINALIDADE E BASE LEGAL DA COLETA DE DADOS
A promoverá o tratamento dos dados coletados na forma do item 2 acima, em conformidade com a legislação brasileira de proteção de dados (Lei Geral de Proteção de Dados LGPD – Lei nº 13.709, de 14 de agosto de 2018). Apresentamos abaixo os motivos pelos quais processamos os seus dados pessoais e os fundamentos legais em que nos baseamos para podermos processar os dados pessoais usados para tais fins:
Dados pessoais informados pelo titular no cadastro:
 Dados cadastrais;
Finalidade: Cadastramento do usuário.
Base Legal: Execução de um contrato.
Obs: Anexar sua imagem é opcional e precisamos do seu consentimento.
 Dados de contato;
Finalidade: Contato por telefone, e-mail, SMS, WhatsApp, ou outros meios de comunicação, inclusive para envio de notificações ou push de uso dos serviços da , mensagem do desenvolvedor, bem como enviar exame e receituário médico.
Base Legal: Execução de um contrato.
Finalidade: Enviar comunicações ao usuário para fins de: marketing, pesquisa, quiz, campanha e promoções por meio de e-mails, notificações, anúncios pelo Google Ads e suas redes parceiras;
Base Legal: Legítimo Interesse.
Finalidade: Fornecer ao usuário funcionalidades, atualizações, políticas de preços e de contratação dos serviços, publicidade ou outro conteúdo baseado na localização e nos seus interesses específicos.
Base Legal: Legítimo Interesse.
 Dados do cartão de crédito;
Finalidade: Prestação dos serviços contratados.
Base Legal: Execução de um contrato
Finalidade: Realização de cobrança pela consulta. Base Legal: Execução de um contrato
*O Histórico de consultas pagas do paciente fica registrado para que ele possa acessar futuramente suas transações na plataforma.
 Dados para a Carteirinha do paciente ;
Finalidade: Ao se cadastrar e anexar sua imagem, esta poderá ser utilizada para fins de identificação dentro do aplicativo, a Carteirinha é uma forma do paciente usufruir de benefícios oferecidos pela junto aos parceiros.
Base Legal: Consentimento.
*A Carteirinha do paciente somente é utilizada pelo próprio usuário, o qual poderá compartilhar caso queira. A não compartilha esses dados com terceiros.
  Dados coletados para iniciar a consulta médica:
Finalidade: Prestação dos serviços contratados (sem considerar os dados de saúde).
Base Legal: Execução de um contrato.
Coleta de Dados sensíveis de Saúde: Os dados sensíveis coletados para iniciar a consulta médica e durante a consulta são tratados com a finalidade de prestar o serviço pelo profissional da saúde e as bases legais utilizadas, dependendo dos dados fornecidos, serão o consentimento, quando o paciente consente e fornece a informação ao médico, bem como a tutela da saúde, quando há a intervenção de um profissional da área.
 Dados pessoais de saúde;
Finalidade: Funcionalidades do sistema, consulta médica, gerar prescrição médica, prescrição de medicamentos, pedidos de exames ou atestados médicos e prestação do serviço pelos profissionais da saúde.
Base Legal: Tutela da Saúde e Consentimento.
 Habilitação da câmera e do microfone para realização da consulta; Finalidade: Realização da consulta médica e prescrições.
Base legal: Tutela da Saúde (Procedimento realizado somente pelo médico ou um profissional da saúde).
 Dados de navegação e do dispositivo;
Finalidade: Fornecer, personalizar e melhorar a experiência do usuário com os serviços gratuitos oferecidos pelo site e demais páginas relacionadas, bem como com outros serviços e produtos fornecidos pela , (por exemplo, ao fornecer serviços personalizados, individualizados ou adaptados à sua localização ou interesse, recomendações e funcionalidades).
Base Legal: Legítimo Interesse.
Finalidade: Compreender de que forma o usuário acessa e utiliza o aplicativo, a fim de garantir a sua funcionalidade técnica, desenvolver novos produtos e serviços e analisar a utilização da plataforma pelo usuário, incluindo a sua interação com os conteúdos, produtos e serviços que são disponibilizados, oferecidos ou conectados através do aplicativo;
Base Legal: Legítimo Interesse.
Além das finalidades acima expostas, também podemos utilizar seus dados para o exercício regular de direitos da , inclusive apresentando documentos em processos judiciais e administrativos, se necessário. Colaboração ou cumprimento de ordem judicial, de autoridade competente ou de órgão fiscalizador e Cumprimento de obrigação legal ou regulatória.
O consentimento que o usuário fornece para as finalidades de uso dos dados é coletado de forma individual, clara, específica e legítima, inclusive referente aos dados sensíveis que coletamos durante as consultas.
Quando você realiza uma consulta em nossa plataforma, processamos dados considerados sensíveis, ou seja, dados de saúde, sem eles não seríamos capazes de fornecer-lhes a nossa plataforma e os serviços técnicos contratados.
Os dados fornecidos para a consulta serão armazenados em nossa plataforma e transferidos ao profissional de saúde, e/ou para a clínica que emprega o especialista. Uma vez que seus dados pessoais são transferidos para o profissional de saúde ou estabelecimento, estes se tornam, de acordo com a legislação, um controlador de dados que processará essas informações para seus próprios fins (por exemplo, em especial para a prestação de serviços de saúde) em conformidade com seus próprios termos e políticas.
Em relação ao compartilhamento desses dados, favor consultar o tópico 7. desta política. A não realiza a venda de dados pessoais e sensíveis dos usuários.
Quando você contrata nossos serviços, você firma um contrato conosco. O tratamento de seus dados pessoais se dará para a execução deste contrato, sendo fundamento legal válido para o processamento (artigo 7, V, da LGPD). Também processamos seus dados pessoais com base em nossas obrigações legais, por exemplo, a fim de emitir notas fiscais e manter nossos registros financeiros atualizados.
A permite que os usuários solicitem a exclusão de dados.
</p>
<p>
•	DIREITO DOS TITULARES DE DADOS
O usuário sempre poderá optar em não divulgar seus dados para nós, mas tenha em mente que alguns desses dados podem ser necessários para utilizar alguns de nossos recursos e produtos que oferecemos a você. Independente disso, você sempre possuirá direitos relativos a privacidade e a proteção dos seus dados pessoais, e nós, além de nos preocuparmos com a segurança desses dados, também nos preocupamos que você tenha acesso e conhecimento de todos os seus direitos relativos a dados pessoais.
Dessa forma, abaixo resumimos todos os direitos que você tem em concordância com a LGPD, os quais são:  Confirmação da existência de tratamento;
 Acesso aos dados;
 Correção de dados incompletos, inexatos ou desatualizados;
 Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com a LGPD;
 Portabilidade dos dados;
  Eliminação dos dados pessoais tratados com o consentimento do titular;
 Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;
 Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;
  Revogação do consentimento.
Caso você tenha alguma dúvida sobre essas questões e sobre como você pode exercer esses direitos, fique à vontade para entrar em contato conosco.
</p>	
<p>
•	USO DE COOKIES
Cookies são pequenos arquivos de texto armazenados em seu navegador ou dispositivo que nos permitem reconhecer as suas preferências pessoais quando você visita nosso site. Assim, algumas informações suas são salvas nesses arquivos de texto e, quando você visita o site novamente, o site reconhece seu navegador.

Os cookies podem ser instalados pelo próprio site, os chamados cookies de origem, ou podem ser oriundos de serviços externos, os chamados cookies de terceiros.
Os cookies geralmente possuem um tempo de expiração. Alguns deles são apagados automaticamente quando você fecha o navegador, os chamados cookies de sessão. Já os chamados cookies persistentes são guardados no seu computador ou dispositivos e não são automaticamente apagados quando fecha o navegador e precisam ser excluídos manualmente.
Podemos utilizar cookies para:
  Viabilizar o funcionamento do site: alguns cookies são necessários para o correto funcionamento do site, sem os quais se torna impossível executar algumas de suas funcionalidades;
  Análises de desempenho do site: utilizamos cookies para compreender como nossos serviços são utilizados, e, assim, melhorar a sua experiência como usuário;
 Armazenar informações, tais como as suas preferências pessoais quando você visita o nosso website, permitindo a personalização da sua experiência de navegação;
  Produção de estatísticas anonimizadas sobre a experiência de navegação no nosso site e páginas
relacionadas;
 Direcionar conteúdos personalizados, bem como conteúdos de marketing e publicidade a partir de informações sobre sua interação com nosso site;
 Habilitar plugins de determinadas redes sociais, quando você escolhe acessar nossos serviços por meio dessas redes.
Você pode utilizar as configurações do seu navegador para saber quais cookies estão instalados no seu computador ou dispositivo ou ainda, excluí-los ou restringi-los. Note que o uso de cookies nos permite oferecer a você uma melhor experiência em nosso site. O bloqueio de cookies pode afetar certas funções, fazendo com que algumas páginas não funcionem corretamente.
</p>
<p>
	•	SEGURANÇA DA INFORMAÇÃO
A armazenará as informações coletadas no site em servidores próprios ou por ela contratados com o compromisso de cumprir as determinações de confidencialidade, neste sentido:
 A adota medidas tecnológicas adequadas a atender aos padrões legalmente requeridos para preservar a privacidade dos dados coletados em seu site, tais como: (i) proteção contra acesso não autorizado de terceiros a seus sistemas; (ii) controle do acesso às informações coletadas por empregados e profissionais terceirizados que tenham se comprometido formalmente a manter seu sigilo e seu acesso, orientado pelos princípios da proporcionalidade, necessidade e relevância, objetivando a preservação da privacidade de acordo com esta política;
 A empregará seus melhores esforços para preservar a privacidade dos dados do usuário. Entretanto, infelizmente, nenhum sistema é totalmente seguro e a não pode garantir integralmente que todas as informações que trafegam no aplicativo não sejam alvo de acessos não autorizados perpetrados por meio de métodos desenvolvidos para obter informações de forma indevida. Por esse motivo, o usuário também deverá tomar as medidas apropriadas para a proteção de suas informações.
A  limita o acesso, a coleta, o uso e o compartilhamento de dados pessoais e sensíveis coletados pela
plataforma.
Lidamos com todos os dados pessoais ou sensíveis de usuários de maneira segura, incluindo a transmissão desses dados por criptografia moderna (HTTPS).
</p>
<p>
	•	COMPARTILHAMENTO DE DADOS E TRANSFERÊNCIA INTERNACIONAL
Nós compartilhamos ou divulgamos seus dados pessoais com o seu consentimento ou de acordo com as suas orientações, por exemplo, quando você autoriza o compartilhamento da Carteirinha para adquirir um benefício em um parceiro.
Os dados sensíveis de saúde coletados a partir da consulta, bem como sua imagem e áudio são compartilhados com o médico, porém, fique tranquilo, pois o sigilo médico está relacionado ao dever de confidencialidade de informações médicas relacionadas ao paciente. O sigilo profissional é um direito do paciente, sendo um dever do médico mantê-lo, podendo, portanto, só ser quebrado mediante o consentimento por escrito do paciente ou mediante situações permitidas por lei.
É importante destacar que também fornecemos a terceiros determinados dados pessoais para nos ajudar a oferecer ou operar nossos serviços, aqui vamos especificar como é feito esse tratamento:
Os seguintes dados são compartilhamos com aplicações de terceiros: Nome, CPF, Data nascimento, Estado civil, Telefone, Endereço, Áudio (voz) e Vídeo (imagem).
Os dados acima são compartilhados com terceiros com a ÚNICA finalidade de gerar prescrição médica, prescrição de medicamentos, pedidos de exames ou atestados médicos.
Quem são esses terceiros?
Esse compartilhamento é feito com dois operadores:
</p>
<p>
	•	– MEMED, registrada no CNPJ/ME sob o nº 13.077.546/0001-92, nome empresarial 2RM TECNOLOGIA DA INFORMAÇÃO S/A.
Dados compartilhados: Nome, CPF, Data Nascimento, Estado civil, Telefone e Endereço.
	•	– Ferramenta de streaming de terceiro para viabilizar a troca de informações entre médico e paciente. (Twilio – https://www.twilio.com/pt-br/)
Dados compartilhados: Vídeo e Áudio.
Utilizamos cláusulas sobre proteção de dados pessoais em nossos contratos com terceiros, visando preservar ao máximo a sua privacidade e a segurança da informação.
Em demais casos, havendo a necessidade de compartilhamento das informações, enviaremos a você uma notificação solicitando sua aprovação.
Qualquer dado pessoal em posse da será armazenado de acordo com os mais rígidos padrões de segurança, o que inclui a adoção de medidas como:
  Proteção contra acesso não autorizado a seus sistemas.
  Acesso restrito de pessoas específicas ao local onde são armazenadas as informações pessoais.
 Garantia para que agentes, funcionários internos ou parceiros externos que realizarem o tratamento de dadospessoais se comprometam a manter o sigilo absoluto das informações, adotando as melhores práticas para manuseio desses dados, conforme determinado nas políticas e procedimentos corporativos.
A poderá compartilhar Dados Pessoais coletados a partir do nosso aplicativo e páginas relacionadas com parceiros e fornecedores, tais como empresas de processamento de dados (operadores), sistemas de processamento (serviços de computação em nuvem), serviços de telecomunicação, serviços de publicidade e marketing, organização de eventos, (como por exemplo, Google Ads, Google Analytics, Amazon Web Services, Intercom), bem como plataformas integradas (Firebase).
Além disso, ao utilizar nosso website, você pode ser redirecionado para sites ou aplicativos de terceiros. Depois que você for redirecionado para um site ou aplicativo de terceiros, as práticas de privacidade serão regidas pelas políticas de privacidade e pelos termos de uso desses terceiros. Não podemos controlar ou nos responsabilizar pelas práticas e conteúdo de privacidade de terceiros. Leia atentamente as políticas de privacidade aplicáveis para entender como eles coletam e processam seus dados.
Nossos Parceiros somente são autorizados a utilizar os dados pessoais para os fins específicos que eles foram contratados, portanto, eles não irão utilizar os seus dados pessoais para outras finalidades, além as da prestação de serviços previstas contratualmente.
Os dados armazenados pela podem vir a ser utilizados para fins de estatísticas (analytics), com a finalidade de compreender quem são as pessoas que visitam seu site ou baixam seu aplicativo e que são consumidores dos seus produtos e serviços. Estes dados são pseudomizáveis e não buscam identificar ou tornar identificável os titulares dos dados pessoais, mas tão somente compreender melhor como é o acesso deles nas plataformas, a fim de melhorar a prestação de serviços e customizar produtos mais direcionados aos interesses dos usuários.
A execução de alguns serviços prestados por fornecedores e parceiros poderá demandar a transferência internacional de dados, caso em que a compromete-se a contratar apenas tais serviços com empresas que estejam obrigadas às regras de proteção previstas na Lei nº 13.709/2018 (LGPD) e/ou em lei análoga à brasileira.
A se reserva no direito de tratar e/ou fornecer quaisquer Dados Pessoais e informações para cumprir uma obrigação legal ou uma ordem judicial, caso lhe for solicitada; para dar cumprimento a outros acordos e/ou contratos; proteger os direitos, propriedade ou segurança da , bem como de nossos funcionários e/ou clientes.
</p>
<p>
	•	RETENÇÃO, ALTERAÇÃO E EXCLUSÃO DOS DADOS
As Informações coletadas pela por meio do site ou aplicativo serão mantidas apenas pelo período necessário para servir à finalidade que se destinam e serão automaticamente excluídas dos nossos servidores depois de terem sido utilizadas para os fins aos quais foram coletados. Iremos considerar ainda, a quantidade, a natureza, a finalidade do tratamento e os requisitos legais aplicáveis para determinar o período de retenção de tais Informações.
O usuário, titular dos dados, poderá solicitar a qualquer tempo uma cópia de seus dados pessoais tratados pela ou requerer a correção, atualização e exclusão de seus dados pessoais, sempre através do e-mail telemedicina@medicar.com.br ou WhatsApp (16) 3512-4400
Caso qualquer dado pessoal seja tratado de forma desnecessária, em excesso para a finalidade a que se destina ou em desconformidade com a LGPD, você pode solicitar que a anonimize, bloqueie ou elimine esses dados, desde que fique efetivamente constatado o excesso, a falta de necessidade ou a desconformidade
com a lei.
Note que em todos os e-mails que enviamos há sempre um link para cancelar a inscrição, disponível no final da página. Ao clicar nesse link você será automaticamente descadastrado da lista.
Se você preencher qualquer formulário no nosso site novamente ficará caracterizada a reinserção do seu e- mail à lista. Portanto, a requisição de cancelamento da inscrição deverá ser feita novamente, caso seja de seu interesse.
A se reserva no direito de reter eventuais dados pessoais necessários ao cumprimento de obrigações legais, para satisfazer exigências regulatórias, sanar divergências com o usuário, evitar fraudes e o uso indevido do site e dos dados e/ou para garantir o cumprimento desta Política de Privacidade.
</p>
<p>
	•	CONSENTIMENTO
Ao longo desta Política de Privacidade, informamos que alguns dados pessoais somente serão coletados pela com o seu consentimento. Da mesma forma, esses dados pessoais somente poderão ser tratados mediante autorização e para as finalidades descritas. Essas informações estão, principalmente, nos capítulos 2 e 3 da Política.
Ao ler esta Política de Privacidade e clicar, ao final, em “Eu li, estou ciente das condições de tratamento dos
meus dados pessoais e forneço meu consentimento, quando aplicável, conforme descrito nesta Política de Privacidade.”, você consente com o tratamento dos dados pessoais nas formas aqui indicadas.
Vale lembrar que o tratamento de seus dados pessoais é condição necessária para que possamos prestar nossos serviços e entregar nossos produtos para você. Caso você tenha dúvidas sobre qualquer um dos termos explicados aqui, estamos à disposição em nossos canais de atendimento para ajudá-lo.
</p>
<p>
	•	ALTERAÇÕES NA POLÍTICA
Buscamos sempre melhorar nossos processos e, por esse motivo, esta Política de Privacidade poderá passar por atualizações. Recomendamos que você visite nossa Política de Privacidade periodicamente. Não obstante, a informará ao usuário sobre as alterações relevantes através de aviso no próprio aplicativo, site, e-mail ou por outro meio razoável.
</p>
<p>
	•	LEI APLICÁVEL
A presente Política de Privacidade será regida e interpretada segundo a legislação brasileira, independentemente das Leis de outros estados ou Países. Todas as informações pessoais relativas aos Usuários que acessam e utilizam o website da são tratadas em concordância com a Lei 12.965/2014 (Marco Civil da Internet) e a Lei 13.709/18 (Lei Geral de Proteção de Dados Pessoais).
Fica eleito o Foro da Comarca de Vitória/ES para dirimir qualquer litígio ou controvérsia envolvendo o presente documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.
Contatos: e-mail telemedicina@medicar.com.br ou WhatsApp (16) 3512-4400
</p>
<p>
(https://app.medicar.com.br/)
</p>
<p>
Política de Privacidade (https://app.medicar.com.br/politica-de-privacidade)
Termos de Uso (https://app.medicar.com.br/termos-de-uso)
telemedicina@medicar.com.br
medicar.com.br (https://www.aplicamed.com.br/)
</p>
</div>
`;
