<template>
  <svg
    width="333"
    height="289"
    viewBox="0 0 333 289"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1607_1918)">
      <path
        d="M332.45 0.412537H0.722168V213.016H332.45V0.412537Z"
        fill="#E6E6E6"
      />
      <path d="M322.965 27.0783H10.208V200.438H322.965V27.0783Z" fill="white" />
      <path
        d="M332.309 0.224335H0.580566V14.3273H332.309V0.224335Z"
        :fill="svgColor"
      />
      <path
        d="M11.0534 10.0484C12.496 10.0484 13.6655 8.87813 13.6655 7.43453C13.6655 5.99092 12.496 4.82065 11.0534 4.82065C9.61085 4.82065 8.44141 5.99092 8.44141 7.43453C8.44141 8.87813 9.61085 10.0484 11.0534 10.0484Z"
        fill="white"
      />
      <path
        d="M20.968 10.0484C22.4106 10.0484 23.58 8.87813 23.58 7.43453C23.58 5.99092 22.4106 4.82065 20.968 4.82065C19.5254 4.82065 18.356 5.99092 18.356 7.43453C18.356 8.87813 19.5254 10.0484 20.968 10.0484Z"
        fill="white"
      />
      <path
        d="M30.8825 10.0484C32.3251 10.0484 33.4946 8.87813 33.4946 7.43453C33.4946 5.99092 32.3251 4.82065 30.8825 4.82065C29.44 4.82065 28.2705 5.99092 28.2705 7.43453C28.2705 8.87813 29.44 10.0484 30.8825 10.0484Z"
        fill="white"
      />
      <path d="M118.99 45.0658H38.626V182.451H118.99V45.0658Z" fill="#E6E6E6" />
      <path
        d="M199.832 45.0658H132.862V82.8826H199.832V45.0658Z"
        :fill="svgColor"
      />
      <path
        d="M199.832 93.4139H132.862V134.103H199.832V93.4139Z"
        fill="#E6E6E6"
      />
      <path
        d="M199.832 144.634H132.862V182.451H199.832V144.634Z"
        fill="#E6E6E6"
      />
      <path
        d="M294.546 45.0658H214.183V104.937H294.546V45.0658Z"
        fill="#E6E6E6"
      />
      <path
        d="M294.546 122.579H214.183V182.451H294.546V122.579Z"
        fill="#E6E6E6"
      />
      <path
        d="M263.587 109.239V96.3492C263.587 91.7683 265.406 87.3752 268.643 84.136C271.88 80.8969 276.27 79.0772 280.847 79.0772C285.425 79.0772 289.815 80.8969 293.052 84.136C296.288 87.3752 298.107 91.7683 298.107 96.3492V109.239C298.106 109.854 297.862 110.443 297.427 110.878C296.992 111.313 296.403 111.558 295.788 111.559H265.906C265.291 111.558 264.702 111.313 264.267 110.878C263.833 110.443 263.588 109.854 263.587 109.239Z"
        fill="#2F2E41"
      />
      <path
        d="M306.369 276.981L300.281 278.667L290.886 255.972L299.871 253.484L306.369 276.981Z"
        fill="#FFB8B8"
      />
      <path
        d="M295.451 278.13L307.192 274.879L309.237 282.276L290.105 287.575C289.836 286.604 289.761 285.589 289.885 284.588C290.008 283.588 290.327 282.622 290.823 281.745C291.32 280.868 291.984 280.097 292.778 279.477C293.572 278.857 294.48 278.399 295.451 278.13Z"
        fill="#2F2E41"
      />
      <path
        d="M242.901 281.667H236.585L233.58 257.286H242.902L242.901 281.667Z"
        fill="#FFB8B8"
      />
      <path
        d="M232.073 279.86H244.254V287.536H224.403C224.403 286.528 224.601 285.53 224.987 284.599C225.372 283.667 225.937 282.821 226.649 282.109C227.362 281.396 228.207 280.83 229.138 280.445C230.068 280.059 231.066 279.86 232.073 279.86Z"
        fill="#2F2E41"
      />
      <path
        d="M235.285 145.411C235.736 146.02 236.314 146.522 236.98 146.883C237.646 147.244 238.383 147.455 239.139 147.5C239.896 147.545 240.652 147.424 241.356 147.144C242.061 146.865 242.695 146.435 243.215 145.884L260.006 153.441L258.479 143.99L242.768 138.262C241.785 137.449 240.538 137.026 239.263 137.071C237.989 137.117 236.776 137.629 235.853 138.51C234.931 139.391 234.364 140.581 234.259 141.852C234.154 143.124 234.519 144.39 235.285 145.411Z"
        fill="#FFB8B8"
      />
      <path
        d="M270.172 202.247C264.897 202.247 259.436 201.461 254.845 199.122C252.408 197.905 250.249 196.198 248.502 194.107C246.754 192.017 245.457 189.588 244.691 186.972C242.299 179.394 245.315 171.948 248.231 164.748C250.038 160.286 251.745 156.072 252.188 151.919L252.343 150.444C253.033 143.82 253.628 138.099 256.927 135.953C258.636 134.841 260.935 134.778 263.955 135.759L292.315 144.978L291.272 198.851L291.1 198.909C290.951 198.959 280.954 202.247 270.172 202.247Z"
        fill="#2F2E41"
      />
      <path
        d="M263.458 118.987C263.458 118.987 277.369 114.863 288.189 116.409C288.189 116.409 282.006 150.438 284.067 161.781C286.128 173.123 248.259 166.163 255.988 155.336L258.564 142.446C258.564 142.446 253.412 137.29 258.049 131.103L263.458 118.987Z"
        fill="#6C63FF"
      />
      <path
        d="M256.617 201.226L239.472 198.009L245.458 151.913C245.86 150.626 255.133 121.42 259.344 119.84C262.401 118.762 265.528 117.893 268.703 117.237L269.313 117.115L265.875 122.276L252.189 155.095L256.617 201.226Z"
        fill="#2F2E41"
      />
      <path
        d="M247.683 276.018L225.532 272.323L237.963 221.526L256.461 152.059L256.643 153.292C256.658 153.384 258.386 162.459 284.028 158.432L284.253 158.397L284.315 158.617L315.307 268.197L290.066 272.407L267.045 192.457L247.683 276.018Z"
        fill="#2F2E41"
      />
      <path
        d="M278.386 209.852L278.4 209.462C278.415 209.029 279.94 165.962 279.43 148.641C278.92 131.261 284.542 116.21 284.599 116.06L284.645 115.939L284.769 115.904C292.044 113.824 298.355 120.031 298.418 120.094L298.507 120.183L296.435 137.288L305.305 199.488L278.386 209.852Z"
        fill="#2F2E41"
      />
      <path
        d="M277.301 111.259C284.289 111.259 289.955 105.589 289.955 98.5955C289.955 91.6018 284.289 85.9323 277.301 85.9323C270.312 85.9323 264.646 91.6018 264.646 98.5955C264.646 105.589 270.312 111.259 277.301 111.259Z"
        fill="#FFB8B8"
      />
      <path
        d="M259.504 97.3803C259.508 93.758 260.948 90.2851 263.508 87.7237C266.067 85.1623 269.538 83.7215 273.158 83.7174H275.734C279.354 83.7215 282.824 85.1623 285.384 87.7237C287.943 90.2851 289.383 93.758 289.387 97.3803V97.6381H283.943L282.086 92.435L281.715 97.6381H278.901L277.964 95.0129L277.777 97.6381H259.504V97.3803Z"
        fill="#2F2E41"
      />
      <path
        d="M276.056 113.008C275.8 112.662 275.647 112.25 275.615 111.821C275.583 111.392 275.673 110.962 275.874 110.581C278.604 105.385 282.426 95.7841 277.353 89.863L276.988 89.4375H291.717V111.566L278.337 113.929C278.202 113.953 278.064 113.965 277.927 113.965C277.561 113.965 277.201 113.878 276.876 113.712C276.551 113.546 276.27 113.304 276.056 113.008Z"
        fill="#2F2E41"
      />
      <path
        d="M216.755 21.6448C206.449 12.942 193.837 7.42453 180.454 5.76379C167.071 4.10305 153.494 6.37066 141.376 12.2907C129.258 18.2107 119.12 27.5279 112.196 39.1085C105.273 50.6891 101.863 64.0337 102.38 77.5186C102.897 91.0036 107.32 104.047 115.11 115.063C122.9 126.078 133.722 134.59 146.259 139.563C158.795 144.536 172.505 145.755 185.721 143.072C198.938 140.39 211.09 133.921 220.699 124.453L306.028 196.504C307.327 197.603 309.008 198.14 310.702 197.998C312.396 197.856 313.965 197.046 315.062 195.747C316.16 194.448 316.697 192.765 316.555 191.07C316.413 189.375 315.604 187.805 314.306 186.707L314.296 186.699L228.967 114.648C238.88 100.411 243.122 82.9829 240.863 65.779C238.604 48.5752 230.006 32.8356 216.755 21.6448ZM211.254 107.709C204.607 115.593 195.772 121.327 185.868 124.186C175.964 127.046 165.435 126.902 155.613 123.773C145.791 120.644 137.116 114.671 130.686 106.608C124.256 98.5459 120.36 88.7565 119.49 78.478C118.619 68.1996 120.815 57.8937 125.797 48.8637C130.78 39.8337 138.327 32.4852 147.483 27.7473C156.64 23.0094 166.994 21.095 177.238 22.2461C187.482 23.3973 197.154 27.5624 205.032 34.2146C210.263 38.6315 214.573 44.0362 217.717 50.12C220.861 56.2039 222.776 62.8478 223.354 69.6725C223.931 76.4971 223.16 83.3688 221.084 89.8952C219.008 96.4216 215.668 102.475 211.254 107.709Z"
        fill="#3F3D56"
      />
      <path
        opacity="0.3"
        d="M137.811 113.936C127.813 105.493 121.353 93.5954 119.715 80.6066C118.076 67.6178 121.379 54.4874 128.967 43.8227C127.971 44.8269 127.007 45.8787 126.077 46.978C121.663 52.2125 118.323 58.2658 116.247 64.7922C114.171 71.3186 113.4 78.1903 113.978 85.015C114.555 91.8396 116.471 98.4835 119.614 104.567C122.758 110.651 127.068 116.056 132.299 120.473C137.53 124.89 143.579 128.232 150.101 130.31C156.623 132.387 163.489 133.159 170.309 132.581C177.129 132.003 183.768 130.086 189.848 126.94C195.927 123.794 201.328 119.481 205.742 114.246C206.67 113.146 207.544 112.019 208.364 110.865C199.135 120.144 186.757 125.611 173.687 126.18C160.616 126.749 147.81 122.378 137.811 113.936Z"
        fill="black"
      />
      <path
        d="M281.364 167.549C282.099 167.729 282.866 167.745 283.608 167.597C284.351 167.448 285.052 167.139 285.663 166.69C286.273 166.241 286.777 165.664 287.141 164.998C287.504 164.333 287.717 163.597 287.765 162.84L305.505 157.912L298.294 151.621L282.553 157.265C281.277 157.268 280.048 157.74 279.097 158.59C278.146 159.441 277.54 160.611 277.393 161.878C277.247 163.146 277.57 164.423 278.302 165.468C279.034 166.513 280.123 167.254 281.364 167.549Z"
        fill="#FFB8B8"
      />
      <path
        d="M288.737 164.94L287.658 154.677L302.477 146.495L292.815 134.792L294.391 121.645L298.317 119.961L298.439 120.118C300.291 122.5 316.526 143.464 316.526 146.055C316.526 148.717 313.42 156.534 309.171 158.66C305.072 160.711 289.68 164.697 289.027 164.866L288.737 164.94Z"
        fill="#2F2E41"
      />
      <path
        d="M365.628 288.224H169.331C169.194 288.224 169.063 288.17 168.966 288.073C168.87 287.977 168.815 287.846 168.815 287.709C168.815 287.572 168.87 287.441 168.966 287.344C169.063 287.248 169.194 287.193 169.331 287.193H365.628C365.765 287.193 365.896 287.248 365.992 287.344C366.089 287.441 366.143 287.572 366.143 287.709C366.143 287.846 366.089 287.977 365.992 288.073C365.896 288.17 365.765 288.224 365.628 288.224Z"
        fill="#CCCCCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1607_1918">
        <rect
          width="332"
          height="288"
          fill="white"
          transform="translate(0.187988 0.224335)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconSupport",
  components: {},
  data: () => {
    return {
    };
  },
  mounted() {},
  methods: {},
};
</script>
