import Store from '@/store'

const getHeaderAuth = (headers = {}) => {
  // headers.authorization = Store.state.fornecedorLogin.token
  headers.authorization = 'headersAuthorization'
  headers['Content-Type'] = headers['Content-Type'] || 'application/json'
  headers['Request-Timeout'] = '240000'
  return headers
}

export { getHeaderAuth }
