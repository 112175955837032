import axios from 'axios'

import { getHeaderAuth } from './headers'

export const $http = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT_CALLING_CIRCLE
})

export const handleError = (_this, error) => {
  if (!_this) {
    throw error
  }
  console.warn('[servico-http] ERROR:', error)

  // if (error.response.status === 401) {
  //   _this.$toasted.error('Sessão foi expirada. Encaminhando para a tela de login.', { duration: 2000 })
  //   setTimeout(() => {
  //     _this.$router.push({ name: 'login' })
  //   }, 2100)
  //   return false
  // }

  throw error
}

class ServicoHttpCallingCircle {
  constructor (_this) {
    if (!_this) {
      console.error('[servico-http] PASSAR THIS COMO PARAMETRO')
      return false
    }
    this._this = _this
  }

  async post ({ url, body }) {
    try {
      const resp = await $http.post(url, body, { headers: getHeaderAuth({}) })
      return resp
    } catch (error) {
      handleError(this._this, error)
    }
  }
  
  async uploadFile ({ url, body }) {
    try {
      const resp = await $http.post(url, body, { headers: {
        'Content-Type': 'multipart/form-data',
      }  })
      return resp
    } catch (error) {
      handleError(this._this, error)
    }
  }

  async get ({ url, params }) {
    try {
      const resp = await $http.get(url, { params, headers: getHeaderAuth({}) })
      return resp
    } catch (error) {
      handleError(this._this, error)
    }
  }

  async put ({ url, body }) {
    try {
      const resp = await $http.put(url, body, { headers: getHeaderAuth({}) })
      return resp
    } catch (error) {
      handleError(this._this, error)
    }
  }

  async delete ({ url, params }) {
    try {
      const resp = await $http.delete(url, { params, headers: getHeaderAuth({}) })
      return resp
    } catch (error) {
      handleError(this._this, error)
    }
  }
}

export { ServicoHttpCallingCircle }
