<style src="./PatientClinicalDocuments.scss" lang="scss" scoped></style>
<template>
  <section class="clinical-documents">
    <PatientHeader />

    <section class="title">
      <span
        :style="{ 'text-decoration': 'underline', cursor: 'pointer' }"
        @click="onClickBack"
      >
        Início
      </span>
      <IconArrow />
      <span>Documentos Médicos Recebidos</span>
    </section>

    <section class="clinical-documents__content">
      <form class="clinical-documents__search" @submit.prevent="handleSubmit">
        <SelectInput
          label="Tipo de Documento"
          placeholder="Defina o tipo de documento desejado!"
          :preselectFirst="false"
          :options="options"
          v-model="form.documentType"
        />

        <TextInput
          label="Data de Adição"
          :placeholder="todayFormated"
          :mask="'##/##/####'"
          v-model="form.date"
        />

        <button class="btn-search" :disabled="isLoading">
          <i class="fas fa-search" />
        </button>
      </form>

      <div class="clinical-documents__list">
        <table>
          <thead>
            <tr>
              <th><span>Arquivo</span></th>
              <th><span>Tipo de Arquivo</span></th>
              <th><span>Data de Consulta</span></th>
              <th><span></span></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="clinicalDocumentsData.length === 0">
              <td colspan="4" class="empty-message">
                <IconNotFoundDocuments :style="{ width: '240px' }" />
                <br />
                <span>Nenhum dado encontrado no momento. </span>
                <br />
                <span>Tente uma nova pesquisa.</span>
              </td>
            </tr>
            <tr v-else v-for="item in clinicalDocumentsData" :key="item.mvd_id">
              <td>{{ item.document }}</td>
              <td>{{ item.mdd_tipo_documento }}</td>
              <td>{{ item.date }}</td>
              <td>
                <button
                  class="btn-open-document"
                  @click="openUrl(item.mvd_documento_url)"
                >
                  <i class="fas fa-eye" />
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="4">
                <div class="grid-pagination__container">
                  <div class="grid-pagination__pages-arrows">
                    <span>{{ labelPagination }}</span>
                    <div class="grid-pagination__arrows">
                      <button :disabled="disablePrevPage" @click="goToPrevPage">
                        <i class="fas fa-chevron-left" />
                      </button>

                      <button :disabled="disableNextPage" @click="goToNextPage">
                        <i class="fas fa-chevron-right" />
                      </button>
                    </div>
                  </div>
                  <div class="grid-pagination__items-per-page">
                    <span>Itens por página</span>
                    <select v-model="pagination.perPage">
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </section>

    <section class="footer">
      <PlButton label="" primary arrowLeft @click="onClickBack" />
    </section>
  </section>
</template>

<script>
import moment from "moment";
import { ServicoHttp } from "@/axios/servico-http";

import PatientHeader from "../components/header/PatientHeader.vue";

import IconArrow from "@/assets/icons/IconArrow";
import IconNotFoundDocuments from "@/assets/icons/IconNotFoundDocuments";
import PlButton from "@/components/button/Button.vue";
import SelectInput from "@/components/select-input/SelectInput.vue";
import TextInput from "@/components/inputs/text-input/TextInput.vue";

const DocumentTypes = [
  { value: "RECEITA", name: "Receita" },
  { value: "EXAME", name: "Solicitação de Exame" },
  { value: "ATESTADO", name: "Atestado/Declaração" },
  { value: "ENCAMINHAMENTO", name: "Encaminhamento" },
  { value: "RELATORIO", name: "Relatório" },
];

export default {
  name: "PatientClinicalDocuments",
  components: {
    PatientHeader,
    PlButton,
    SelectInput,
    TextInput,
    IconNotFoundDocuments,
    IconArrow,
  },
  data() {
    return {
      patient: null,
      servicoHttp: null,
      options: DocumentTypes,
      todayFormated: moment().format("DD/MM/YYYY"),
      isLoading: false,
      clinicalDocumentsData: [],
      form: {
        date: null,
        documentType: null,
      },
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
        numberPages: 0,
      },
    };
  },
  computed: {
    disablePrevPage() {
      return this.pagination.page === 1;
    },
    disableNextPage() {
      return this.pagination.numberPages === this.pagination.page;
    },
    labelPagination() {
      const { page, total, perPage } = this.pagination;

      if (!total) {
        return "0 - 0 de 0";
      }

      const firsItem = perPage * (page - 1) + 1;
      const lastItem = page * perPage > total ? total : page * perPage;

      return `${firsItem} - ${lastItem} de ${total}`;
    },
  },

  watch: {
    "pagination.perPage"(value, previous) {
      if (value && previous && value !== previous) {
        this.handleSearchData();
      }
    },
  },

  mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.init();
  },

  methods: {
    async init() {
      this.patient = await this.$store.dispatch("getLocalStoragePatient");
      this.handleSearchData();
    },
    goToPrevPage() {
      if (this.pagination.page === 1) {
        return;
      }

      this.pagination.page = this.pagination.page - 1;
      this.handleSearchData();
    },
    goToNextPage() {
      if (this.pagination.numberPages === this.pagination.page) {
        return;
      }

      this.pagination.page = this.pagination.page + 1;
      this.handleSearchData();
    },
    async handleSearchData() {
      this.isLoading = true;

      try {
        const params = {
          cpf: this.patient.usu_cpf,
          page: this.pagination.page,
          perPage: this.pagination.perPage,
        };

        if (this.form.date !== null) {
          params["date"] = moment(this.form.date, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
        }

        if (this.form.documentType !== null) {
          params["type"] = this.form.documentType.value;
        }

        const { data: response } = await this.servicoHttp.get({
          url: "/api/mevo-integration/obter-documentos-por-cpf",
          params,
        });

        this.pagination = {
          ...this.pagination,
          total: response.total,
          numberPages: response.numberPages,
        };

        this.clinicalDocumentsData = response.data.map((item) => {
          const documentUrl = new URL(item.mvd_documento_url);
          const documentPathname = documentUrl.pathname.split("/");

          return {
            ...item,
            date: moment(item.mvd_created_at).format("DD/MM/YYYY"),
            document: documentPathname[documentPathname.length - 1],
          };
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleSubmit() {
      if (this.form.date !== null) {
        const date = moment(this.form.date, "DD/MM/YYYY");

        if (this.form.date.length > 0) {
          if (!date.isValid()) {
            return alert("Data de Consulta Inválida!");
          }
          if (date.isAfter(moment())) {
            return alert("Data deve ser igual ou inferior ao dia de hoje!");
          }
        } else {
          this.form.date = null;
        }
      }

      await this.handleSearchData();
    },
    openUrl(url) {
      window.open(url, "_blank");
    },
    onClickBack() {
      this.$router.push({ name: "pacienteHome" });
    },
  },
};
</script>
