<template>
  <svg width="41" height="44" viewBox="0 0 41 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_829_5892)">
    <path d="M40.9864 36.7473C40.9864 40.4438 38.1213 43.4404 34.587 43.4404H6.50952C2.97521 43.4404 0.110107 40.4438 0.110107 36.7473V9.38898L8.42935 0.687866H34.587C38.1213 0.687866 40.9864 3.68448 40.9864 7.38103V36.7473Z" fill="#E24C3F"/>
    <path opacity="0.2" d="M0.110107 14.0518V9.38892H4.54706L0.110107 14.0518Z" fill="#5D5D5D"/>
    <path d="M0.110107 9.38898H5.65632C7.18146 9.38898 8.42935 8.10606 8.42935 6.51092V0.687866L0.110107 9.38898Z" fill="#FF7063"/>
    <path d="M6.96143 29.4449V15.446H13.5863C14.2151 15.446 14.7737 15.5536 15.2617 15.7683C15.7495 15.9833 16.1539 16.282 16.475 16.6647C16.7958 17.0474 17.0399 17.504 17.2068 18.0344C17.3735 18.565 17.4571 19.1522 17.4571 19.7968C17.4571 20.4413 17.3768 21.0456 17.2163 21.6095C17.0557 22.1735 16.8182 22.6604 16.5039 23.0699C16.1891 23.4796 15.7881 23.802 15.3003 24.0367C14.8122 24.2719 14.241 24.3892 13.5862 24.3892H9.13755V29.445H6.96143V29.4449ZM9.13763 17.5408V22.2541H12.8353C13.6826 22.2541 14.3019 22.0561 14.6937 21.6599C15.0851 21.264 15.281 20.6427 15.281 19.7967C15.281 18.991 15.0723 18.4138 14.6551 18.0644C14.2378 17.7154 13.6311 17.5408 12.8353 17.5408H9.13763V17.5408Z" fill="#FF7063"/>
    <path d="M25.2952 15.8085L27.3944 15.2042V27.1284C27.3944 27.3837 27.4069 27.6118 27.4328 27.8133C27.4584 28.0147 27.4906 28.2029 27.529 28.3773C27.5803 28.5384 27.638 28.7097 27.7024 28.8909C27.7665 29.0722 27.8498 29.2569 27.9527 29.4448H25.738C25.661 29.3107 25.584 29.1294 25.5069 28.901C25.4554 28.6592 25.417 28.4446 25.3914 28.2565C25.0063 28.6592 24.5663 28.9986 24.0721 29.2737C23.5778 29.5487 23.0481 29.6865 22.4834 29.6865C21.8156 29.6865 21.2284 29.5723 20.7212 29.3441C20.2139 29.1159 19.7872 28.8003 19.4405 28.3975C19.094 27.9947 18.834 27.5147 18.6606 26.9573C18.4873 26.4003 18.4006 25.7991 18.4006 25.1545V24.1877C18.4006 22.4825 18.8306 21.2 19.691 20.3405C20.5509 19.4813 21.8028 19.0514 23.4463 19.0514C24.0752 19.0514 24.6914 19.1792 25.2951 19.4341L25.2952 15.8085ZM25.2952 21.4685C25.0126 21.2942 24.6885 21.1733 24.3227 21.1059C23.9568 21.0389 23.613 21.0053 23.2923 21.0053C22.7787 21.0053 22.3453 21.0792 21.9924 21.2267C21.6391 21.3747 21.3503 21.5827 21.1257 21.8512C20.9009 22.1199 20.7407 22.4422 20.6442 22.818C20.548 23.1941 20.4998 23.6036 20.4998 24.0467V24.792C20.4998 25.1681 20.5254 25.5341 20.5768 25.8898C20.628 26.2457 20.7276 26.5579 20.8754 26.8263C21.0227 27.0951 21.225 27.3131 21.4819 27.4809C21.7386 27.649 22.0723 27.7327 22.4834 27.7327C22.753 27.7327 23.0193 27.6858 23.2827 27.5917C23.5456 27.4979 23.7993 27.3736 24.0434 27.2191C24.2871 27.0648 24.5152 26.8936 24.7269 26.7054C24.9388 26.5175 25.1282 26.3227 25.2952 26.1213V21.4685Z" fill="#FF7063"/>
    <path d="M30.0712 18.1647C30.0712 17.789 30.1193 17.423 30.2157 17.067C30.3119 16.7114 30.4627 16.3957 30.6681 16.1203C30.8734 15.8453 31.1431 15.6237 31.477 15.4557C31.8107 15.2879 32.2087 15.2039 32.6711 15.2039C32.8892 15.2039 33.1362 15.2275 33.4125 15.2744C33.6885 15.3216 33.9291 15.3785 34.1347 15.4456V17.1778H33.3643C32.9662 17.1778 32.6678 17.292 32.4689 17.5203C32.2698 17.7488 32.1704 18.0641 32.1704 18.4669V19.2928H34.0386L33.4801 21.1056H32.1705V29.4445H30.0713V21.1056H28.8582V19.2928H30.0713V18.1647H30.0712Z" fill="#FF7063"/>
    <path d="M6.96143 28.9432V14.9443H13.5863C14.2151 14.9443 14.7737 15.0519 15.2617 15.2666C15.7495 15.4815 16.1539 15.7803 16.475 16.163C16.7958 16.5457 17.0399 17.0023 17.2068 17.5327C17.3735 18.0633 17.4571 18.6505 17.4571 19.2951C17.4571 19.9396 17.3768 20.5439 17.2163 21.1078C17.0557 21.6718 16.8182 22.1587 16.5039 22.5682C16.1891 22.9779 15.7881 23.3002 15.3003 23.535C14.8122 23.7702 14.241 23.8875 13.5862 23.8875H9.13755V28.9432H6.96143V28.9432ZM9.13763 17.0391V21.7524H12.8353C13.6826 21.7524 14.3019 21.5544 14.6937 21.1582C15.0851 20.7623 15.281 20.141 15.281 19.295C15.281 18.4893 15.0723 17.9121 14.6551 17.5627C14.2378 17.2137 13.6311 17.039 12.8353 17.039H9.13763V17.0391Z" fill="white"/>
    <path d="M25.2952 15.3065L27.3944 14.7021V26.6263C27.3944 26.8816 27.4069 27.1097 27.4328 27.3112C27.4584 27.5126 27.4906 27.7008 27.529 27.8752C27.5803 28.0363 27.638 28.2076 27.7024 28.3888C27.7665 28.5701 27.8498 28.7548 27.9527 28.9427H25.738C25.661 28.8086 25.584 28.6273 25.5069 28.3989C25.4554 28.1571 25.417 27.9425 25.3914 27.7544C25.0063 28.1571 24.5663 28.4965 24.0721 28.7716C23.5778 29.0466 23.0481 29.1845 22.4834 29.1845C21.8156 29.1845 21.2284 29.0703 20.7212 28.842C20.2139 28.6139 19.7872 28.2982 19.4405 27.8954C19.094 27.4926 18.834 27.0126 18.6606 26.4552C18.4873 25.8982 18.4006 25.297 18.4006 24.6524V23.6856C18.4006 21.9805 18.8306 20.698 19.691 19.8385C20.5509 18.9792 21.8028 18.5494 23.4463 18.5494C24.0752 18.5494 24.6914 18.6771 25.2951 18.9321L25.2952 15.3065ZM25.2952 20.9664C25.0126 20.7921 24.6885 20.6713 24.3227 20.6038C23.9568 20.5368 23.613 20.5032 23.2923 20.5032C22.7787 20.5032 22.3453 20.5771 21.9924 20.7246C21.6391 20.8726 21.3503 21.0806 21.1257 21.3491C20.9009 21.6178 20.7407 21.9401 20.6442 22.3159C20.548 22.692 20.4998 23.1016 20.4998 23.5446V24.2899C20.4998 24.666 20.5254 25.032 20.5768 25.3877C20.628 25.7436 20.7276 26.0558 20.8754 26.3242C21.0227 26.593 21.225 26.8111 21.4819 26.9788C21.7386 27.1469 22.0723 27.2306 22.4834 27.2306C22.753 27.2306 23.0193 27.1837 23.2827 27.0896C23.5456 26.9958 23.7993 26.8716 24.0434 26.717C24.2871 26.5627 24.5152 26.3916 24.7269 26.2033C24.9388 26.0154 25.1282 25.8206 25.2952 25.6193V20.9664Z" fill="white"/>
    <path d="M30.0712 17.663C30.0712 17.2873 30.1193 16.9213 30.2157 16.5653C30.3119 16.2097 30.4627 15.894 30.6681 15.6186C30.8734 15.3436 31.1431 15.122 31.477 14.954C31.8107 14.7862 32.2087 14.7021 32.6711 14.7021C32.8892 14.7021 33.1362 14.7257 33.4125 14.7727C33.6885 14.8199 33.9291 14.8768 34.1347 14.9439V16.6761H33.3643C32.9662 16.6761 32.6678 16.7903 32.4689 17.0186C32.2698 17.2471 32.1704 17.5624 32.1704 17.9652V18.7911H34.0386L33.4801 20.6038H32.1705V28.9428H30.0713V20.6038H28.8582V18.7911H30.0713V17.663H30.0712Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_829_5892">
    <rect width="40.8763" height="42.7526" fill="white" transform="translate(0.110107 0.687866)"/>
    </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconPdf",
};
</script>
