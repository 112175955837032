<style src="./Textarea.scss" lang="scss" scoped></style>
<template>
  <fieldset class="input--component">
    <label v-if="label" class="label">
      {{ label }} <span class="is--required" v-if="required">*</span>
    </label>
    <div class="input--base">
      <div class="input--textbox-container">
        <textarea
          :id="getId"
          ref="inputFieldId"
          v-model="innerValue"
          :data-id="dataId"
          :type="type"
          :placeholder="placeholder"
          :maxlength="maxlength"
          :disabled="disabled"
          class="textarea small-scroll"
        />

        <span v-show="viewCharacters" class="remaining-character">
          Caracteres restantes: {{ remainCharacters }}
        </span>
      </div>
    </div>
  </fieldset>
</template>
<script>
import isEmpty from "lodash/isEmpty";
import InputMixin from "../InputMixin";
export default {
  name: "Textarea",
  inheritAttrs: false,
  components: {},
  mixins: [InputMixin],
  props: {
    type: {
      type: String,
      default: "text",
    },
    viewCharacters: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      remainCharacters: this.maxlength,
      dataId: null,
    };
  },
  computed: {
    getId() {
      return !isEmpty(this.id) ? this.id : this.dataId;
    },
  },
  watch: {
    innerValue(pValue) {
      this.characterCount();
    },
  },
  methods: {
    characterCount(event) {
      if (this.innerValue && this.innerValue.length <= this.maxlength) {
        this.remainCharacters = this.maxlength - this.innerValue.length;
      } else {
        this.remainCharacters = this.maxlength;
      }
    },
    resetCharacter() {
      this.remainCharacters = this.maxlength;
    },
  },
};
</script>
