<style src="./Term.scss" lang="scss"></style>

<template>
  <section class="">
    <img class="logo" :src="appLogoWhite" />

    <p class="firstheading">
      TERMOS E CONDIÇÕES GERAIS DE USO DO APLICATIVO AXIA SAÚDE
    </p>
    <p class="paragraph">
      Por meio deste instrumento, a pessoa jurídica AXIA SERVICOS LTDA, pessoa
      jurídica de direito privado, regularmente inscrita no CNPJ sob o nº
      53.460.617/0001-56, estabelece os Termos de Uso aplicáveis ao site e/ou
      aplicativo AXIA SAÚDE, de modo a descrever as obrigações, direitos e
      responsabilidades de seus Usuários.
    </p>
    <p class="subHeading">1. DO OBJETO</p>
    <p class="subParagraph">
      1.1. A plataforma visa licenciar o uso de seu software, aplicativos,
      website e demais ativos de sua propriedade intelectual ou os quais possui
      autorização, fornecendo ferramentas para auxiliar e dinamizar o dia a dia
      dos seus usuários.
    </p>
    <p class="subParagraph">
      1.2. Uma vez que o uso da Plataforma MEDICAR apenas pode ser utilizada
      após a leitura destes Termos e Condições de Uso, restando claro que sua
      utilização significará a concordância integral com os Termos e Condições
      de Uso apresentados. Caso o Usuário não concorde com qualquer uma das
      disposições nestes Termos e Condições de Uso, ele não deverá utilizar a
      Plataforma MEDICAR. Sendo assim, fica claro e evidente que o uso da
      Plataforma MEDICAR não está autorizado em caso de não concordância com
      estes Termos e Condições de Uso.
    </p>
    <p class="subParagraph">
      1.3. Desta forma, a plataforma AXIA SAÚDE realiza a prestação dos
      seguintes serviços: Serviços de Teleconsultas Médicas, disponibilizando,
      também, monitoramento feito por uma equipe de enfermagem, Teleconsultas de
      Nutrição e Psicologia, todos através do aplicativo “AXIA SAÚDE” e de uma
      “Central Telefônica”.
    </p>
    <p class="subHeading">2. DA ACEITAÇÃO</p>
    <p class="subParagraph">
      2.1. O presente Termo estabelece obrigações contratadas de livre e
      espontânea vontade, durante o tempo de vigência contratado pelo usuário,
      entre a plataforma e os usuários do aplicativo AXIA SAÚDE.
    </p>
    <p class="subParagraph">
      2.2. Ao utilizar a plataforma, o usuário aceita integralmente as presentes
      normas e compromete-se a observá-las, sob o risco de aplicação das
      penalidades cabíveis.
    </p>
    <p class="subParagraph">
      2.3. A aceitação do presente instrumento é imprescindível para o acesso e
      para a utilização de quaisquer serviços fornecidos pela empresa. Caso não
      concorde com as disposições deste instrumento, o usuário não deve
      utilizá-los.
    </p>
    <p class="definition">
      MEDICAR: Plataforma de telemedicina já qualificada no início destes Termos
      e Condições de Uso.
    </p>
    <p class="subHeading">3. DO ACESSO DOS USUÁRIOS</p>
    <p class="subParagraph">
      3.1. Serão utilizadas todas as soluções técnicas à disposição do
      responsável pela plataforma para permitir o acesso aos serviços 24 (vinte
      e quatro) horas por dia, 7 (sete) dias por semana. No entanto, a navegação
      na plataforma ou em alguma de suas páginas poderá ser interrompida,
      limitada ou suspensa para atualizações, modificações ou qualquer ação
      necessária ao seu bom funcionamento.
    </p>
    <p class="subHeading">3. DO CADASTRO</p>
    <p class="subParagraph">
      4.1. Para que o usuário tenha acesso às funcionalidades da plataforma
      “AXIA SAÚDE”, este deverá, de forma obrigatória, realizar seu cadastro
      prévio, assim como o pagamento do valor do serviço contratado. O usuário
      deverá, também, aceitar o Contrato de Prestação de Serviços da AXIA SAÚDE
      e demais termos de consentimento, a fim de que possa concluir o pagamento.
    </p>
    <p class="subParagraph">
      4.2. Ao realizar o seu cadastro, o usuário deverá informar seus dados
      completos, recentes, verdadeiros e válidos, sendo de sua exclusiva
      responsabilidade manter os referidos dados atualizados.
    </p>

    <p class="subParagraph">
      4.3. O usuário se compromete a não informar seus dados cadastrais e/ou de
      acesso à plataforma a terceiros, responsabilizando-se integralmente pelo
      uso que deles seja feito.
    </p>
    <p class="subParagraph">
      4.4 Mediante a realização do cadastro, o usuário declara e garante
      expressamente ser plenamente capaz, podendo exercer e usufruir livremente
      dos serviços e produtos.
    </p>
    <p class="subParagraph">
      4.5 Menores de 18 anos, bem como aqueles que não possuírem plena
      capacidade civil deverão obter previamente o consentimento expresso de
      seus responsáveis legais para utilização da plataforma e dos serviços ou
      produtos, sendo de responsabilidade exclusiva destes o eventual acesso por
      menores de idade e por aqueles que não possuem plena capacidade civil sem
      a prévia autorização.
    </p>
    <p class="subParagraph">
      4.6 O usuário deverá fornecer um endereço de e-mail válido, através do
      qual o site realizará todas as comunicações necessárias.
    </p>
    <p class="subParagraph">
      4.7 Após a confirmação do cadastro, o usuário possuirá um login e uma
      senha pessoal, a qual assegura ao usuário o acesso individual à mesma.
      Desta forma, compete ao usuário exclusivamente a manutenção de referida
      senha de maneira confidencial e segura, evitando o acesso indevido às
      informações pessoais.
    </p>
    <p class="subParagraph">
      4.8 Toda e qualquer atividade realizada com o uso da senha será de
      responsabilidade do usuário, que deverá informar prontamente a plataforma
      em caso de uso indevido da respectiva senha.
    </p>
    <p class="subParagraph">
      4.9 Não será permitido ceder, vender, alugar ou transferir, de qualquer
      forma, a conta, que é pessoal e intransferível.
    </p>
    <p class="subParagraph">
      4.10 Caberá ao usuário assegurar que o seu equipamento seja compatível com
      as características técnicas que viabilize a utilização da plataforma e dos
      serviços ou produtos.
    </p>
    <p class="subHeading">5. DOS SERVIÇOS PRESTADOS</p>

    <p class="subParagraph">
      5.1 A AXIA SAÚDE oferecerá ao usuário os seguintes serviços, abaixo
      listados:
    </p>
    <p class="subParagraph">
      Serviços de Pronto Atendimento 24h: O profissional médico responsável pelo
      Pronto Atendimento 24h, que será ofertado em todos os pacotes, deverá
      informar, recomendar, orientar virtualmente por meio do chat de mensagens
      ou videochamada, aos beneficiários da CONTRATADA, a respeito de
      enfermidades, sintomas, dúvidas ou inquietações de saúde, sobre as
      providências e ações preliminares convenientes que devam ser adotadas e
      orientação sobre medicamentos de venda livre (OTC) já em uso pelo usuário,
      sempre seguindo protocolos de teleatendimento baseados em evidências
      científicas reconhecidas nacional e internacionalmente, de acordo com o
      art. 3º e parágrafos da Resolução CFM nº 2314/202.
    </p>
    <p class="subParagraph">
      Os serviços de Teleconsulta Médica de Atenção Primária compreendem
      consultas de:
    </p>
    <ul class="subParagraph">
      <li>
        Demanda Espontânea: O beneficiário será direcionado no mesmo dia para a
        classificação de risco com o profissional enfermeiro e conforme a mesma
        poderá: ser encaminhado para a emergência/urgência, ser atendido no
        mesmo dia, ser agendado ou ter sua demanda resolvida. São os seguintes
        casos:
        <ul>
          <li>(a) Cefaleias;</li>
          <li>(b) Febre;</li>
          <li>(c) Diarreia;</li>
          <li>(d) Vômitos;</li>
          <li>(e) Queixas urinárias;</li>
          <li>(f) Lesões de pele (por exemplo: escabiose, herpes zoster);</li>
          <li>(g) Dor abdominal leve a moderada;</li>
          <li>(h) Dor de garganta;</li>
          <li>(i) Dor lombar;</li>
          <li>
            (j) Alterações leve da pressão, excetuando as urgência/emergências
            hipertensivas;
          </li>
          <li>(k) Mordedura de animais (Orientações);</li>
          <li>(l) Queimaduras leves/ primeiro grau;</li>
          <li>(m) Resfriados;</li>
          <li>(n) Queixas oftalmológicas “olho vermelho sem dor”;</li>
          <li>(o) Alergias leves;</li>
        </ul>
      </li>
      <li>
        Agendamento de Demandas Programadas: O beneficiário deverá solicitar o
        agendamento da teleconsulta através do aplicativo “AXIA SAÚDE”.
      </li>
      <li>Serviços de Monitoramento realizado por uma equipe de enfermagem;</li>
      <li>
        Teleconsultas de Psicologia e Serviços de Nutrição, todos através do
        aplicativo “AXIA SAÚDE”.
      </li>
    </ul>
    <p class="subHeading">6. DOS PREÇOS</p>
    <p class="subParagraph">
      6.1. Os preços dos serviços estão descritos no site institucional da
      empresa. Os valores aplicados são aqueles que estão em vigor no momento do
      pedido.
    </p>
    <p class="subParagraph">
      6.2. Na contratação dos serviços, a plataforma poderá solicitar as
      informações financeiras do usuário, como CPF, endereço de cobrança e dados
      de cartões. Ao inserir referidos dados o usuário concorda que sejam
      cobrados, de acordo com a forma de pagamento que venha a ser escolhida, os
      preços então vigentes e informados quando da contratação. Referidos dados
      financeiros poderão ser armazenados para facilitar acessos e contratações
      futuras.
    </p>
    <p class="subHeading">7. DO CANCELAMENTO</p>
    <p class="subParagraph">
      7.1. O usuário poderá cancelar a contratação dos serviços de acordo com os
      termos que forem definidos no momento de sua contratação, através do
      Contrato de Prestação de Serviços. Ainda, o usuário também poderá cancelar
      os serviços em até 7 (sete) dias corridos após a contratação, mediante
      contato com a Contratada.
    </p>
    <p class="subParagraph">
      7.2. Além disso, o Contrato poderá ser cancelado nas seguintes situações:
    </p>
    <ul class="subParagraph">
      <li>
        a) Violação dos Termos de Uso pelo Usuário: os serviços serão cessados
        imediatamente.
      </li>
      <li>
        b) Nas demais situações listadas no Contrato de Prestação de Serviços.
      </li>
    </ul>
    <p class="subHeading">8. DO SUPORTE</p>
    <p class="subParagraph">
      8.1. Em caso de qualquer dúvida, sugestão ou problema com a utilização da
      plataforma, o usuário poderá entrar em contato com o suporte, através do
      e-mail: contato@axiasaude.com. Estes serviços de atendimento ao usuário
      estarão disponíveis em dias úteis, das 09h00 às 17h00.
    </p>
    <p class="subHeading">9. DAS RESPONSABILIDADES</p>
    <p class="subParagraph">9.1. É de responsabilidade do usuário:</p>
    <ul class="subParagraph">
      <li>
        a) Defeitos ou vícios técnicos originados no próprio sistema do usuário;
      </li>
      <li>
        b) A correta utilização da plataforma, dos serviços ou produtos
        oferecidos, prezando pela boa convivência, pelo respeito e cordialidade
        entre os usuários;
      </li>
      <li>
        c) Pelo cumprimento e respeito ao conjunto de regras disposto nesse
        Termo de Condições Geral de Uso, na respectiva Política de Privacidade e
        na legislação nacional e internacional;
      </li>
      <li>
        d) Pela proteção aos dados de acesso à sua conta/perfil (login e senha).
      </li>
    </ul>
    <p class="subParagraph">
      9.2. É de responsabilidade da plataforma AXIA SAÚDE:
    </p>
    <ul class="subParagraph">
      <li>a) Indicar as características do serviço ou produto;</li>
      <li>
        b) Os defeitos e vícios encontrados no serviço ou produto oferecido
        desde que lhe tenha dado causa;
      </li>
      <li>
        c) As informações que foram por ele divulgadas, sendo que os comentários
        ou informações divulgadas por usuários são de inteira responsabilidade
        dos próprios usuários;
      </li>
      <li>
        d) Os conteúdos ou atividades ilícitas praticadas através da sua
        plataforma.
      </li>
    </ul>
    <p class="subParagraph">
      9.3. A plataforma não se responsabiliza por links externos contidos em seu
      sistema que possam redirecionar o usuário à ambiente externo a sua rede.
    </p>
    <p class="subParagraph">
      9.4. Não poderão ser incluídos links externos ou páginas que sirvam para
      fins comerciais ou publicitários ou quaisquer informações ilícitas,
      violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias ou
      ofensivas.
    </p>
    <p class="subHeading">10. DOS DIREITOS AUTORAIS</p>
    <p class="subParagraph">
      10.1. O presente Termo de Uso concede aos usuários uma licença não
      exclusiva, não transferível e não sublicenciável, para acessar e fazer uso
      da plataforma e dos serviços e produtos por ela disponibilizados.
    </p>
    <p class="subParagraph">
      10.2. A estrutura do site ou aplicativo, as marcas, logotipos, nomes
      comerciais, layouts, gráficos e design de interface, imagens, ilustrações,
      fotografias, apresentações, vídeos, conteúdos sonoros, programas de
      computador, banco de dados, arquivos de transmissão e quaisquer outras
      informações e direitos de propriedade intelectual da razão social AXIA
      SERVIÇOS LTDA, observadas as disposições da Lei da Propriedade Industrial
      (Lei nº 9.279/96), Lei de Direito Autoral (Lei nº 9.610/98) e Lei do
      Software (Lei nº 9.609/98), estão devidamente reservados.
    </p>
    <p class="subParagraph">
      10.3. Este Termo de Uso não cede ou transfere ao usuário qualquer direito,
      de modo que o acesso não gera qualquer direito de propriedade intelectual
      ao usuário, exceto pela licença limitada ora concedida.
    </p>
    <p class="subParagraph">
      10.4. O uso da plataforma pelo usuário é pessoal, individual e
      intransferível, sendo vedado qualquer uso não autorizado, comercial ou não
      comercial. Tais usos consistirão em violação dos direitos de propriedade
      intelectual da AXIA SAÚDE, puníveis nos termos da legislação aplicável.
    </p>
    <p class="subHeading">11. DAS SANÇÕES</p>
    <p class="subParagraph">
      11.1. Sem prejuízo das demais medidas legais cabíveis, a AXIA SAÚDE
      poderá, a qualquer momento, advertir, suspender ou cancelar a conta do
      usuário:
    </p>
    <ul class="subParagraph">
      <li>a) Que violar qualquer dispositivo do presente Termo;</li>
      <li>b) Que descumprir os seus deveres de usuário;</li>
      <li>
        c) Que tiver qualquer comportamento fraudulento, doloso ou que ofenda a
        terceiros.
      </li>
    </ul>
    <p class="subHeading">12. DA RESCISÃO</p>
    <p class="subParagraph">
      12.1. A não observância das obrigações pactuadas neste Termo de Uso ou na
      legislação aplicável poderá, sem prévio aviso, ensejar a imediata rescisão
      unilateral por parte da AXIA SAÚDE e o bloqueio de todos os serviços
      prestados ao usuário.
    </p>
    <p class="subHeading">13. DAS ALTERAÇÕES</p>
    <p class="subParagraph">
      13.1. Os itens descritos no presente instrumento poderão sofrer
      alterações, unilateralmente e a qualquer tempo, por parte da AXIA SAÚDE,
      para adequar ou modificar os serviços, bem como para atender novas
      exigências legais. As alterações serão veiculadas pelo site ou pelo
      aplicativo e o usuário poderá optar por aceitar o novo conteúdo ou por
      cancelar o uso dos serviços, caso seja assinante de algum serviço.
    </p>
    <p class="subHeading">14. DA POLÍTICA DE PRIVACIDADE</p>
    <p class="subParagraph">
      14.1. Além do presente Termo de Uso, o usuário deverá consentir com as
      disposições contidas na respectiva Política de Privacidade a ser
      apresentada a todos os interessados dentro da interface da plataforma.
    </p>
    <p class="subHeading">15. DO FORO</p>
    <p class="subParagraph">
      15.1. Para a solução de controvérsias decorrentes do presente instrumento
      será aplicado integralmente o Direito brasileiro.
    </p>
    <p class="subParagraph">
      15.2. Os eventuais litígios deverão ser apresentados no foro da comarca em
      que se encontra a sede da empresa.
    </p>
    <p class="definition">
      ACEITE: Em virtude das cláusulas do presente Contrato, o USUÁRIO declara
      que concorda integralmente com todas as disposições nele contidas,
      obrigando-se, desde já, a respeitá-las durante toda a vigência de
      contratação da AXIA SAÚDE.
    </p>
    <br />

    <img class="logo" :src="appLogoWhite" />
    <br />
    <br />
    <br />
    <a href="https://axiasaude.com/" class="link">
      <span>https://axiasaude.com/</span>
    </a>
    <br />
    <br />
    <a href="https://axiasaude.com/politica-de-privacidade-e-termos-de-uso/" class="link">
      <span>Política de Privacidade e Termos de Uso</span>
    </a>
    <br />
    <br />

    <a href="mailto:CONTATO@AXIASAUDE.COM" class="link">
      <span>CONTATO@AXIASAUDE.COM</span>
    </a>
    <br />
    <br />
  </section>
</template>

<script>
export default {
  name: "TermAxia",
  components: {},
};
</script>
