<style src="./FileInput.scss" lang="scss" scoped></style>

<template>
  <section class="file-input">
    <div
      id="fileDragDrog"
      @dragover.prevent="updateDragDropFocus(true)"
      @dragleave.prevent="updateDragDropFocus(false)"
      @dragenter.prevent="updateDragDropFocus(true)"
      @drop.prevent="updateDragDropFocus(false)"
    >
      <form ref="fileForm" :class="{ 'is-hovered': dragDropFocus }">
        <span class="drop-files">
          {{ label }}
          <!-- <fa
            name="fa-arrow-up"
            clickable
            title="Enviar Arquivo"
            color="#1b71a2"
          /> -->
        </span>
        <input
          id="file-input"
          ref="input"
          type="file"
          v-bind="$attrs"
          :accept="accept"
          :disabled="disabled"
          :multiple="multiple"
          @change="dataTransfer"
        />
      </form>
    </div>
    <div class="file-listing">
      <div class="preview-item" v-for="(file, i) in files" :key="i">
        <div class="preview-img-container" v-if="isImg(i)">
          <img class="preview-img" :ref="`preview ${parseInt(i)}`" />
        </div>
        <div class="preview-pdf-container" v-else>
          <i class="fas fa-file" />
        </div>
        <div class="preview-info">
          <span class="preview-title" :title="file.name">{{ file.name }}</span>
          <!-- <fa
            name="fa-ban"
            title="Remover"
            clickable
            color="#f15959"
            class="remove-icon"
            @click="removeFile(i)"
          /> -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import InputMixin from '../InputMixin'
// import Fa from '~lovelace/foundation/fa/Fa'

export default {
  name: 'FileInput',
  components: {
    // Fa
  },
  mixins: [InputMixin],
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: 'Adicione um arquivo'
    },
    value: {
      type: [Object, Function, File, Array]
    },
    disabled: Boolean,
    accept: String,
    dragDrop: Boolean,
    type: {
      type: String,
      default: 'is-primary'
    },
    native: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      dragAndDropCapable: false,
      dragDropFocus: false,
      files: []
    }
  },
  mounted () {
    this.dragAndDropCabaple = this.determineDragAndDropCapable()

    if (this.dragAndDropCabaple) {
      ;[
        'drag',
        'dragstart',
        'dragend',
        'dragover',
        'dragenter',
        'dragleave',
        'drop'
      ].forEach(
        function (event) {
          this.$refs.fileForm.addEventListener(
            event,
            function (e) {
              e.preventDefault()
              e.stopPropagation()
            },
            false
          )
        }.bind(this)
      )

      this.$refs.fileForm.addEventListener(
        'drop',
        function (e) {
          e.preventDefault()
          const files = e.dataTransfer.files
          this.dataTransfer(files, false)
        }.bind(this)
      )
    }
  },

  watch: {
    value (file) {
      if (file === null || file.length === 0) {
        this.removeAllFiles()
      }
    }
  },

  methods: {
    dataTransfer (e, fromInput = true) {
      const value = fromInput ? e.target.files : e

      for (let i = 0; i < value.length; i++) {
        this.files.push(value[i])
        this.getImagePreview()
        this.$emit('input', this.files)
      }
    },

    determineDragAndDropCapable () {
      var div = document.createElement('div')
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      )
    },

    updateDragDropFocus (focus) {
      this.dragDropFocus = focus
    },

    getImagePreview () {
      for (let i = 0; i < this.files.length; i++) {
        if (this.isImg(i)) {
          let reader = new FileReader()

          reader.addEventListener(
            'load',
            function () {
              this.$refs['preview' + parseInt(i)][0].src = reader.result
            }.bind(this),
            false
          )

          reader.readAsDataURL(this.files[i])
        }
      }
    },

    removeFile (index) {
      this.files.splice(index, 1)
      document.getElementById('file-input').value = null
      this.getImagePreview()
    },

    removeAllFiles () {
      this.files = []
      this.getImagePreview()
    },

    isImg (i) {
      const point = this.files[i].type.indexOf('/')
      const type = this.files[i].type.substr(0, point)
      return type === 'image'
    }
  }
}
</script>
