import moment from 'moment'
import Firestore from './firestore'
// *** *** ***
let aFunIdsAlreadyTested = [1,2,3,4]
let runCallingCircle = true
let objTimeout = null
let currentProfessionalId = null

// *** *** ***
// * ARRAY FunIds Testados
const getFunIdsAlreadyTested = () => {
  return aFunIdsAlreadyTested
}
const pushFunIdsAlreadyTested = (professionalId) => {
  return aFunIdsAlreadyTested.push(professionalId)
}

// *** *** ***
// * VIDEO
const funcionarioEnteredVideo = () => {
  clearTimeout(objTimeout)
  runCallingCircle = false
}

// *** *** ***
// * CALLING CIRCLE
const forceNext = async (pacId, pacNome, spaId, agoraUID, agoraRoomName, agoraToken) => {
  runCallingCircle = false
  clearTimeout(objTimeout)
  await Firestore.cleanShowModalByPacId(pacId)
  Firestore.updatePatientSpecificKey(pacId, 'comando', 'nenhum')
  runCallingCircle = true
  callingCircle(pacId, pacNome, spaId, agoraUID, agoraRoomName, agoraToken)
}

const stopCallingCircle = () => {
  clearTimeout(objTimeout)
  runCallingCircle = false
}

const startCallingCircle = async (objPac, spaId, agoraUID, agoraRoomName, agoraToken) => {
  runCallingCircle = true
  const date = moment().format('DD/MM/YYYY - HH:mm:ss')
  Firestore.updatePatientSpecificKey(objPac.pacId, 'entrouFila', date)
  callingCircle(objPac, spaId, agoraUID, agoraRoomName, agoraToken)
}

const callingCircle = (objPac, spaId, agoraUID, agoraRoomName, agoraToken) => {
  if (!runCallingCircle) {
    console.warn('[callingCircle] parar CallingCircle')
    return
  }
  // getFuncionarioFree
  Firestore.getProfessionalLinkedListFree(objPac, spaId, agoraUID, agoraRoomName, agoraToken, (result) => {
    if (result.status === true) {
      currentProfessionalId = result.professionalId
    } else {
      currentProfessionalId = null
      console.warn('[callingCircle] Status false: ', result)
    }
    if (!runCallingCircle) {
      console.warn('[callingCircle] parar CallingCircle')
      return
    }
    let time = process.env.VUE_APP_SETTIMEOUT_CC_SEG || 35
    if (result.time) {
      time = result.time
    }
    console.warn('[callingCircle] contagem de tempo (em seg)', time)
    objTimeout = setTimeout(() => {
      if (!runCallingCircle) {
        console.warn('[callingCircle] parar CallingCircle')
        return
      }
      callingCircle(objPac, spaId, agoraUID, agoraRoomName, agoraToken)
    }, time * 1000)
  })
}

const startMedicCallingCircle = async (objPac, spaId, agoraUID, agoraRoomName, agoraToken) => {
  Firestore.setPatientFirstFreeMedico(
    objPac, spaId, 
    agoraUID, agoraRoomName, agoraToken, 
    (ret) => {
      if (ret.status) {
        console.warn('Colocou para ligar')
      } else {
        console.warn('Foi pra fila virtual do medico')
      }
    }
  )
}

export default {
    getFunIdsAlreadyTested,
    pushFunIdsAlreadyTested,
    startCallingCircle,
    funcionarioEnteredVideo,
    stopCallingCircle,
    forceNext,
    startMedicCallingCircle
}