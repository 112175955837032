<style src="./Home.scss" lang="scss" scoped></style>
<template>
  <section class="home">
    <!-- FORM CPF -->
    <section v-if="qsCpf && !showRoom" class="back-form-box">
      <section class="form-box">
        <div class="title">Portal</div>
        <div class="title">Videoconferência</div>
        <!-- <div class="subtitle">Seja bem-vindo!</div> -->
      </section>
    </section>
    
    <!-- FORM CPF -->
    <section v-else-if="!qsCpf && !showRoom" class="back-form-box">
      <section v-if="!showRoom" class="form-box">      
        <div class="title">Portal</div>
        <div class="title">Videoconferência</div>
        <div class="subtitle">Digite o CPF do paciente para criar uma sala de Atendimento</div>
        
        <div class="cpf">
          <InputText 
            placeholder="Digite aqui o CPF do paciente"
            mask="###.###.###-##"
            v-model="inputCpf"
          />
        </div>
        
        <div class="act">
          <div class="btn" @click="actOpenRoom">Criar Sala</div>
        </div>
      </section>
    </section>

    <!-- VIDEOCONFERENCIA -->
    <section v-if="showRoom" class="videoconferencia">
      <Video :cpf="inputCpf" @close="onCloseRoom" :startRoom="startRoom"/>
    </section>
    <!-- *** -->
  </section>
</template>

<script>
import Firebase from '@/helpers/firebase'

import InputText from '@/components/input/text/InputText'
import Video from '@/views/cabine/video/Video'

export default {
  name: 'PortaVideoHome',
  components: {
    InputText,
    Video
  },

  data () {
    return {
      inputCpf: null,

      showRoom: false,
      startRoom: false,
      qsCpf: false
    }
  },

  mounted () {
    const qs = this.$route.query
    qs.env ? Firebase.setFirebaseConfig(qs.env) : Firebase.setFirebaseConfig(null)
    if (!qs.id) {
      console.warn('QueryString ID não identificado')
    } else {
      this.qsCpf = true
      const id = this.$route.query.id
      Firebase.onCallRoom(id, (obj) => {
        if (!obj) {
          return
        }
        if (obj.cpf && !this.showRoom) {
          this.inputCpf = obj.cpf
          this.showRoom = true
          setTimeout(()=> {
            this.startRoom = true
          }, 250)
        }
      })
    }
  },

  methods: {
    actOpenRoom () {
      if (!this.validateCpf()) {
        return
      }
      this.showRoom = true
      setTimeout(()=> {
        this.startRoom = true
      }, 250)
    },
    onCloseRoom () {
      this.showRoom = false
      this.startRoom = false
      this.inputCpf = null
    },

    validateCpf() {
      if(!this.inputCpf) {
        return false
      }
      if(this.inputCpf.length < 14) {
        return false
      }
      return true
    }
  }
}
</script>
