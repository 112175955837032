import moment from 'moment'

import { ServicoHttpCallingCircle } from '@/axios/servico-http-calling-circle'
import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";


import { initializeApp } from 'firebase/app'
import {
  getFirestore, addDoc, collection,
  query, where, updateDoc, getDocs,
  doc, onSnapshot, setDoc, deleteDoc,
  getDoc, deleteField
} from 'firebase/firestore'
import Gear from './gear'

import Enum from './enum'
// const Enum.COLLECTION.PROFESSIONAL = 'funcionarios'

let instanceFirestore = null
const colecaoPacientes = 'pacientes'
const colecaoFilaVirtual = 'fila-virtual'
const colecaoFilaVirtualMedico = 'fila-virtual-medico'
const colecaoChat = 'chat-pacientes'
const colecaoFuncionarios = 'funcionarios'


const setFirestoreConfig = () => {
  let config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
  }
  const fbInit = initializeApp(config)
  if (!instanceFirestore) {
    instanceFirestore = getFirestore(fbInit)
    console.log('Inicializando Firestore')
  } else {
    console.warn('instanceFirestore ja inicializado')
  }
}

// *** *** ***
// * FILA VIRTUAL
const addPatientVirtualQueue = async (objPatient, spaId, agoraUID, agoraRoomName, agoraToken) => {
  try {
    if (!objPatient.pacId || !objPatient.pacNome || !agoraRoomName || !agoraToken || !agoraUID) {
      console.warn('[addPatientVirtualQueue] faltando props')
      return {
        status: false
      }
    }
    // validar se já tem profesisonalId no firestore
    const querySnapshot = await getVirtualQueueByPatientId(objPatient.pacId)
    if (!querySnapshot.empty) {
      console.warn('[addPatientVirtualQueue] Já existe um documento com o mesmo pacId.')
      return {
        status: false,
        msg: 'já existe'
      }
    }

    let num = await getLengthFilaVirtual()
    num++
    const docId = `${num}_${new Date().getTime()}`
    const docRef = doc(instanceFirestore, colecaoFilaVirtual, docId)
    const payload = {
      pac_id: objPatient.pacId,
      pac_nome: objPatient.pacNome,
      usu_cpf: objPatient.usuCpf || 'Não passado',
      spa_id: spaId,
      numeroFila: num,
      agoraRoomName: agoraRoomName,
      agoraToken: agoraToken,
      agoraUID: agoraUID
    }
    console.log('PAYLOAD', payload)
    await setDoc(docRef, payload)
    console.log('Novo paciente na fila virtual', docId)
    return {
      status: true
    }
  } catch (e) {
    console.warn('[addPatientVirtualQueue] Error adding document: ', e)
    return {
      status: false
    }
  }
}

const getVirtualQueueByPatientId = async (pacId) => {
  const q = query(collection(instanceFirestore, colecaoFilaVirtual), where('pac_id', '==', pacId))
  const querySnapshot = await getDocs(q)
  return querySnapshot
}

const getLengthFilaVirtual = async () => {
  const q = query(collection(instanceFirestore, colecaoFilaVirtual))
  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.length
}

const removeVirtualQueueByPatientId = async (pacId) => {
  try {
    const myCollection = collection(instanceFirestore, colecaoFilaVirtual)
    const q = query(myCollection, where('pac_id', '==', pacId))
    const querySnapshot = await getDocs(q)
    if (querySnapshot.size > 0) {
      const doc = querySnapshot.docs[0]
      await deleteDoc(doc.ref)
      console.log(`Fila Virtual com pacId ${pacId} removido.`)

      const queueRef = collection(instanceFirestore, colecaoFilaVirtual)
      const queueSnapshot = await getDocs(queueRef)
      queueSnapshot.forEach((doc) => {
        const docRef = doc.ref;
        let newNumber = doc.data().numeroFila - 1
        if (newNumber < 0) {
          newNumber = 1
        }
        updateDoc(docRef, { numeroFila: newNumber });
        console.log(`Fila Virtual ${doc.id} atualizado.`);
      });
    } else {
      console.warn('[removeVirtualQueueByPatientId] Nenhum documento encontrado com o pacId:', pacId)
    }
  } catch (error) {
    console.error('Erro ao remover documento:', error)
  }
}

const onListenerFilaVirtualByPacId = async (pacId, call) => {
  try {
    const q = query(collection(instanceFirestore, colecaoFilaVirtual))
    onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.doc.data().pac_id === pacId) {
          switch (change.type) {
          case 'added':
            call({ type: 'added', data: change.doc.data() })
            break
          case 'modified':
            call({ type: 'modified', data: change.doc.data() })
            break
          case 'removed':
            call({ type: 'removed', data: change.doc.data() })
            break
          default:
            call({ type: 'other', data: change.doc.data() })
          }
        }
      })
    })
    console.warn(`Listener Fila Virtual configurado para documentos com pacId: ${pacId}`)
    // Retornar a função de cancelamento do listener (unsubscribe) se necessário
    // return unsubscribe
  } catch (error) {
    console.error('Erro ao configurar o listener:', error)
  }
}

// *** *** ***
// * PACIENTE
/**
 * Adiciona um paciente ao Firestore ou faz update caso o pacId já exista.
 *
 * @param {Object} obj - Objeto contendo os dados do paciente.
 * @param {string} obj.pacId - ID do paciente.
 * @param {string} obj.pacNome - Nome do paciente.
 * @param {string} [obj.pacGenero] - Gênero do paciente (opcional).
 * @param {number} [obj.pacIdade] - Idade do paciente (opcional).
 * @param {string} [obj.pacImagem] - URL da imagem do paciente (opcional).
 * @param {string} [obj.usuCpf] - CPF do usuário (opcional).
 * @returns {Promise<Object>} Retorna um objeto com `status: true` em caso de sucesso, ou `status: false` em caso de falha.
 */
const addPatient = async (obj) => {
  try {
    if (!obj) {
      console.warn('[addPatient] obj não existente');
      return { status: false };
    }

    if (!obj.pacId) {
      console.warn('[addPatient] obj.pacId não existente');
      return { status: false };
    }

    if (!obj.pacNome) {
      console.warn('[addPatient] obj.pacNome não existente');
      return { status: false };
    }

    const payload = {
      pac_id: obj.pacId,
      pac_nome: obj.pacNome,
      pac_genero: obj.pacGenero,
      pac_idade: obj.pacIdade,
      pac_imagem: obj.pacImagem,
      usu_cpf: obj.usuCpf || 'Não passado',
      status: null,
      spa_id: null,
      agoraUID: null,
      agoraRoomName: null,
      agoraToken: null,
      comando: 'nenhum'
    };

    // Verificar se o paciente já existe no Firestore pelo pacId
    const querySnapshot = await getPacienteByPacId(obj.pacId);
    if (!querySnapshot.empty) {
      const existingDoc = querySnapshot.docs[0]; // Primeiro documento encontrado
      console.warn('[addPatient] Já existe um documento com o mesmo pacId. Fazendo update...');
      const docRef = doc(instanceFirestore, colecaoPacientes, existingDoc.id);
      await updateDoc(docRef, payload);
      console.log('Paciente atualizado com sucesso', existingDoc.id);
      return { status: true, msg: 'Paciente atualizado' };
    }
    // Caso não exista, criar um novo documento
    let docId = await getLengthPacientes();
    docId++;
    docId = `${docId}_${new Date().getTime()}`;
    const docRef = doc(instanceFirestore, colecaoPacientes, docId);
    await setDoc(docRef, payload);
    console.log('Novo paciente adicionado', docId);
    return { status: true, msg: 'Novo paciente adicionado' };
  } catch (e) {
    console.warn('[addPatient] Erro ao adicionar documento: ', e);
    return { status: false, msg: 'Erro ao adicionar paciente' };
  }
};


const getLengthPacientes = async () => {
  const q = query(collection(instanceFirestore, colecaoPacientes))
  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.length
}

const updatePatientSpecificKey = async (pacId, key, value) => {
  try {
    if (!pacId) {
      console.warn('[updatePatientSpecificKey] pacId nao existente')
      return {
        status: false
      }
    }
    const querySnapshot = await getPatientByPacId(pacId)
    if (!querySnapshot.empty) {
      const docRef = doc(collection(instanceFirestore, colecaoPacientes), querySnapshot.docs[0].id)
      await updateDoc(docRef, {
        [key]: value
      })
      console.log('Paciente Atualizado com sucesso! PacId', pacId, key, value)
      return {
        status: true
      }
    } else {
      console.warn('[updatePatientSpecificKey] Nenhum documento correspondente encontrado para a chave pacId.')
      return {
        status: false
      }
    }
  } catch (error) {
    console.warn('[updatePatientSpecificKey] Erro ao atualizar campo:', error)
    return {
      status: false
    }
  }
}

const getPatientByPacId = async (pacId) => {
  const q = query(collection(instanceFirestore, colecaoPacientes), where('pac_id', '==', pacId))
  const querySnapshot = await getDocs(q)
  return querySnapshot
}

const onListenerByPacId = async (pacId, call) => {
  try {
    const q = query(collection(instanceFirestore, colecaoPacientes))
    onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.doc.data().pac_id === pacId) {
          switch (change.type) {
          case 'added':
            call({ type: 'added', data: change.doc.data() })
            break
          case 'modified':
            call({ type: 'modified', data: change.doc.data() })
            break
          case 'removed':
            call({ type: 'removed', data: change.doc.data() })
            break
          default:
            call({ type: 'other', data: change.doc.data() })
          }
        }
      })
    })
    console.warn(`Listener configurado para documentos com pacId: ${pacId}`)
    // Retornar a função de cancelamento do listener (unsubscribe) se necessário
    // return unsubscribe
  } catch (error) {
    console.error('Erro ao configurar o listener:', error)
  }
}

const getPacienteByPacId = async (pacId) => {
  const q = query(collection(instanceFirestore, colecaoPacientes), where('pac_id', '==', pacId))
  const querySnapshot = await getDocs(q)
  return querySnapshot
}

const removePacIdFromDocuments = async (pacId) => {
  try {
    Gear.stopCallingCircle()
    const myCollection = collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL)
    const q = query(myCollection, where('pac_id', '==', pacId))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      const docRef = doc.ref;
      updateDoc(docRef, { status: 'livre',
        pac_id: 0,
        pac_nome: null,
        pac_genero: null,
        pac_idade: null,
        pac_imagem: null,
        usu_cpf: null
      })
      console.log(`Documento ${doc.id} atualizado para status 'livre'.`)
    });
    console.log('Atualização concluída.');
  } catch (error) {
    console.error('Erro ao atualizar documentos:', error);
  }
}

// *** *** ***
// * FUNCIONARIO
const sendCommandProfessionalByFunId = async (funId, command) => {
  console.warn('[sendCommandProfessionalByFunId] START. FunId', funId)
  try {
    if (!funId) {
      console.warn('[sendCommandProfessionalByFunId] funId nao existente')
      return {
        status: false
      }
    }
    const q = query(collection(instanceFirestore, colecaoFuncionarios), where('fun_id', '==', funId))
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      const docRef = doc(collection(instanceFirestore, colecaoFuncionarios), querySnapshot.docs[0].id)
      await updateDoc(docRef, {
        'comando': command
      })
      console.warn('[sendCommandProfessionalByFunId] enviado com sucesso. Comando', command)
      return {
        status: true
      }
    } else {
      console.warn('[sendCommandProfessionalByFunId] Comando nao enviado. Funcionario para o funId nao existe. FunId', funId)
      return {
        status: false
      }
    }
  } catch (error) {
    console.warn('[sendCommandProfessionalByFunId] Erro ao enviar comando:', error)
    return {
      status: false
    }
  }
}

const sendCommandProfessionalByStreamId = async (streamId, command) => {
  try {
    if (!streamId) {
      console.warn('@doble [sendCommandProfessionalByStreamId] streamId nao existente')
      return {
        status: false
      }
    }
    const q = query(collection(FirebaseST, colecaoFuncionarios), where('stream_id', '==', streamId))
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      const docRef = doc(collection(FirebaseST, colecaoFuncionarios), querySnapshot.docs[0].id)
      await updateDoc(docRef, {
        'comando': command
      })
      console.warn('@doble [sendCommandProfessionalByStreamId] enviado com sucesso')
      return {
        status: true
      }
    } else {
      console.warn('@doble [sendCommandProfessionalByStreamId] Comando nao enviado. Funcionario para o streamId nao existe. StreamId', streamId)
      return {
        status: false
      }
    }
  } catch (error) {
    console.warn('@doble [sendCommandProfessionalByStreamId] Erro ao enviar comando:', error)
    return {
      status: false
    }
  }
}

const updateFunIdSpecificKey = async (funId, key, value) => {
  try {
    if (!profesisonalId) {
      console.warn('[updateStatus] profesisonalId nao existente')
      return {
        status: false
      }
    }
    const querySnapshot = await getFuncionarioByFunId(profesisonalId)
    if (!querySnapshot.empty) {
      const docRef = doc(collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL), querySnapshot.docs[0].id)
      await updateDoc(docRef, {
        [key]: value
      })
      console.log('Atualizado com sucesso!', key, value)
      return {
        status: true
      }
    } else {
      console.warn('[updateStatus] Nenhum documento correspondente encontrado para a chave FunId.')
      return {
        status: false
      }
    }
  } catch (error) {
    console.warn('[updateStatus] Erro ao atualizar campo:', error)
    return {
      status: false
    }
  }
}

const updateProfessionalByProfessionalId = async (profesisonalId, status, objPatient, spaId, agoraUID, agoraRoomName, agoraToken) => {
  try {
    if (!profesisonalId) {
      console.warn('[updateStatus] profesisonalId nao existente')
      return {
        status: false
      }
    }
    const querySnapshot = await getFuncionarioByFunId(profesisonalId)
    if (!querySnapshot.empty) {
      const docRef = doc(collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL), querySnapshot.docs[0].id)
      await updateDoc(docRef, {
        pac_id: objPatient.pacId || 0,
        pac_nome: objPatient.pacNome || null,
        pac_genero: objPatient.pacGenero || null,
        pac_idade: objPatient.pacIdade || null,
        pac_imagem: objPatient.pacImagem || null,
        usu_cpf: objPatient.usuCpf || 'Não passado',
        status: status,
        spa_id: spaId || null,
        agoraUID: agoraUID || null,
        agoraRoomName: agoraRoomName || null,
        agoraToken: agoraToken || null
      })
      console.log('Atualizado com sucesso!')
      return {
        status: true
      }
    } else {
      console.warn('[updateStatus] Nenhum documento correspondente encontrado para a chave FunId.')
      return {
        status: false
      }
    }
  } catch (error) {
    console.warn('[updateStatus] Erro ao atualizar campo:', error)
    return {
      status: false
    }
  }
}

const getFuncionarioFree = async (objPatient, spaId, agoraUID, agoraRoomName, agoraToken, functionCallBack) => {
  // * pega todos os Funcionarios livres
  const q = query(collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL),
    where('status', '==', 'livre'),
    where('isTecnico', '==', 1),
    where('isOcupado', '==', 0)
  )
  const querySnapshot = await getDocs(q)
  // * NUMERO RANDOMICO
  if (querySnapshot.empty) {
    functionCallBack({
      status: false,
      isVirtualQueue: true,
      msg: 'Fila Virtual'
    })
  } else {
    const rdmNum = Math.floor(Math.random() * querySnapshot.size);
    const rdmDoc = querySnapshot.docs[rdmNum];
    const dadosRdmDoc = rdmDoc.data();
    const profesisonalId = parseInt(dadosRdmDoc.fun_id)
    console.warn('Func Livre RDM - FunId:' , profesisonalId)
    const status = 'showModalLigacao'
    updateProfessionalByProfessionalId(profesisonalId, status, objPatient, spaId, agoraUID, agoraRoomName, agoraToken)
    functionCallBack({
      status: true,
      profesisonalId
    })
  }
}

const getFuncionarioByFunId = async (profesisonalId) => {
  const q = query(collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL), where('fun_id', '==', profesisonalId))
  const querySnapshot = await getDocs(q)
  return querySnapshot
}

const cleanShowModalByPacId = async (pacId) => {
  try {
    const myCollection = collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL)
    const q = query(myCollection, where('pac_id', '==', pacId), where('status', '!=', 'ligação'))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      const docRef = doc.ref
      updateDoc(docRef, { status: 'livre',
        pac_id: 0,
        pac_nome: null,
        pac_genero: null,
        pac_idade: null,
        pac_imagem: null,
        usu_cpf: null
      })
      console.log(`Documento Id ${doc.id} atualizado para status livre.`)
    })
    console.log('Atualização concluída.')
  } catch (error) {
    console.error('Erro ao atualizar documentos:', error)
  }
}


// ! - talvez nao usando
const getProfessionalMedicFree = async (objPatient, spaId, agoraUID, agoraRoomName, agoraToken, functionCallBack) => {
  // * pega todos os Funcionarios livres
  const q = query(collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL),
    where('status', '==', 'livre'),
    where('isTecnico', '==', 0),
    where('isOcupado', '==', 0)
  )
  const querySnapshot = await getDocs(q)
  let gotId = false
  let countOldDate = 0
  // * varre todos os Funcionarios livres
  querySnapshot.forEach((doc) => {
    const profesisonalId = parseInt(doc.data().fun_id)
    const lastUp = moment(doc.data().ultimaAtualizacao);
    const momNow = moment();
    const diffHours = momNow.diff(lastUp, 'hours');
    // updateFunIdSpecificKey(profesisonalId, 'ultimaAtualizacaoHoras', diffHours)
    if (diffHours > 666) {
      countOldDate += 1
    } else {
      // pega todos os Ids já testados
      const aFunIds = Gear.getFunIdsAlreadyTested()
      // valida se dos livres ja passou pelos testados
      const exist = aFunIds.includes(parseInt(doc.data().fun_id))
      // se nao testou o ID e ainda nao pegou um ID
      if (!exist && !gotId) {
        // VALIDAR SE AINDA ESTA OCUPADO, SE SIM PROXIMO
        console.log('Func Livre - FunId:' , profesisonalId)
        gotId = true
        // coloca o ID na lista de testados
        Gear.pushFunIdsAlreadyTested(profesisonalId)
        // atualiza o Funcionario ID
        const status = 'showModalLigacao'
        updateProfessionalByProfessionalId(profesisonalId, status, objPatient, spaId, agoraUID, agoraRoomName, agoraToken)
        functionCallBack({
          status: true,
          profesisonalId
        })
      }
    }
  })
  if (countOldDate >= querySnapshot.size) {
    // * se todos Funcionarios estao com data antiga
    console.log('Todos profissionais datas antigas')
    functionCallBack({
      status: false,
      msg: 'Todos profisisonais datas antigas'
    })
  }
  else if (querySnapshot.size <= 0) {
    // * se nao tem Funcionario livre
    console.log('Sem prof livres')
    functionCallBack({
      status: false,
      msg: 'Fila Virtual'
    })
  }
  else if (!gotId) {
    // * se nao pegou nenhum ID, pois ja testou todos
    // * reseta array ids testados e chama d novo essa funcao
    console.log('Reseta')
    Gear.resetFunIdsAlreadyTested()
    // getProfessionalMedicFree(objPatient, spaId, agoraUID, agoraRoomName, agoraToken, functionCallBack)
  }
}

const existFuncionarioWithPacId = async (pacId) => {
  const q = query(collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL), where('pac_id', '==', pacId))
  const querySnapshot = await getDocs(q)
  if (querySnapshot.empty) {
    return false
  }
  return true
}

const isPacienteWithStatusNull = async (pacId) => {
  const funDataByPacId = await getProfessionalByPatientId(pacId)
  if (!funDataByPacId) {
    console.log('@f5 [isPacienteWithStatusNull] nao achou o profissional com o pacId', pacId)
    return true
  }

  const q = query(collection(instanceFirestore, colecaoPacientes), where('pac_id', '==', pacId))
  const querySnapshot = await getDocs(q)
  if (querySnapshot.empty) {
    console.log('@f5 [isPacienteWithStatusNull] nao achou o paciente', pacId)
    return false
  }
  const pac = querySnapshot.docs[0].data()
  // console.log('@isPacienteWithStatusNull', pac)
  if (pac.status) {
    if (pac.status === Enum.PACIENTE_STATUS.NULL) {
      console.log('@f5 [isPacienteWithStatusNull] paciente status null', pacId)
      return true
    }
    // validar se o paciente está em fila de pendencia
    // if (pac.status === Enum.PACIENTE_STATUS.LIGACAO) {
    //   const servicoHttp = new ServicoHttp(this)
    //   const sQueue = new ServicoAll(
    //     "/api/pep/fila/get-pac-id",
    //     servicoHttp
    //   )
    //   const retQueue = await sQueue.post({ pacId: pacId })
    //   if (retQueue.data.status === true) {
    //     // remove o status de ligacao
    //     console.log('@f5 paciente fila pendencia', pacId)
    //     await updatePatientSpecificKey(pacId, "status", Enum.PACIENTE_STATUS.NULL);
    //     return true
    //   }
    // }
    console.log('@f5 [isPacienteWithStatusNull] return false', pacId)
    return false
  } else {
    console.log('@f5 [isPacienteWithStatusNull] paciente sem status', pacId)
    return true
  }
}

const hasPassedFiveMinutesSinceProfessionalLastUpdate = async (profesisonalId) => {
  const docFun = await getProfessionalByProfessionalId(profesisonalId)
  const momentFun = moment(docFun.ultimaAtualizacao, 'YYYY-MM-DD HH:mm:ss')
  const momentNow = moment()
  const diffMinutes = momentNow.diff(momentFun, 'minutes')
  const moreThanFiveMinutes = diffMinutes > 5
  return moreThanFiveMinutes
}

const updateProfessionalByStreamId = async (streamId, status, obj) => {
  try {
    if (!streamId) {
      console.warn('[updateProfessionalByStreamId] streamId nao existente')
      return {
        status: false
      }
    }

    const q = query(collection(FirebaseST, colecaoFuncionarios), where('stream_id', '==', streamId))
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      const docRef = doc(collection(FirebaseST, colecaoFuncionarios), querySnapshot.docs[0].id)
      await updateDoc(docRef, {
        pac_id: obj.pacId || 0,
        pac_nome: obj.pacNome || null,
        pac_genero: obj.pacGenero || null,
        pac_idade: obj.pacIdade || null,
        pac_imagem: obj.pacImagem || null,
        usu_cpf: obj.usuCpf || 'Não passado',
        spa_id: obj.spaId || null,
        agoraUID: obj.agoraUID || null,
        agoraRoomName: obj.agoraRoomName || null,
        agoraToken: obj.agoraToken || null,
        status: status,
      })
      console.log('[updateProfessionalByStreamId] Atualizado com sucesso!')
      return {
        status: true
      }
    } else {
      console.warn('[updateProfessionalByStreamId] Nenhum documento correspondente encontrado para a chave StreamId.', streamId)
      return {
        status: false
      }
    }
  } catch (error) {
    console.warn('[updateProfessionalByStreamId] Erro ao atualizar campo:', error)
    return {
      status: false
    }
  }
}

// *** *** ***
// * FUNCIONARIO LISTA ENCADEADA
const updateProfessionalSpecificKey = async (professionalId, key, value) => {
  try {
    if (!professionalId) {
      console.warn('[updateProfessionalSpecificKey] professionalId nao existente')
      return {
        status: false
      }
    }
    const q = query(collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL),
    where('fun_id', '==', professionalId))
    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      const docRef = doc(collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL),
      querySnapshot.docs[0].id)
      await updateDoc(docRef, {
        [key]: value
      })
      return {
        status: true
      }
    } else {
      console.warn('[updatePatientSpecificKey] Nenhum documento correspondente encontrado para a chave pacId.')
      return {
        status: false
      }
    }
  } catch (error) {
    console.warn('[updatePatientSpecificKey] Erro ao atualizar campo:', error)
    return {
      status: false
    }
  }
}

const getProfessionalByProfessionalId = async (professionalId) => {
  console.log('getProfessionalByProfessionalId', professionalId)
  try {
    const myCollection = collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL)
    const q = query(myCollection, where('fun_id', '==', professionalId))
    const querySnapshot = await getDocs(q)
    if (querySnapshot.size > 0) {
      const document = querySnapshot.docs[0]
      return document.data()
    } else {
      console.warn('[getProfessionalByProfessionalId] Nenhum documento encontrado com o professionalId:', professionalId)
      return null
    }
  } catch (error) {
    console.error('Erro ao obter documento:', error)
    return null
  }
}

const getProfessionalByPatientId = async (patientId) => {
  try {
    const myCollection = collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL)
    const q = query(myCollection, where('pac_id', '==', patientId))
    const querySnapshot = await getDocs(q)
    if (querySnapshot.size > 0) {
      const document = querySnapshot.docs[0]
      return document.data()
    } else {
      console.warn('[getProfessionalByPatientId] Nenhum documento encontrado com o patientId:', patientId)
      return null
    }
  } catch (error) {
    console.error('Erro ao obter documento:', error)
    return null
  }
}

const getProfessionalLinkedListFree = async (objPatient, spaId, agoraUID, agoraRoomName, agoraToken, functionCallback) => {
  const professionalByPatientId = await getProfessionalByPatientId(objPatient.pacId)
  if (!professionalByPatientId) {
    console.warn('[LinkedList] chamar setPatientFirstFreeProfessional')
    setPatientFirstFreeProfessional(objPatient, spaId, agoraUID, agoraRoomName, agoraToken, functionCallback)
  } else {
    console.warn('[LinkedList] partiu a lista encadeada')
    runLinkedList(professionalByPatientId.fun_id, professionalByPatientId, objPatient, spaId, agoraUID, agoraRoomName, agoraToken, functionCallback)
  }
}

const runLinkedList = async (startProfessionalId, currentDocument, objPatient, spaId, agoraUID, agoraRoomName, agoraToken, functionCallBack) => {
  // do Nó atual analisar o proximo nó, se tudo estiver OK voltar para o setTimeout
  try {
    const nextDocumentId = currentDocument.proximo
    const nextDocumentReference = doc(instanceFirestore, Enum.COLLECTION.PROFESSIONAL, nextDocumentId)
    const nextDocumentSnapshot = await getDoc(nextDocumentReference)
    if (nextDocumentSnapshot.exists()) {
      const nextDocument = nextDocumentSnapshot.data()
      console.warn('Proximo Profissional', nextDocument.fun_nome)
      // * se for medico (nao tecnico)
      if (nextDocument.isTecnico === 0) {
        // runLinkedList(startProfessionalId, nextDocument, objPatient, spaId, agoraUID, agoraRoomName, agoraToken, functionCallBack)
      }
      // * se for o mesmo de onde começou
      else if (nextDocument.fun_id === startProfessionalId) {
        functionCallBack({
          status: true,
          profesisonalId: nextDocument.fun_id
        })
        updateProfessionalByProfessionalId(startProfessionalId, 'showModalLigacao', objPatient, spaId, agoraUID, agoraRoomName, agoraToken)
      }
      // * se o proximo estiver ocupado ou esteja com paciente
      else if (nextDocument.isOcupado === 1 || nextDocument.pac_id !== 0) {
        runLinkedList(startProfessionalId, nextDocument, objPatient, spaId, agoraUID, agoraRoomName, agoraToken, functionCallBack)
      }
      // * se está livre para a chegada do novo paciente
      else {
        // * se a ultima atualizacao do profissional passou de 5 minutos
        const hasPassed = await hasPassedFiveMinutesSinceProfessionalLastUpdate(nextDocument.fun_id)
        if (hasPassed) {
          await removeFuncionarioLinkedList(nextDocument.fun_id)
          runLinkedList(startProfessionalId, nextDocument, objPatient, spaId, agoraUID, agoraRoomName, agoraToken, functionCallBack)
          return
        }
        // * novo nó fica show
        updateProfessionalByProfessionalId(nextDocument.fun_id, 'showModalLigacao', objPatient, spaId, agoraUID, agoraRoomName, agoraToken)
        // * antigo nó fica livre
        updateProfessionalByProfessionalId(startProfessionalId, 'livre', 0, 0, 0, 0, 0, 0)
        functionCallBack({
          status: true,
          profesisonalId: nextDocument.fun_id
        })
      }
    } else {
      console.warn('Documento não encontrado.')
    }
  } catch (error) {
    console.error('Erro ao obter documento:', error)
    return null
  }
}

const setPatientFirstFreeProfessional = async (objPatient, spaId, agoraUID, agoraRoomName, agoraToken, functionCallBack) => {
  // * validacao se paciente esta na fila virtual
  const virtualQueueSnapshot = await getVirtualQueueByPatientId(objPatient.pacId)
  if (!virtualQueueSnapshot.empty) {
    const dataVirtualQueue = virtualQueueSnapshot.docs[0]
    if (dataVirtualQueue.numeroFila > 1) {
      console.warn('[setPatientFirstFreeProfessional] Paciente continuara na fila', objPatient.pacId)
      functionCallBack({
        status: false,
        profesisonalId: null
      })
      return
    }
  }

  // * pega todos os Funcionarios livres
  const q = query(collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL),
    where('status', '==', 'livre'),
    where('isTecnico', '==', 1),
    where('isOcupado', '==', 0)
  )
  const listProfessionalFree = await getDocs(q)
  if (listProfessionalFree.empty) {
    addPatientVirtualQueue(objPatient, spaId, agoraUID, agoraRoomName, agoraToken)
    console.warn('[setPatientFirstFreeProfessional] Paciente indo para fila virtual')
    functionCallBack({
      status: false,
      isVirtualQueue: true,
      msg: 'Fila Virtual'
    })
  } else {
    removeVirtualQueueByPatientId(objPatient.pacId)
    // * pega o profissional livre e joga para showModalLigacao
    const firstProfessionalFree = listProfessionalFree.docs[0]
    const firstProfessionalData = firstProfessionalFree.data()
    const professionalId = firstProfessionalData.fun_id
    const status = 'showModalLigacao'
    updateProfessionalByProfessionalId(professionalId, status, objPatient, spaId, agoraUID, agoraRoomName, agoraToken)
    functionCallBack({
      status: true,
      professionalId
    })
  }
}

// *** *** ***
// * MEDICO
const setPatientFirstFreeMedico = async (objPatient, spaId, agoraUID, agoraRoomName, agoraToken, functionCallBack) => {
  let queryMed = null
  console.warn('[setPatientFirstFreeMedico] SPECIFIC:', objPatient.specific)
  if (objPatient.specific) {
    console.warn('!!! vai ser para profissional especifico !!!')
    // * pega especifico Funcionario livre
    queryMed = query(collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL),
      where('status', '==', 'livre'),
      where('isTecnico', '==', 0),
      where('isOcupado', '==', 0),
      where('fun_id', '==', objPatient.specific)
    )
  } else {
    // * pega todos os Funcionarios livres
    queryMed = query(collection(instanceFirestore, Enum.COLLECTION.PROFESSIONAL),
      where('status', '==', 'livre'),
      where('isTecnico', '==', 0),
      where('isOcupado', '==', 0)
    )
  }
  const querySnapshot = await getDocs(queryMed)
  if (querySnapshot.empty) {
    console.warn('[setPatientFirstFreeMedico] Paciente indo para fila virtual medico:', objPatient.pacId)
    addPatientFilaVirtualMedico(objPatient, spaId, agoraUID, agoraRoomName, agoraToken)
    console.log('--- --- --- ---')
    functionCallBack({
      status: false,
      isVirtualQueue: true,
      msg: 'Fila Virtual Medico'
    })
  } else {
    const firstDoc = querySnapshot.docs[0]
    const firstData = firstDoc.data()
    const status = 'showModalLigacao'
    const profesisonalId = firstData.fun_id
    console.warn('[setPatientFirstFreeMedico] MODAL PARA FUNID:', profesisonalId)
    updateProfessionalByProfessionalId(profesisonalId, status, objPatient, spaId, agoraUID, agoraRoomName, agoraToken)
    removeFilaVirtualMedicoByPacId(objPatient.pacId)
    // * pega o livre e joga para show
    console.log('--- --- --- ---')
    functionCallBack({
      status: true,
      profesisonalId
    })
  }
}

const addPatientFilaVirtualMedico = async (objPatient, spaId, agoraUID, agoraRoomName, agoraToken) => {
  try {
    if (!objPatient.pacId || !objPatient.pacNome || !agoraRoomName || !agoraToken || !agoraUID) {
      console.warn('[addPatientFilaVirtualMedico] faltando props')
      return {
        status: false
      }
    }
    // validar se já tem profesisonalId no firestore
    const querySnapshot = await getFilaVirtualMedicoByPacId(objPatient.pacId)
    if (!querySnapshot.empty) {
      console.warn('[addPatientVirtualQueue] Já existe um documento com o mesmo pacId.')
      return {
        status: false,
        msg: 'já existe'
      }
    }

    let num = await getLengthFilaVirtualMedico()
    num++
    const docId = `${num}_${new Date().getTime()}`
    const docRef = doc(instanceFirestore, colecaoFilaVirtualMedico, docId)
    const payload = {
      pac_id: objPatient.pacId,
      pac_nome: objPatient.pacNome,
      pac_idade: objPatient.pacIdade,
      pac_genero: objPatient.pacGenero,
      pac_imagem: objPatient.pacImagem,
      usu_cpf: objPatient.usuCpf || 'Não passado',
      spa_id: spaId,
      numeroFila: num,
      agoraRoomName: agoraRoomName,
      agoraToken: agoraToken,
      agoraUID: agoraUI}
    console.log('[addPatientFilaVirtualMedico] PAYLOAD', payload)
    await setDoc(docRef, payload)
    console.log('addPatientFilaVirtualMedico] Novo paciente na fila virtual', docId)
    return {
      status: true
    }
  } catch (e) {
    cle.warn('[addPatientFilaVirtualMedico] Error adding document: ', e)
    return {
      status: false
    }
  }
}

const removeFilaVirtualMedicoByPacId = async (pacId) => {
  try {
    const myCollection = collection(instanceFirestore, colecaoFilaVirtualMedico)
    const q = query(myCollection, where('pac_id', '==', pacId))
    const querySnapshot = await getDocs(q)
    if (querySnapshot.size > 0) {
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref)
        console.log(`Fila Virtual com pacId ${pacId} removido.`)
      })

      const queueRef = collection(instanceFirestore, colecaoFilaVirtualMedico)
      const queueSnapshot = await getDocs(queueRef)
      queueSnapshot.forEach((doc) => {
        const docRef = doc.ref;
        let newNumber = doc.data().numeroFila - 1
        if (newNumber < 0) {
          newNumber = 1
        }
        updateDoc(docRef, { numeroFila: newNumber });
        console.log(`Fila Virtual Medico ${doc.id} atualizado.`);
      });
    } else {
      console.warn('[removeFilaVirtualMedicoByPacId] Nenhum documento encontrado com o pacId:', pacId)
    }
  } catch (error) {
    console.error('[removeFilaVirtualMedicoByPacId] Erro ao remover documento:', error)
  }
}

const getLengthFilaVirtualMedico = async () => {
  const q = query(collection(instanceFirestore, colecaoFilaVirtualMedico))
  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.length
}

const getFilaVirtualMedicoByPacId = async (pacId) => {
  const q = query(collection(instanceFirestore, colecaoFilaVirtualMedico), where('pac_id', '==', pacId))
  const querySnapshot = await getDocs(q)
  return querySnapshot
}

// *** *** ***
// * CHAT
const onListenerChatByPacIdFunId = async (pacId, profesisonalId, call) => {
  try {
    const chatPacRef = doc(instanceFirestore, colecaoChat, `${pacId}_${profesisonalId}`)
    const q = query(collection(chatPacRef, 'mensagens'))
    onSnapshot(q, async (snapshot) => {
      const colFuncs = collection(chatPacRef, 'mensagens')
      const querySnapshot = await getDocs(colFuncs)
      const docs = []
      querySnapshot.docs.forEach((doc) => {
        docs.push({
          id: doc.id,
          ...doc.data()
        })
      })
      call(docs)
    })
  } catch (error) {
    console.error('Erro ao configurar o listener:', error)
  }
}

const senChatMsg = async (pacId, profesisonalId, msg) => {
  try {
    const chatPacRef = doc(instanceFirestore, colecaoChat, `${pacId}_${profesisonalId}`)
    const newId = moment().format('YYYYMMDD_HHmmss_SSS')
    // const msgRef = collection(chatPacRef, 'mensagens')
    const msgRef = doc(collection(chatPacRef, 'mensagens'), newId)
    // const msgRef = instanceFirestore.collection(chatPacRef, 'mensagens').doc(newId)
    await setDoc(msgRef, {
      msg: msg,
      date: moment().format('DD/MM/YYYY'),
      time: moment().format('HH:mm:ss'),
      isPatient: 1
      // Outros campos que você desejar adicionar
    })
    console.log('Mensagem enviada com sucesso!', msg)
  } catch (error) {
    console.error('Erro ao enviar mensagem: ', error)
  }
}

const removeMsgsByPacIdFunId = async (pacId, profesisonalId) => {
  try {
    const chatPacRef = doc(instanceFirestore, colecaoChat, `${pacId}_${profesisonalId}`)
    const msgRef = collection(chatPacRef, 'mensagens')
    const mensagensSnapshot = await getDocs(msgRef)
    mensagensSnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref)
    })
    await deleteField(chatPacRef, 'mensagens')
  } catch (error) {
    console.error('Erro ao remover documento:', error)
  }
}

// *** *** ***
// * API
const removeFuncionarioLinkedList = async (professionalId) => {
  updateProfessionalSpecificKey(professionalId, 'isOcupado', 1)
  const servicoHttp = new ServicoHttpCallingCircle(this)
  const sCallingCircle = new ServicoAll('/lista-encadeada/start', servicoHttp)
  await sCallingCircle.post({
    funId: professionalId,
    isRemove: 1
  })
}

// *** *** ***
// *** *** ***
// *** *** ***
export default {
  // Fila Virtual
  addPatientVirtualQueue,
  removeVirtualQueueByPatientId,
  onListenerFilaVirtualByPacId,
  getLengthFilaVirtual,
  // Paciente
  addPatient,
  updatePatientSpecificKey,
  removePacIdFromDocuments,
  onListenerByPacId,
  isPacienteWithStatusNull,
  // Funcionario
  getFuncionarioFree,
  updateProfessionalByProfessionalId,
  getFuncionarioByFunId,
  getProfessionalByPatientId,
  cleanShowModalByPacId,
  getProfessionalMedicFree,
  existFuncionarioWithPacId,
  hasPassedFiveMinutesSinceProfessionalLastUpdate,
  sendCommandProfessionalByStreamId,
  updateProfessionalByStreamId,
  sendCommandProfessionalByFunId,
  // Funcionario LinkedList
  getProfessionalLinkedListFree,
  // Funcionario Medico
  setPatientFirstFreeMedico,
  removeFilaVirtualMedicoByPacId,
  // CHAT
  onListenerChatByPacIdFunId,
  senChatMsg,
  removeMsgsByPacIdFunId,
  // FIRESTORE
  setFirestoreConfig,
  // GEAR
  ...Gear
}
