export default {
  validateAll(_, form, today) {
    if (!form.name) {
      _.$toast.error("O nome deve ser informado.", { duration: 3000 });
      return false;
    }

    if (form.name.length < 5) {
      _.$toast.error("O nome deve conter pelo menos 5 caracteres.", { duration: 3000 })
      return;
    }

    if (!form.birthDate) {
      _.$toast.error("A data de nascimento deve ser informada.", { duration: 3000 })
      return;
    }


    if (!form.birthDate) {
      _.$toast.error("A data de nascimento deve ser informada.", { duration: 3000 })
      return;
    }

    if (form.birthDate > today) {
      _.$toast.error("A data de nascimento deve ser menor que a data atual.", { duration: 3000 })
      return;
    }

    if (!form.gender) {
      _.$toast.error('O sexo deve ser informado', { duration: 3000 });
      return;
    }
    if (!form.motherName) {
      _.$toast.error('O nome da mãe deve ser informado', { duration: 3000 });
      return;
    }
    const emailPattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    if (!form.email || !emailPattern.test(form.email)) {
      _.$toast.error('O e-mail deve ser informado e estar no formato válido (exemplo@exemplo.com)', { duration: 3000 });
      return;
    }
    if (!form.phone) {
      _.$toast.error('O telefone deve ser informado', { duration: 3000 });
      return;
    }
    if (form.phone.length < 14) {
      _.$toast.error('O telefone inválido', { duration: 3000 });
      return;
    }

    if (!form.zipcode) {
      _.$toast.error('O cep deve ser informado', { duration: 3000 });
      return;
    }

    if (!form.address) {
      _.$toast.error('O logradouro deve ser informado', { duration: 3000 });
      return;
    }

    if (!form.number) {
      _.$toast.error('O número deve ser informado', { duration: 3000 });
      return;
    }

    if (!form.neighborhood) {
      _.$toast.error('O bairro deve ser informado', { duration: 3000 });
      return;
    }

    if (!form.city) {
      _.$toast.error('O municipio deve ser informado', { duration: 3000 });
      return;
    }
    const passwordPattern = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,})/;
    if (!form.newpassword || !passwordPattern.test(form.newpassword)) {
      _.$toast.error('Senha deve ter pelo menos 1 letra maiúscula, 1 número e 1 caracter especial e no mínimo 8 caracteres', { duration: 3000 });
      return;
    }
    if (!form.newpassword) {
      _.$toast.error('Digite sua senha', { duration: 3000 });
      return;
    }

    if (!form.newpasswordconfirmation) {
      _.$toast.error('Confirme sua senha', { duration: 3000 });
      return;
    }
    if (form.newpassword !== form.newpasswordconfirmation) {
      _.$toast.error('As senhas não conferem', { duration: 3000 });
      return;
    }

    return true;
  },
};
