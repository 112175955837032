var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pep-chat__content"},[_c('div',{staticClass:"chat-header"},[_c('div',{staticClass:"ball header"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.appLogoChat}})]),_vm._v(" "+_vm._s(_vm.objFun ? _vm.objFun.fun_nome : 'Não Informado')+" ")]),_c('div',{staticClass:"chat-date"},[_vm._v(" Hoje "+_vm._s(_vm.dateNow)+" ")]),_c('div',{ref:"scrollContainer",staticClass:"msgs"},_vm._l((_vm.listMsgs),function(item,i){return _c('div',{key:`chat-tag-${i}`,staticClass:"tag",class:[
        {'my': item.isPatient === 1},
        {'other': item.isPatient === 0},
      ]},[(item.isPatient === 0)?_c('div',{staticClass:"ball cheader"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.appLogoChat}})]):_vm._e(),_c('div',{staticClass:"tag_msg",class:[
        {'my': item.isPatient === 1},
        {'other': item.isPatient === 0},
      ]},[_c('div',{staticClass:"msg",class:[
          {'my': item.isPatient === 1},
          {'other': item.isPatient === 0},
        ]},[_vm._v(_vm._s(item.msg))]),_c('div',{staticClass:"time",class:[
            {'my': item.isPatient === 1},
            {'other': item.isPatient === 0},
          ]},[_vm._v(" "+_vm._s(item.isPatient === 0 ? "Atendimento • " : 'Você' + " • ")+" "+_vm._s(item.time || 'Não Informado')+" ")])]),(item.isPatient === 1)?_c('div',{staticClass:"ball user"},[_vm._v(_vm._s(_vm.acronymPatient))]):_vm._e()])}),0),_c('div',{staticClass:"actions"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.chatMsg),expression:"chatMsg"}],ref:"input",staticClass:"send-input",attrs:{"placeholder":"Digite sua mensagem aqui..."},domProps:{"value":(_vm.chatMsg)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendMsg.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.chatMsg=$event.target.value}}}),_c('button',{staticClass:"send-btn",on:{"click":_vm.sendMsg}},[_c('i',{staticClass:"fas fa-paper-plane"})])]),_c('div',{staticClass:"show-info-message"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMessage),expression:"showMessage"}]},[_vm._v("Por favor, digite sua mensagem.")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }