<template>
  <div class="combobox">
      <label class="label">{{ label }} <span v-if="isRequired || required" style="color: red">*</span></label>
      <v-select
        v-model="selected"
        :disabled="disabled"
        :searchable="searchable"
        :options="options"
        :placeholder="placeholder"
        :taggable="taggable"
        :create-option="createOption"
        :multiple="multiple"
        :class="inputClassesComputed"
        :clearable="false"
        @search:blur="blur"
        @search:focus="focus"
      >
        <span slot="option" slot-scope="slotProps" :title="slotProps.label">{{slotProps.label}}</span>
        <span slot="selected-option" slot-scope="slotProps" :title="slotProps.label">{{slotProps.label}}</span>
        <span slot="no-options">Nenhum resultado encontrado</span>
      </v-select>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'Combobox',
  components: {
    vSelect
  },
  data: () => {
    return {
      selected: null,
      inputClasses: ['select']
    }
  },
  props: {
    label: {
      type: String,
      default: 'Combo'
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: null,
    disabled: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String
    },
    taggable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    createOption: {
      type: Function,
      default: () => {}
    },
    reduce: {
      type: Function,
      default: () => {}
    },
    required: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    shouldSkipValidation: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputClassesComputed () {
      return this.inputClasses
    },
    isValid () {
      return this.validationMessage === ''
    }
  },
  watch: {
    'selected': function (pData) {
      this.$emit('input', this.selected)
      this.$emit('selected', this.selected)
    },
    'value': function (pData) {
      this.selected = pData
      this.$emit('input', pData)
    }
  },
  methods: {
    blur (e) {
      this.doValidation()
      this.$emit('blur', e)
    },
    focus (e) {
      this.$emit('blur', e)
    },
    reset () {
      this.selected = null
    },
    doValidation () {
      if (this.shouldSkipValidation) {
        return true
      }

      let isUnselected = ((this.selected === null))

      const hasValue = !!this.value

      if (hasValue) {
        isUnselected = (this.value.length === 0)
      }

      const isInvalid = this.required && isUnselected

      if (isInvalid) {
        this.formIsInvalid(`Campo ${this.label} é requerido`)
        return `Campo ${this.label} é requerido`
      }

      if (typeof this.validate === 'function') {
        let valid = this.validate(this.input)
        if (valid !== true) {
          this.formIsInvalid(valid)
          return valid
        }
      }

      this.formIsValid()

      return true
    },
    formIsValid () {
      this.inputClasses = this.inputClasses
        .filter(c => c !== 'is-invalid')
        .concat('is-valid')

      this.validationMessage = ''
    },
    formIsInvalid (pMsg) {
      this.inputClasses = this.inputClasses
        .filter(c => c !== 'is-valid')
        .concat('is-invalid')

      this.validationMessage = pMsg
    },
    resetValidation () {
      this.inputClasses = ['select']
    }
  }
}
</script>
<style lang="scss">

.is-invalid {
  border-color: #eb5757;
}

.is-valid {
  border-color: #27ae60;
}

.combobox {
  display: flex;
  flex-direction: column;
  justify-items: center;

  width: 100%;
  min-width: 160px;

  > .label {
    z-index: 1;
    background-color: #fff;
    margin-bottom: 5px;
    margin-left: 2px;
    color: $label;
  }

  >.select {
    width:100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .v-select .vs__dropdown-toggle {
    height: 37.5px;
    margin: 0;
    border: 1px solid #CBCBCB;
  }

  & ::v-deep .v-select .vs__dropdown-menu {
    overflow-x: hidden;
    max-height: 15rem !important;
  }

  & ::v-deep .v-select input, .v-select .vs__selected {
    min-height: 2.3rem;
  }
  & ::v-deep .v-select input{
    padding: 12px 8px 8px 12px;
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    color: #212121;
    width: 100%;
  }

  .vs__selected {
    padding: 0 !important;
    padding-left: rem(8px) !important;
    color: #495057;
  }

  .v-select .vs__open-indicator:before {
    border-color: #CBCBCB;
  }

  .v-select.single .vs__selected {
    color: #A1A0A0;
  }

  .v-select .vs__dropdown-menu {
    padding: 0;
    border: 2px solid var(--mainColor);;
  }

  .v-select li {
    color: var(--mainColor);;
  }

  .v-select .vs__dropdown-menu > .vs__dropdown-option--highlight {
    color: #FFF;
    background-color: var(--mainColor);;
    width: auto;
  }

  .vs__selected-options {
    width: 75%;
  }

  .vs__selected {
    padding-top: 0.5em;
    width: 80%;

    span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 12px;
    }
  }

  ul {
    text-overflow: ellipsis;
    width: 100%;

    li {
      span {
        white-space: wrap;
      }
    }
  }

  &.is-primary {
    .v-select .vs__dropdown-toggle {
      padding: 0 0 5px;
      border: 2px solid var(--mainColor);;
    }

    .v-select .vs__open-indicator:before {
      border-color: var(--mainColor);;
    }
  }
}

.v-select.is-valid .vs__dropdown-toggle {
  border-color: #27AE60;
}

.v-select.is-invalid .vs__dropdown-toggle {
  border-color: #EB5757;
}

.v-select.disabled .vs__dropdown-toggle,
.v-select.disabled .vs__dropdown-toggle .vs__clear,
.v-select.disabled .vs__dropdown-toggle input,
.v-select.disabled .vs__open-indicator,
.v-select.disabled .vs__selected  {
  background-color: #C3C3C3;
}

</style>
