<style src="./PatientServiceRequest.scss" lang="scss" scoped></style>
<template>
  <section class="patient-schedule">
    <Loading v-if="isLoading"/>
    <!-- HEADER -->
    <PatientHeader />
    <!-- TITLE -->
    <section class="title">
      <span
        :style="{ 'text-decoration': 'underline', cursor: 'pointer' }"
        @click="onClickBack"
      >
        Início
      </span>
      <IconArrow />
      <span>Solicitar Pronto Atendimento</span>
    </section>
    <!-- CONTENT -->
    <section class="content">
      <div class="recommendations__section">
        <p>Para uma melhor experiência de consulta, recomendamos que você utilize fones de ouvido e escolha um ambiente tranquilo e reservado. Isso garantirá mais privacidade e eficácia na comunicação com o médico. Obrigado!</p>
      </div>

      <section class="form">
        <div class="input">
          <Textarea label="Motivo do contato" v-model="form.motivo" />
        </div>

        <div class="input">
          <span class="subtitle">O que você está sentindo?</span>
          <div class="checks">
            <Checkbox
              class="check"
              :true-value="1"
              :false-value="0"
              v-model="form.dorCorpo"
            >
              Dor no corpo
            </Checkbox>
            <Checkbox
              class="check"
              :true-value="1"
              :false-value="0"
              v-model="form.doresArticulares"
            >
              Dores articulares
            </Checkbox>
            <Checkbox
              class="check"
              :true-value="1"
              :false-value="0"
              v-model="form.dorLombar"
            >
              Dor lombar
            </Checkbox>
            <Checkbox
              class="check"
              :true-value="1"
              :false-value="0"
              v-model="form.tontura"
            >
              Tontura
            </Checkbox>
            <Checkbox
              class="check"
              :true-value="1"
              :false-value="0"
              v-model="form.febre"
            >
              Febre
            </Checkbox>
          </div>
        </div>

        <div class="input">
          <Textarea
            label="Toma algum medicamento?"
            v-model="form.medicamento"
          />
        </div>

        <div class="input">
          <span class="subtitle">Informações</span>
          <div class="checks">
            <Checkbox
              v-if="patient.usu_genero === 'f'"
              class="check"
              :true-value="1"
              :false-value="0"
              v-model="form.gestante"
            >
              Está gravida?
            </Checkbox>
            <Checkbox
              class="check"
              :true-value="1"
              :false-value="0"
              v-model="form.deficiencia"
            >
              Alguma deficiência?
            </Checkbox>
          </div>
        </div>

        <div class="input">
          <span class="subtitle">Anexos</span>
          <InputFiles
            @change="onChangeBase64"
            @remove="onRemoveImg"
            :multipleMax="3"
            :filesBase64="form.files"
          />
        </div>
      </section>
    </section>
    <!-- FOOTER -->
    <section class="footer">
      <div>
        <PlButton label="" primary arrowLeft @click="onClickBack" />
      </div>

      <div class="footer-flex">
        <PlButton label="Cancelar" gray cross @click="onClickBack" />
        <PlButton
          label="Solicitar Atendimento"
          primary
          check
          @click="onClickServiceRequest"
        />
      </div>
    </section>
    <!-- MODAL AVISO -->
    <section class="modal-alert" v-if="showModalAlert">
      <section class="modal-alert__container">
        <div class="msg">{{ msgModalAlert }}</div>
        <div class="msg">Deseja continuar?</div>
        <div class="acts">
          <div class="btn red" @click="onClickCancelModalAlert">Cancelar</div>
          <div class="btn blue" @click="onClickContinueModalAlert">Continuar</div>
        </div>
      </section>
    </section>
    <!--  -->
  </section>
</template>

<script>
import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";
import { ServicoHttpCallingCircle } from "@/axios/servico-http-calling-circle";
import moment from 'moment'
import axios from 'axios'
import Firebase from "@/helpers/firebase/index";
import MovementLogs from "@/helpers/movement_logs";

import PatientHeader from "../components/header/PatientHeader";
import Loading from "../components/loading/Loading";
import IconArrow from "@/assets/icons/IconArrow";

import PlButton from "@/components/button/Button";
import InputFiles from "@/components/input/files/InputFile";
import SelectInput from "@/components/select-input/SelectInput.vue";
import Textarea from "@/components/inputs/textarea/Textarea.vue";
import Checkbox from "@/components/checkbox/Checkbox.vue";

export default {
  name: "PatientFiles",
  components: {
    InputFiles,
    SelectInput,
    PlButton,
    Textarea,
    Checkbox,
    PatientHeader,
    IconArrow,
    Loading
  },

  watch: {},

  data() {
    return {
      qsSystemFrom: null,
      patient: {
        usu_genero: null,
      },
      form: {
        motivo: null,
        medicamento: null,
        files: [],
        filesFormData: [],
        dorCorpo: 0,
        doresArticulares: 0,
        dorLombar: 0,
        tontura: 0,
        febre: 0,
        gestante: 0,
        deficiencia: 0,
      },
      executingServiceRequest: false,
      isLoading: true,

      hasToken: null,
      hasTokenFirestoreId: null,
      hasQueuePosition: null,
      hasCallingCircleList: null,
      hasDoctorQueue: null,

      showModalAlert: false,
      msgModalAlert: null
    };
  },

  computed: {
    isDifferentSystemaPA () {
      const from = process.env.VUE_APP_SYSTEM_FROM_PA
      const to = process.env.VUE_APP_SYSTEM_TO_PA
      return from.toUpperCase() !== to.toUpperCase()
    },
    objSystemFromTo () {
      const from = process.env.VUE_APP_SYSTEM_FROM_PA
      const to = process.env.VUE_APP_SYSTEM_TO_PA
      return {
        systemFrom: from.toUpperCase(),
        systemTo: to.toUpperCase()
      }
    }
  },

  async mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.servicoHttpCallingCircle = new ServicoHttpCallingCircle(this);

    this.sPepServReqHandle = new ServicoAll(
      "/api/pep/circle/service-request-handle",
      this.servicoHttp
    );
    this.sPepFileHandle = new ServicoAll(
      "/api/pep/file/upload",
      this.servicoHttp
    );
    this.sPepGetPatientByCpf = new ServicoAll(
      "/api/pep/paciente/get-by-cpf",
      this.servicoHttp
    );

    this.apiSetLinkAgoraToken = new ServicoAll(
      "/solicitacao-atendimento/set-token",
      this.servicoHttpCallingCircle
    );
    this.apiGetLinkAgoraTokenInformations = new ServicoAll(
      "/solicitacao-atendimento/get-token-patient",
      this.servicoHttpCallingCircle
    );
    this.apiRemoveAgoraToken = new ServicoAll(
      "/solicitacao-atendimento/remove-token",
      this.servicoHttpCallingCircle
    );
    this.apiCleanPatient = new ServicoAll(
      "/solicitacao-atendimento/limpar-paciente",
      this.servicoHttpCallingCircle
    );
    this.apiGetObjTokenByPatientId = new ServicoAll(
      "/solicitacao-atendimento/get-token-pacid",
      this.servicoHttpCallingCircle
    );

    this.patient = await this.$store.dispatch("getLocalStoragePatient");
    this.qsSystemFrom = this.$route.query.from || '';
    console.log('Paciente abrindo a tela', this.patient)
    await Firebase.updatePatientSpecificKey(this.patient.pac_id, "comando", null);

    this.getPermissions();

    if (this.isDifferentSystemaPA) {
      if (this.objSystemFromTo.systemTo.toUpperCase() === 'MEDICAR') {
          console.log('Sistema diferente', this.objSystemFromTo)
          const qsCpf = this.$route.query.cpf || null;
          if (!qsCpf) {
            console.error('[PatientServiceRequest]', 'QueryString CPF nao informado')
            return
          }
          console.log('Pegar paciente via CPF', qsCpf)
          const objPatientByCpf = await this.sPepGetPatientByCpf.get({ cpf: qsCpf })
          console.log('Pegou o paciente via CPF', objPatientByCpf)
          // console.log(objPatientByCpf.data, this.patient)
          const newPatient = {
            ...this.patient,
            ...objPatientByCpf.data
          }
          await this.$store.dispatch("setLocalStoragePatient", newPatient);
          this.patient = await this.$store.dispatch("getLocalStoragePatient");
          console.log('Armazenado o novo paciente', this.patient)
        } else {
          console.error('[PatientServiceRequest]', 'sistemas diferentes, mas nao medicar', this.objSystemFromTo)
          return
        }
    }

    const isNull = await Firebase.isPacienteWithStatusNull(
      this.patient.pac_id
    );
    if (!isNull) {
      // this.showWarn = true;
      // this.$toast.error("O profissional ainda está na chamada. Você será encaminhado em instantes.", {
      //   duration: 3000,
      // });
      // setTimeout(async() => {
      //   const objToken = await this.apiGetObjTokenByPatientId.post({ patientId: this.patient.pac_id })
      //   console.log('objToken', objToken)
      //   const objTokenData = objToken.data.obj
      //   this.$router.push({
      //     name: "pacienteVideo",
      //     query: { video: objTokenData.spaId, refresh: true, token: objTokenData.token, from: this.qsSystemFrom },
      //   });
      //   this.showWarn = false;
      // }, 3.1 * 1000);
    }

    this.getExistingTokenInformationByPatientId(this.patient.pac_id)

    // ! Firebase LOG
    const objLog = { ...this.patient }
    const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
    MovementLogs.saveLog(1200, timeAction, objLog)
  },

  methods: {
    onClickBack() {
      if (this.isDifferentSystemaPA || this.qsSystemFrom.toUpperCase() === 'AXIA') {
        if (this.objSystemFromTo.systemFrom.toUpperCase() === 'AXIA' || this.qsSystemFrom.toUpperCase() === 'AXIA') {
          const url = `${process.env.VUE_APP_AXIA_PACIENTE}/portal-paciente`;
          window.location.href = url;
        } else {
          console.error('[PatientServiceRequest]', 'sistemas diferentes, mas nao medicar - tem q voltar para axia', this.objSystemFromTo)
        }
        return
      }
      // ! Firebase LOG
      const objLog = { ...this.patient, from: this.qsSystemFrom }
      const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
      MovementLogs.saveLog(1202, timeAction, objLog)

      this.$router.push({ name: "pacienteHome" });
    },

    async onClickServiceRequest () {
      if (this.executingServiceRequest) {
        return
      }
      try {
        this.executingServiceRequest = true
        console.log("this.patient.pac_id", this.patient.pac_id);
        const txtSentimento = `${this.form.dorCorpo ? "Dor no corpo, " : ""}${
          this.form.doresArticulares ? "Dores articulares, " : ""
        }${this.form.dorLombar ? "Dor lombar, " : ""}${
          this.form.tontura ? "Tontura, " : ""
        }${this.form.febre ? "Febre" : ""}`;
        const obj = {
          pacId: this.patient.pac_id,
          // files: this.form.files,
          motivo: this.form.motivo,
          medicamento: this.form.medicamento,
          deficiencia: this.form.deficiencia,
          gestante: this.form.gestante,
          sentimento: txtSentimento,
          agoraUID: "não informado",
          agoraRoomName: "não informado",
          agoraToken: "não informado",
        };

        for await (let file of this.form.filesFormData) {
          await this.sPepFileHandle.uploadFile(file);
        }

        const token = await this.getAgoraToken()

        const ret = await this.sPepServReqHandle.post(obj);

        if (!ret.data.status) {
          return this.$toast.error(ret.data.message, {
            duration: 3000,
          });
        }

        if (ret.data.status) {
          // ! Firebase LOG
          const objLog = { ...this.patient, ...ret.data, token: token, from: this.qsSystemFrom }
          const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
          MovementLogs.saveLog(1201, timeAction, objLog)

          this.$router.push({
            name: "pacienteVideo",
            query: { video: ret.data.spaId, token: token, from: this.qsSystemFrom },
          });
        }
        console.log(ret)
      } catch (err) {
        console.error('erro para solicitar atendimento', err)
        this.executingServiceRequest = false
      }
    },
    // *********
    // * ARQUIVOS
    onChangeBase64 (val, file) {
      const files = [];
      let count = 1;
      Object.keys(val).map((i) => {
        const item = val[i];
        files.push({
          type: 4, // documento
          filename: `${count}_${new Date().getTime()}`,
          base64: item,
        });
        count++;
      });
      this.form.files = [...files];

      const type = 4; // documento
      const formData = new FormData();
      formData.append("cpf", this.patient.usu_cpf);
      formData.append("doc_id_documentos_tipos", type);
      formData.append("file", file);
      this.form.filesFormData.push(formData);
    },
    onRemoveImg (objRemove) {
      this.form.files = this.form.files.filter(
        (_, index) => objRemove.idx !== index
      );
    },
    // * PERMISSAO DE VIDEO
    async getPermissions () {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        stream.getTracks().forEach((track) => track.stop());
      } catch (error) {
        console.warn("Erro ao solicitar permissão:", error);
        this.$toast.error("A Webcam/Microfone não estão liberados para o uso", {
          duration: 3000,
        });
      }
    },
    // *** *** ***
    // * TOKEN AGORA
    async getAgoraToken () {
      const cpfTmp = this.patient.usu_cpf || "12345678901";
      const agoraUID = this.cpfClean(cpfTmp);
      const date = Date.now()
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let randomLetters = '';
      for (let i = 0; i < 3; i++) {
        randomLetters += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
      }
      const channelName = `${randomLetters}_${agoraUID}_${date}`;

      // * pegar token
      let obj = {
        canal: channelName,
        uid: agoraUID,
      };
      try {
        const url = `${process.env.VUE_APP_BACKEND_AGORA_ENDPOINT}/telemedicina/gerar-token`
        const tokenClient = process.env.VUE_APP_PEP_CLI_TOKEN
        const tokenUser = process.env.VUE_APP_PEP_USU_TOKEN

        const body = {
          cli_token: tokenClient,
          usu_token: tokenUser,
          ...obj
        }
        // pega o token
        const response = await axios.post(url, body)
        let token = response.data.token

        const objAgora = {
          ...obj,
          token: token,
          pac_id: this.patient.pac_id,
          pac_nome: this.patient.pac_nome,
          usu_cpf: this.patient.usu_cpf
        }
        // cria o link
        await this.apiSetLinkAgoraToken.post(objAgora)
        return token
      } catch (error) {
        console.error('GET TOKEN', error)
        throw new Error('Falha ao obter token de chamada, tente novament mais tarde!')
      }
    },
    cpfClean (cpf)  {
      let cpfTmp = cpf.replace('.', '')
      cpfTmp = cpfTmp.replace('-', '')
      cpfTmp = cpfTmp.replace('.', '')
      cpfTmp = cpfTmp.trim()

      return cpfTmp
    },
    // *** *** ***
    // * ACOES DA MODAL ALERTA
    async onClickCancelModalAlert () {
      this.showModalAlert = false
      this.isLoading = true
      await this.apiRemoveAgoraToken.post({
        firestoreId: this.hasTokenFirestoreId
      })
      await this.apiCleanPatient.post({
        patientId: this.patient.pac_id,
        cleanByPatient: true
      })
      this.isLoading = false
    },
    async onClickContinueModalAlert () {
      this.showModalAlert = false
      this.isLoading = true
      console.log('onClickContinueModalAlert')
      const token = this.hasToken
      const tokenSpaId = this.hasTokenSpaId
      if (this.hasQueuePosition) {
        console.log('Continuar para a fila de requisicao')
      }
      this.$router.push({
        name: "pacienteVideo",
        query: { video: tokenSpaId, token: token, from: this.qsSystemFrom },
      });
      this.isLoading = false
    },
    // * VALIDACAO DE TOKEN
    async getExistingTokenInformationByPatientId (patientId) {
      try {
        this.isLoading = true
        this.showModalAlert = false
        this.msgModalAlert = null
        const respTokenInfo = await this.apiGetLinkAgoraTokenInformations.post({
          patientId: patientId
        })
        if (respTokenInfo.status === 200) {
          const tokenInfo = respTokenInfo.data.obj
          console.log(tokenInfo)
          this.hasTokenFirestoreId = tokenInfo.hasTokenData && tokenInfo.infoTokenData.firestoreId
          this.hasTokenSpaId = tokenInfo.hasTokenData && tokenInfo.infoTokenData.spa_id
          if (tokenInfo.hasEmergencyServiceRequestQueue) {
            // const numServiceRequest = tokenInfo.infoEmergencyServiceRequestQueue.filaRequisicaoProntoAtendimento
            // this.hasQueuePosition = numServiceRequest
            // this.hasToken = tokenInfo.infoTokenData.token
            // this.showModalAlert = true
            // this.msgModalAlert = `Você está na fila, posição ${numServiceRequest}.`
          }
          else if (tokenInfo.hasCallingCircleList) {
            // this.hasCallingCircleList = true
            // this.hasToken = tokenInfo.infoTokenData.token
            // this.showModalAlert = true
            // this.msgModalAlert = 'Você está prestes a ser atendido.'
          }
          else if (tokenInfo.hasDoctorsQueue) {
            // this.hasDoctorQueue = true
            // this.hasToken = tokenInfo.infoTokenData.token
            // this.showModalAlert = true
            // this.msgModalAlert = 'Você está prestes a ser atendido pelo médico.'
          }
          else if (tokenInfo.hasTokenData) {
            // const tokenFirestoreId = tokenInfo.infoTokenData.firestoreId
            // console.warn('Remover token do firestore', tokenFirestoreId)
            // await this.apiRemoveAgoraToken.post({
            //   firestoreId: tokenFirestoreId
            // })
          }
          else {
            console.info('Seguir para uma solicitacao comum')
          }
          console.log('Limpando todas as requisicoes do server')
          await this.apiCleanPatient.post({
            patientId: this.patient.pac_id,
            cleanByPatient: true
          })
        }
        this.isLoading = false
      } catch (err) {
        console.error('[getExistingTokenInformationByPatientId]', 'Erro', err)
      }
    }
  },
};
</script>
