import Vue from "vue";
import VueRouter from "vue-router";

import beforeEach from "./beforeEach";

// import SystemPaciente from '../views/paciente/system-old/PatientVideo.vue'

import PatientTerms from "../views/paciente/terms/PatientTerms.vue";

import PacienteLogin from "../views/paciente/login/PatientLogin.vue";
import PacienteHome from "../views/paciente/home/PatientHome.vue";
import PacienteSchedule from "../views/paciente/schedule/PatientSchedule.vue";
import PacienteFiles from "../views/paciente/files/PatientFiles.vue";
import PatientClinicalDocuments from "../views/paciente/clinical-documents/PatientClinicalDocuments.vue";
import PatientEncounterHistory from "../views/paciente/patient-encounter-history/PatientEncounterHistory";
import PacienteServiceRequest from "../views/paciente/service-request/PatientServiceRequest.vue";
import PacienteVideo from "../views/paciente/video/PatientVideo.vue";
import PacienteLinks from "../views/paciente/links/PatientLinks.vue";
import PacienteUserInfo from "../views/paciente/user-info/PatientUserInfo.vue";
import ForgotPsw from "../views/paciente/forgot-psw/ForgotPsw.vue";

import PacienteNps from "../views/paciente/nps/PatientNps.vue";
import PacienteGiftCard from "../views/paciente/gift-card/PatientGiftCard.vue";

import MobileAttendanceRequest from "../views/paciente/mobile-attendance/service-request/MobileAttendanceRequest.vue";
import MobileVideoCall from "../views/paciente/mobile-attendance/video-call/MobileVideoCall.vue";
import MobileNps from "../views/paciente/mobile-attendance/nps/MobileNps.vue";

import CabineHome from "../views/cabine/home/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "pacienteLogin",
    component: PacienteLogin,
  },
  {
    path: "/termos",
    name: "patient-terms",
    component: PatientTerms,
  },
  {
    path: "/cabine",
    name: "cabineHome",
    component: CabineHome,
  },
  // {
  //   path: '/sistema-paciente',
  //   name: 'pacienteSystem',
  //   component: SystemPaciente
  // },
  {
    path: "/portal-paciente",
    name: "pacienteHome",
    component: PacienteHome,
  },
  {
    path: "/portal-paciente/agendamento",
    name: "pacienteSchedule",
    component: PacienteSchedule,
  },
  {
    path: "/portal-paciente/documentos",
    name: "pacienteFiles",
    component: PacienteFiles,
  },
  {
    path: "/portal-paciente/documentos-clinicos",
    name: "patientClinicalDocuments",
    component: PatientClinicalDocuments,
  },
  {
    path: "/portal-paciente/historico-atendimento",
    name: "patientEncounterHistory",
    component: PatientEncounterHistory,
  },
  {
    path: "/portal-paciente/solicitar-pronto-atendimento",
    name: "pacienteServiceRequest",
    component: PacienteServiceRequest,
  },
  {
    path: "/portal-paciente/video",
    name: "pacienteVideo",
    component: PacienteVideo,
  },
  {
    path: "/portal-paciente/links",
    name: "pacienteLinks",
    component: PacienteLinks,
  },
  {
    path: "/portal-paciente/user-info",
    name: "pacienteUserInfo",
    component: PacienteUserInfo,
  },
  {
    path: "/portal-paciente/nps",
    name: "pacienteNps",
    component: PacienteNps,
  },
  {
    path: "/portal-paciente/gift-card",
    name: "pacienteGiftCard",
    component: PacienteGiftCard,
  },
  {
    path: "/esqueci-senha",
    name: "forgotPsw",
    component: ForgotPsw,
  },
  {
    path: "/atendimento-mobile/solicitar-atendimento",
    name: "mobile-service-request",
    component: MobileAttendanceRequest,
  },
  {
    path: "/atendimento-mobile/video-chamada",
    name: "mobile-video-call",
    component: MobileVideoCall,
  },
  {
    path: "/atendimento-mobile/nps",
    name: "mobile-nps",
    component: MobileNps,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
