<template>
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.2124 6.80566L0.212402 12.8678L0.212403 0.743486L6.2124 6.80566Z"
      fill="#36474F"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArrow",
  components: {},

  data: () => {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
