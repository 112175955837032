<style src="./PatientFiles.scss" lang="scss" scoped></style>
<template>
  <section class="patient-schedule">
    <!-- HEADER -->
    <PatientHeader />
    <!-- CONTENT -->

    <section class="title">
      <span
        :style="{ 'text-decoration': 'underline', cursor: 'pointer' }"
        @click="onClickBack"
      >
        Início
      </span>
      <IconArrow />
      <span>Adicionar Documentos</span>
    </section>

    <section class="content">
      <section class="warning">
        <IconAlert class="icon" />
        <div class="txt">
          <div class="txt_title">ATENÇÃO!</div>
          <div class="txt_text">
            Todos os arquivos guardados aqui <b>podem ser visualizados</b> pelo
            médico ou atendente. Mantenha salvo apenas os documentos de saúde
            que são importantes para avaliação da equipe médica.
          </div>
        </div>
      </section>

      <section class="inputs-file">
        <SelectInput
          class="sel"
          required
          label="Tipo de documento"
          :options="optType"
          :preselect-first="false"
          v-model="form.type"
        />
        <InputFiles
          ref="InputFiles"
          class="ifile"
          @change="onChangeBase64"
          @remove="onRemoveImg"
          :fileBase64="form.file"
        />
        <PlButton
          label="Salvar"
          class="btn"
          check
          primary
          :disabled="disableButton"
          @click="onClickSendFile"
        />
      </section>

      <div class="subtitle">Meus Documentos</div>

      <section class="files">
        <div
          class="type"
          v-if="listExaminationResult && listExaminationResult.length > 0"
        >
          Resultado de Exame
        </div>
        <section class="flex">
          <div
            class="file"
            :title="item.doc_nome"
            v-for="(item, idx) of listExaminationResult"
            :key="`file-${idx}`"
          >
            <div class="acts">
              <i
                class="fas fa-trash"
                title="Remover arquivo"
                @click="onClickDeleteFile(item)"
              />
            </div>

            <div
              class="fimg"
              @click="onClickOpenFile(item)"
              v-if="
                item.doc_extensao === 'jpeg' ||
                item.doc_extensao === 'jpg' ||
                item.doc_extensao === 'png'
              "
            >
              <img :src="item.doc_url" />
            </div>

            <div
              class="pdf"
              v-else-if="item.doc_extensao === 'pdf'"
              @click="onClickOpenFile(item)"
            >
              <i class="far fa-file-pdf"></i>
            </div>

            <div class="other" v-else @click="onClickOpenFile(item)">
              <i class="far fa-file"></i>
            </div>

            <div class="name">{{ item.doc_nome }}</div>
          </div>
        </section>

        <div
          class="type"
          v-if="listExaminationRequest && listExaminationRequest.length > 0"
        >
          Solicitação de Exame
        </div>
        <section class="flex">
          <div
            class="file"
            :title="item.doc_nome"
            v-for="(item, idx) of listExaminationRequest"
            :key="`file-${idx}`"
          >
            <div class="acts">
              <i
                class="fas fa-trash"
                title="Remover arquivo"
                @click="onClickDeleteFile(item)"
              />
            </div>

            <div
              class="fimg"
              @click="onClickOpenFile(item)"
              v-if="
                item.doc_extensao === 'jpeg' ||
                item.doc_extensao === 'jpg' ||
                item.doc_extensao === 'png'
              "
            >
              <img :src="item.doc_url" />
            </div>

            <div
              class="pdf"
              v-else-if="item.doc_extensao === 'pdf'"
              @click="onClickOpenFile(item)"
            >
              <i class="far fa-file-pdf"></i>
            </div>

            <div class="other" v-else @click="onClickOpenFile(item)">
              <i class="far fa-file"></i>
            </div>

            <div class="name">{{ item.doc_nome }}</div>
          </div>
        </section>

        <div
          class="type"
          v-if="listPrescription && listPrescription.length > 0"
        >
          Receita
        </div>
        <section class="flex">
          <div
            class="file"
            :title="item.doc_nome"
            v-for="(item, idx) of listPrescription"
            :key="`file-${idx}`"
          >
            <div class="acts">
              <i
                class="fas fa-trash"
                title="Remover arquivo"
                @click="onClickDeleteFile(item)"
              />
            </div>

            <div
              class="fimg"
              @click="onClickOpenFile(item)"
              v-if="
                item.doc_extensao === 'jpeg' ||
                item.doc_extensao === 'jpg' ||
                item.doc_extensao === 'png'
              "
            >
              <img :src="item.doc_url" />
            </div>

            <div
              class="pdf"
              v-else-if="item.doc_extensao === 'pdf'"
              @click="onClickOpenFile(item)"
            >
              <i class="far fa-file-pdf"></i>
            </div>

            <div class="other" v-else @click="onClickOpenFile(item)">
              <i class="far fa-file"></i>
            </div>

            <div class="name">{{ item.doc_nome }}</div>
          </div>
        </section>

        <div class="type" v-if="listDocument && listDocument.length > 0">
          Documento
        </div>
        <section class="flex">
          <div
            class="file"
            :title="item.doc_nome"
            v-for="(item, idx) of listDocument"
            :key="`file-${idx}`"
          >
            <div class="acts">
              <i
                class="fas fa-trash"
                title="Remover arquivo"
                @click="onClickDeleteFile(item)"
              />
            </div>

            <div
              class="fimg"
              @click="onClickOpenFile(item)"
              v-if="
                item.doc_extensao === 'jpeg' ||
                item.doc_extensao === 'jpg' ||
                item.doc_extensao === 'png'
              "
            >
              <img :src="item.doc_url" />
            </div>

            <div
              class="pdf"
              v-else-if="item.doc_extensao === 'pdf'"
              @click="onClickOpenFile(item)"
            >
              <i class="far fa-file-pdf"></i>
            </div>

            <div class="other" v-else @click="onClickOpenFile(item)">
              <i class="far fa-file"></i>
            </div>

            <div class="name">{{ item.doc_nome }}</div>
          </div>
        </section>
      </section>
    </section>
    <!--  -->
    <section class="footer">
      <PlButton label="" primary arrowLeft @click="onClickBack" />
    </section>
    <!--  -->
  </section>
</template>

<script>
import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";

import PatientHeader from "../components/header/PatientHeader";

import IconArrow from "@/assets/icons/IconArrow";
import IconAlert from "@/assets/icons/IconAlert";
import PlButton from "@/components/button/Button";
import InputFiles from "@/components/input/files/InputFile";
import SelectInput from "@/components/select-input/SelectInput.vue";

export default {
  name: "PatientFiles",
  components: {
    InputFiles,
    SelectInput,
    PlButton,
    PatientHeader,
    IconArrow,
    IconAlert,
  },
  watch: {},
  data() {
    return {
      patient: null,
      pacFilesOri: [],
      pacFiles: [],
      optType: [
        { name: "Resultado de Exame", value: 1 },
        { name: "Solicitação de Exame", value: 2 },
        { name: "Receita", value: 3 },
        { name: "Documento", value: 4 },
      ],
      sendFile: false,
      form: {
        file: null,
        fileComplete: null,
        type: null,
        name: null,
      },
      listExaminationResult: [],
      listExaminationRequest: [],
      listPrescription: [],
      listDocument: [],
      disableButton: false,
    };
  },
  async mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.sPepPacFiles = new ServicoAll(
      "/api/pep/file/get-by-pac",
      this.servicoHttp
    );
    this.sPepFileHandle = new ServicoAll(
      "/api/pep/file/upload",
      this.servicoHttp
    );
    // this.sPepFileHandle = new ServicoAll(
    //   "/api/pep/file/handle",
    //   this.servicoHttp
    // );
    this.sPepFileDelete = new ServicoAll(
      "/api/pep/file/delete",
      this.servicoHttp
    );

    this.patient = await this.$store.dispatch("getLocalStoragePatient");
    await this.getFiles();
  },
  methods: {
    onClickOpenFile(pFile) {
      window.open(pFile.doc_url, "_blank");
    },
    onClickBack() {
      this.$router.push({ name: "pacienteHome" });
    },
    async onClickDeleteFile(item) {
      try {
        await this.sPepFileDelete.post({
          docId: item.doc_id,
          docNome: item.doc_nome,
        });
        this.$toast.success("Documento removido com sucesso!");
      } catch (error) {
        console.error(error);
        this.$toast.error("Não foi possível excluir o documento");
      } finally {
        await this.getFiles();
      }
    },
    async getFiles() {
      try {
        const ret = await this.sPepPacFiles.post({
          pacId: this.patient.pac_id,
        });
        const list = ret.data.files;

        const EXAMINATION_RESULT = 1;
        const EXAMINATION_REQUEST = 2;
        const PRESCRIPTION = 3;
        const DOCUMENT = 4;

        this.pacFilesOri = ret.data.status ? ret.data.files : [];
        this.pacFiles = Object.assign({}, this.pacFilesOri);

        this.listExaminationResult = list.filter((item) => {
          return item.dot_id === EXAMINATION_RESULT;
        });
        this.listExaminationRequest = list.filter((item) => {
          return item.dot_id === EXAMINATION_REQUEST;
        });
        this.listPrescription = list.filter((item) => {
          return item.dot_id === PRESCRIPTION;
        });
        this.listDocument = list.filter((item) => {
          return item.dot_id === DOCUMENT;
        });
      } catch (error) {
        console.error(error);
        this.$toast.error("Não foi possível carregar os documentos");
      }
    },
    async onClickSendFile() {
      try {
        this.disableButton = true;
        if (this.sendFile) {
          return;
        }
        this.sendFile = true;

        if (!this.form.type) {
          this.sendFile = false;
          this.$toast.warning("Selecione o tipo de documento desejado");
          return false;
        }

        if (!this.form.file || this.form.file.length <= 0) {
          this.$toast.warning("Selecione o documento desejado");
          this.sendFile = false;
          return false;
        }

        // const obj = {
        //   file: this.form.file,
        //   type: this.form.type ? this.form.type.value : 4,
        //   pacId: this.patient.pac_id,
        //   filename: this.form.name,
        // };
        // console.log(this.patient)
        const formData = new FormData();
        formData.append('cpf', this.patient.usu_cpf);
        formData.append('doc_id_documentos_tipos', this.form.type ? this.form.type.value : 4);
        formData.append('file', this.form.fileComplete);
        await this.sPepFileHandle.uploadFile(formData);

        this.$toast.success("Documento salvo com sucesso!");
        this.sendFile = false;
        await this.getFiles();
        this.clearForm();
      } catch (error) {
        console.error(error);
        this.$toast.error("Erro ao salvar documento");
      } finally {
        setTimeout(()=> {
          this.disableButton = false;
        }, 1600)
      }
    },
    clearForm() {
      this.form = {
        file: null,
        fileComplete: null,
        type: null,
        name: null,
      };
      this.$refs.InputFiles.clear()
      window.location.reload()
    },
    onChangeBase64(val, file) {
      this.form.file = val;
      this.form.fileComplete = file;
      this.form.name = file.name;
      // console.log(file)
    },
    onRemoveImg(objRemove) {
      // this.form.file = this.form.file.filter((_, index) => objRemove.idx !== index)
    },
  },
};
</script>
