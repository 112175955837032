<template>

<svg width="145" height="129" viewBox="0 0 145 129" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M130.396 9.5882L106.661 33.3456L35.0112 104.971L13.3688 126.615C11.9493 128.033 9.88173 128.587 7.94405 128.068C6.00534 127.549 4.49167 126.035 3.97226 124.097C3.45297 122.159 4.00689 120.091 5.42629 118.671L122.453 1.64495C123.506 0.591672 124.934 0 126.424 0C127.913 0 129.342 0.591672 130.395 1.64495C131.449 2.69823 132.04 4.12707 132.04 5.61673C132.04 7.1064 131.449 8.53521 130.395 9.58852L130.396 9.5882Z" fill="#6C6C6C"/>
<path d="M92.5632 25.1905L12.7838 105.088H12.4785C9.16875 105.088 5.99514 103.774 3.65488 101.433C1.3144 99.0938 0 95.9193 0 92.6104V35.7662C0 32.4575 1.31448 29.2828 3.65488 26.9434C5.99536 24.6029 9.16875 23.2885 12.4785 23.2885H85.936C88.2796 23.2917 90.5749 23.9505 92.563 25.1915L92.5632 25.1905Z" fill="#6C6C6C"/>
<path d="M144.849 25.2387V103.02C144.87 104.447 144.508 105.854 143.801 107.093C143.095 108.333 142.069 109.361 140.83 110.07C139.602 110.779 138.208 111.151 136.789 111.15C135.377 111.151 133.991 110.779 132.77 110.07L98.4129 90.1658V92.5157C98.4129 95.8255 97.0984 98.9991 94.7591 101.339C92.4186 103.679 89.2452 104.994 85.9355 104.994H41.2868L121.773 24.6508L132.864 18.2587C134.512 17.3114 136.442 16.9768 138.312 17.3135C140.183 17.6503 141.874 18.6375 143.087 20.101C144.3 21.5634 144.958 23.4086 144.942 25.3087L144.849 25.2387Z" fill="#6C6C6C"/>
</svg>

</template>

<script>
export default {
  name: 'IconCamOff',
  components: {
  },

  data: () => {
    return {
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>
