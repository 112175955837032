var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-input-date input--wrapper"},[(_vm.label)?_c('label',[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"is--required"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"input--content"},[(_vm.hasPrependSlot)?_c('div',{staticClass:"input--slot is--prepend"},[_vm._t("prepend")],2):_vm._e(),_c('div',{staticClass:"picker--content"},[_c('DatePicker',{attrs:{"type":"date","lang":_vm.lang,"format":_vm.format,"formatter":_vm.momentFormat,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"inline":_vm.inline,"value-type":"format","disabled-date":_vm.disabledDate,"input-class":[
          'picker-input',
          {
            'is--append': _vm.hasAppendSlot,
            'is--prepend': _vm.hasPrependSlot
          }
        ]},on:{"focus":_vm.focus},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}}),(_vm.today && !_vm.inline)?_c('span',{class:[
          'shortkey-tdy',
          {
            'is--disabled': _vm.disabled || _vm.isToday
          }
        ],on:{"click":_vm.setToday}},[_vm._v(" Hoje ")]):_vm._e()],1),(_vm.isLabelEmpty && _vm.required && !_vm.disabled)?_c('span',{staticClass:"is--required no-label"},[_vm._v("*")]):_vm._e(),(_vm.hasAppendSlot)?_c('div',{staticClass:"input--slot is--append"},[_vm._t("append")],2):_vm._e(),(_vm.errorMsg)?_c('i',{staticClass:"input--warning fas fa-exclamation-circle",attrs:{"title":_vm.errorMsg}}):_vm._e()]),(_vm.helptext)?_c('span',{staticClass:"helptext"},[_vm._v(_vm._s(_vm.helptext))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }