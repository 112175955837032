import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    patient: null,
    patientFromHash: null
  },
  getters: {
  },
  mutations: {
    setPatient(state, obj) {
      state.patient = obj
    },
    setPatientFromHash(state, payload) {
      state.patientFromHash = { ...payload }
    },
    resetPatientFromHash(state) {
      state.patientFromHash = null
    }
  },
  actions: {
    updatePatient({ commit }, payload) {
      commit('setPatient', payload)
    },
    async setLocalStoragePatient({}, payload) {
      localStorage.setItem('patient', JSON.stringify(Object.assign(payload, { usu_imagem: '' })))
    },
    getLocalStoragePatient() {
      const storagePatient = localStorage.getItem('patient')
      if (!storagePatient) {
        return null
      }
      return JSON.parse(storagePatient)
    },
    removeLocalStoragePatient() {
      localStorage.removeItem('patient')
    },
    setPatientToVideoUrl({commit}, payload) {
      commit('setPatientFromHash', payload)
    },
    getPatientToVideoUrl({state}) {
      return state.patientFromHash
    },
    resetPatientToVideoUrl({commit}) {
      commit('resetPatientFromHash')
    }
  }
})
