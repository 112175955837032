<template>

<svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.75 3.99994V13.9999C12.75 15.429 11.9398 16.7495 10.625 17.464C9.31017 18.1785 7.68983 18.1785 6.375 17.464C5.06017 16.7495 4.25 15.429 4.25 13.9999V3.99994C4.25 2.57091 5.06017 1.2504 6.375 0.535886C7.68983 -0.178629 9.31017 -0.178629 10.625 0.535886C11.9398 1.2504 12.75 2.57091 12.75 3.99994ZM17 14.9999V11.9999C17 11.6426 16.7975 11.3125 16.4688 11.1339C16.14 10.9553 15.735 10.9553 15.4062 11.1339C15.0775 11.3125 14.875 11.6426 14.875 11.9999V14.9999C14.875 17.76 12.0169 19.9999 8.5 19.9999C4.98306 19.9999 2.125 17.76 2.125 14.9999V11.9999C2.125 11.6426 1.92246 11.3125 1.59375 11.1339C1.26504 10.9553 0.859957 10.9553 0.53125 11.1339C0.202543 11.3125 0 11.6426 0 11.9999V14.9999C0 18.56 3.25131 21.5102 7.4375 21.9399V26H3.1875C2.8078 26 2.45705 26.1906 2.26731 26.5C2.07758 26.8094 2.07758 27.1906 2.26731 27.5C2.45705 27.8094 2.80781 28 3.1875 28H13.8125C14.1922 28 14.543 27.8094 14.7327 27.5C14.9224 27.1906 14.9224 26.8094 14.7327 26.5C14.543 26.1906 14.1922 26 13.8125 26H9.5625V21.9399C13.7487 21.51 17 18.5601 17 14.9999Z" fill="#F5F5F5"/>
</svg>

</template>

<script>
export default {
  name: 'IconBtnMicroOn',
  components: {
  },

  data: () => {
    return {
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>
