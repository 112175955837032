<template>
  <svg
    width="61"
    height="61"
    viewBox="0 0 61 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1032_3010)">
      <path
        d="M28.5522 5.15897L1.20898 52.5187C0.187918 54.2873 1.46421 56.4981 3.50644 56.4981H58.1928C60.235 56.4981 61.5113 54.2873 60.4903 52.5187L33.1471 5.15897C32.126 3.39037 29.5732 3.39037 28.5522 5.15897Z"
        fill="#5D5360"
      />
      <path
        d="M30.0116 12.3087L8.13063 50.2079C7.75809 50.853 8.22379 51.6595 8.96875 51.6595H52.7308C53.4757 51.6595 53.9413 50.853 53.5689 50.2079L31.6878 12.3087C31.3153 11.6635 30.3841 11.6635 30.0116 12.3087Z"
        fill="#FFE17D"
      />
      <path
        d="M30.85 45.4457C31.919 45.4457 32.7855 44.5791 32.7855 43.5102C32.7855 42.4412 31.919 41.5747 30.85 41.5747C29.7811 41.5747 28.9146 42.4412 28.9146 43.5102C28.9146 44.5791 29.7811 45.4457 30.85 45.4457Z"
        fill="#5D5360"
      />
      <path
        d="M31.9526 36.8957L33.5655 27.2182C33.6638 26.6283 33.2089 26.0913 32.6109 26.0913H29.0891C28.4911 26.0913 28.0362 26.6283 28.1345 27.2182L29.7474 36.8957C29.8252 37.3623 30.2289 37.7043 30.702 37.7043H30.9979C31.4711 37.7043 31.8749 37.3622 31.9526 36.8957Z"
        fill="#5D5360"
      />
      <path
        d="M5.2993 49.9567L31.83 4.01636C30.6782 3.56929 29.2512 3.94734 28.5522 5.15812L1.20898 52.518C0.187919 54.2866 1.46421 56.4974 3.50644 56.4974H58.1929C59.562 56.4974 60.5874 55.4994 60.8065 54.3116H7.81356C5.57867 54.3116 4.18191 51.8923 5.2993 49.9567Z"
        fill="#4B3F4E"
      />
      <path
        d="M30.8493 26.0896H29.0884C28.4904 26.0896 28.0354 26.6266 28.1338 27.2164L29.7466 36.8938C29.8244 37.3605 30.2282 37.7026 30.7012 37.7026H30.8493V26.0896Z"
        fill="#4B3F4E"
      />
      <path
        d="M31.8177 44.4769C30.7489 44.4769 29.8823 43.6103 29.8823 42.5414C29.8823 42.2578 29.9437 41.9874 30.0534 41.7448C29.3813 42.0483 28.9146 42.7239 28.9146 43.5091C28.9146 44.578 29.7811 45.4446 30.85 45.4446C31.6353 45.4446 32.3109 44.9777 32.6144 44.3057C32.3717 44.4153 32.1013 44.4769 31.8177 44.4769Z"
        fill="#4B3F4E"
      />
      <path
        d="M53.1445 49.474H23.2718C18.0571 49.474 14.7979 43.8289 17.4052 39.3127L32.3414 13.4422L31.6872 12.3091C31.3148 11.6639 30.3835 11.6639 30.011 12.3091L8.1301 50.2083C7.75768 50.8535 8.22315 51.6599 8.96823 51.6599H52.7302C53.4752 51.6599 53.9408 50.8535 53.5684 50.2083L53.1445 49.474Z"
        fill="#FFD164"
      />
    </g>
    <defs>
      <clipPath id="clip0_1032_3010">
        <rect
          width="60"
          height="60"
          fill="white"
          transform="translate(0.849609 0.165161)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconBtnCamOff",
  components: {},

  data: () => {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
