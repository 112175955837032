<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.747 0.219905C12.8855 0.0780987 13.0696 -3.05176e-05 13.2649 -3.05176e-05C13.4602 -3.05176e-05 13.6441 0.0780987 13.7828 0.219905C14.0724 0.516051 14.0724 0.997923 13.7828 1.29407L9.87523 5.29096L9.48278 4.88948L8.83325 4.22324L12.747 0.219905ZM8.31458 4.3866C8.31646 4.3891 8.31833 4.39159 8.32054 4.39386L9.12745 5.2193L9.92555 6.03796C7.58532 8.59207 7.54942 12.2118 7.59616 13.2794C7.60214 13.4156 7.55133 13.549 7.45672 13.6448L7.1065 14C6.69667 13.767 6.30885 13.5211 5.93932 13.2667C5.98143 12.9343 6.03867 12.6019 6.1115 12.277C6.40143 10.9828 6.93636 9.74154 7.70127 8.58789C7.77522 8.47645 7.74485 8.32584 7.63353 8.25189C7.52209 8.1776 7.37182 8.20808 7.29798 8.31974C6.5001 9.52307 5.94192 10.8187 5.639 12.1711C5.58165 12.4274 5.53366 12.6883 5.49426 12.9501C4.60143 12.2903 3.83359 11.5819 3.17842 10.8772C3.28962 10.7883 3.40027 10.6987 3.50911 10.6089C4.35452 9.91123 5.17023 9.15354 5.93379 8.35689C6.02648 8.26036 6.02321 8.10692 5.92679 8.01411C5.83037 7.92131 5.67705 7.92469 5.58458 8.02123C4.83401 8.80433 4.03218 9.54904 3.20122 10.2348C3.0864 10.3295 2.96955 10.4239 2.85224 10.5175C2.19606 9.77146 1.66666 9.04142 1.24734 8.38941C1.51616 8.34865 1.7843 8.29818 2.04793 8.23744C3.38266 7.92989 4.66116 7.36368 5.84798 6.55438C5.95851 6.47897 5.98708 6.32813 5.91177 6.21748C5.83658 6.10695 5.68597 6.07838 5.57544 6.15358C4.43851 6.92889 3.21522 7.47105 1.93943 7.76494C1.61969 7.83866 1.29307 7.89658 0.966779 7.93915C0.424624 7.03772 0.117079 6.34088 0 6.05569L0.0803862 5.97417C0.19024 5.86274 0.345819 5.81317 0.496204 5.84084C1.55455 6.03808 4.39087 6.26072 8.08299 4.14796L8.31026 4.38111C8.3118 4.38288 8.31319 4.38474 8.31458 4.3866Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "IconBroom",
};
</script>
