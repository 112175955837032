var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"patient-schedule"},[_c('PatientHeader'),_c('section',{staticClass:"title"},[_c('span',{style:({ 'text-decoration': 'underline', cursor: 'pointer' }),on:{"click":_vm.onClickBack}},[_vm._v(" Início ")]),_c('IconArrow'),_c('span',[_vm._v("Adicionar Documentos")])],1),_c('section',{staticClass:"content"},[_c('section',{staticClass:"warning"},[_c('IconAlert',{staticClass:"icon"}),_vm._m(0)],1),_c('section',{staticClass:"inputs-file"},[_c('SelectInput',{staticClass:"sel",attrs:{"required":"","label":"Tipo de documento","options":_vm.optType,"preselect-first":false},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('InputFiles',{ref:"InputFiles",staticClass:"ifile",attrs:{"fileBase64":_vm.form.file},on:{"change":_vm.onChangeBase64,"remove":_vm.onRemoveImg}}),_c('PlButton',{staticClass:"btn",attrs:{"label":"Salvar","check":"","primary":"","disabled":_vm.disableButton},on:{"click":_vm.onClickSendFile}})],1),_c('div',{staticClass:"subtitle"},[_vm._v("Meus Documentos")]),_c('section',{staticClass:"files"},[(_vm.listExaminationResult && _vm.listExaminationResult.length > 0)?_c('div',{staticClass:"type"},[_vm._v(" Resultado de Exame ")]):_vm._e(),_c('section',{staticClass:"flex"},_vm._l((_vm.listExaminationResult),function(item,idx){return _c('div',{key:`file-${idx}`,staticClass:"file",attrs:{"title":item.doc_nome}},[_c('div',{staticClass:"acts"},[_c('i',{staticClass:"fas fa-trash",attrs:{"title":"Remover arquivo"},on:{"click":function($event){return _vm.onClickDeleteFile(item)}}})]),(
              item.doc_extensao === 'jpeg' ||
              item.doc_extensao === 'jpg' ||
              item.doc_extensao === 'png'
            )?_c('div',{staticClass:"fimg",on:{"click":function($event){return _vm.onClickOpenFile(item)}}},[_c('img',{attrs:{"src":item.doc_url}})]):(item.doc_extensao === 'pdf')?_c('div',{staticClass:"pdf",on:{"click":function($event){return _vm.onClickOpenFile(item)}}},[_c('i',{staticClass:"far fa-file-pdf"})]):_c('div',{staticClass:"other",on:{"click":function($event){return _vm.onClickOpenFile(item)}}},[_c('i',{staticClass:"far fa-file"})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.doc_nome))])])}),0),(_vm.listExaminationRequest && _vm.listExaminationRequest.length > 0)?_c('div',{staticClass:"type"},[_vm._v(" Solicitação de Exame ")]):_vm._e(),_c('section',{staticClass:"flex"},_vm._l((_vm.listExaminationRequest),function(item,idx){return _c('div',{key:`file-${idx}`,staticClass:"file",attrs:{"title":item.doc_nome}},[_c('div',{staticClass:"acts"},[_c('i',{staticClass:"fas fa-trash",attrs:{"title":"Remover arquivo"},on:{"click":function($event){return _vm.onClickDeleteFile(item)}}})]),(
              item.doc_extensao === 'jpeg' ||
              item.doc_extensao === 'jpg' ||
              item.doc_extensao === 'png'
            )?_c('div',{staticClass:"fimg",on:{"click":function($event){return _vm.onClickOpenFile(item)}}},[_c('img',{attrs:{"src":item.doc_url}})]):(item.doc_extensao === 'pdf')?_c('div',{staticClass:"pdf",on:{"click":function($event){return _vm.onClickOpenFile(item)}}},[_c('i',{staticClass:"far fa-file-pdf"})]):_c('div',{staticClass:"other",on:{"click":function($event){return _vm.onClickOpenFile(item)}}},[_c('i',{staticClass:"far fa-file"})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.doc_nome))])])}),0),(_vm.listPrescription && _vm.listPrescription.length > 0)?_c('div',{staticClass:"type"},[_vm._v(" Receita ")]):_vm._e(),_c('section',{staticClass:"flex"},_vm._l((_vm.listPrescription),function(item,idx){return _c('div',{key:`file-${idx}`,staticClass:"file",attrs:{"title":item.doc_nome}},[_c('div',{staticClass:"acts"},[_c('i',{staticClass:"fas fa-trash",attrs:{"title":"Remover arquivo"},on:{"click":function($event){return _vm.onClickDeleteFile(item)}}})]),(
              item.doc_extensao === 'jpeg' ||
              item.doc_extensao === 'jpg' ||
              item.doc_extensao === 'png'
            )?_c('div',{staticClass:"fimg",on:{"click":function($event){return _vm.onClickOpenFile(item)}}},[_c('img',{attrs:{"src":item.doc_url}})]):(item.doc_extensao === 'pdf')?_c('div',{staticClass:"pdf",on:{"click":function($event){return _vm.onClickOpenFile(item)}}},[_c('i',{staticClass:"far fa-file-pdf"})]):_c('div',{staticClass:"other",on:{"click":function($event){return _vm.onClickOpenFile(item)}}},[_c('i',{staticClass:"far fa-file"})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.doc_nome))])])}),0),(_vm.listDocument && _vm.listDocument.length > 0)?_c('div',{staticClass:"type"},[_vm._v(" Documento ")]):_vm._e(),_c('section',{staticClass:"flex"},_vm._l((_vm.listDocument),function(item,idx){return _c('div',{key:`file-${idx}`,staticClass:"file",attrs:{"title":item.doc_nome}},[_c('div',{staticClass:"acts"},[_c('i',{staticClass:"fas fa-trash",attrs:{"title":"Remover arquivo"},on:{"click":function($event){return _vm.onClickDeleteFile(item)}}})]),(
              item.doc_extensao === 'jpeg' ||
              item.doc_extensao === 'jpg' ||
              item.doc_extensao === 'png'
            )?_c('div',{staticClass:"fimg",on:{"click":function($event){return _vm.onClickOpenFile(item)}}},[_c('img',{attrs:{"src":item.doc_url}})]):(item.doc_extensao === 'pdf')?_c('div',{staticClass:"pdf",on:{"click":function($event){return _vm.onClickOpenFile(item)}}},[_c('i',{staticClass:"far fa-file-pdf"})]):_c('div',{staticClass:"other",on:{"click":function($event){return _vm.onClickOpenFile(item)}}},[_c('i',{staticClass:"far fa-file"})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.doc_nome))])])}),0)])]),_c('section',{staticClass:"footer"},[_c('PlButton',{attrs:{"label":"","primary":"","arrowLeft":""},on:{"click":_vm.onClickBack}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('div',{staticClass:"txt_title"},[_vm._v("ATENÇÃO!")]),_c('div',{staticClass:"txt_text"},[_vm._v(" Todos os arquivos guardados aqui "),_c('b',[_vm._v("podem ser visualizados")]),_vm._v(" pelo médico ou atendente. Mantenha salvo apenas os documentos de saúde que são importantes para avaliação da equipe médica. ")])])
}]

export { render, staticRenderFns }