<template lang="html">
  <section class="text-input input--wrapper">
    <label v-if="label">
      {{ label }} <span class="is--required" v-if="required">*</span>
    </label>

    <div class="input--content">
      <div v-if="hasPrependSlot" class="input--slot is--prepend">
        <slot name="prepend" />
      </div>

      <!-- v-on="listeners" -->
      <component
        ref="editable"
        :is="tag"
        :contenteditable="!disabled"
        :placeholder="placeholder"
        v-bind="$attrs"
        @input="onUpdate"
        @blur="onUpdate"
        @keypress="onKeypress"
        @paste="onPaste"
        :style="{ textTransform }"
        :class="[
          'input',
          {
            'is--append': hasAppendSlot,
            'is--prepend': hasPrependSlot,
            disabled: disabled
          }
        ]"
      />

      <div v-if="hasAppendSlot" class="input--slot is--append">
        <slot name="append" />
      </div>

      <i
        v-if="errorMsg"
        :title="errorMsg"
        class="input--warning fas fa-exclamation-circle"
      />
    </div>
    <span class="helptext" v-if="helptext">{{ helptext }}</span>
  </section>
</template>

<script>
export default {
  name: 'TextInputGrow',
  inheritAttrs: false,
  props: {
    /**
     * Define tag of component
     * @default ''
     * @type {String}
     */
    tag: {
      type: String,
      default: 'div'
    },
    /**
     * Define label of component
     * @default ''
     * @type {String}
     */
    label: String,
    /**
     * Add select is required.
     * @default false
     * @type {Boolean}
     */
    required: Boolean,
    /**
     * Disables the input if true.
     * @default false
     * @type {Boolean}
     */
    disabled: Boolean,
    /**
     * Equivalent to the `placeholder` attribute on a `<select>` input.
     * @default ''
     * @type {String}
     */
    placeholder: String,
    /**
     * Help text similar to bootstrap
     * @default ''
     * @type {String}
     */
    helptext: String,
    /**
     * Error message pass by vuelidate
     * @default ''
     * @type {String}
     */
    errorMsg: String,
    /**
     * Presets the selected options value.
     * @default ''
     * @type {String}
     */
    value: String,
    /**
     * Set case for text and value
     * @default ''
     * @type {String}
     */
    textCase: {
      type: String,
      default: 'normal',
      validator: value => ['normal', 'upper', 'lower'].includes(value)
    }
  },

  computed: {
    hasPrependSlot () {
      return !!this.$slots['prepend']
    },
    hasAppendSlot () {
      return !!this.$slots['append']
    },
    textTransform () {
      switch (this.textCase) {
      case 'upper':
        return 'uppercase'
      case 'lower':
        return 'lowercase'
      default:
        return 'none'
      }
    }
  },

  watch: {
    value (newValue) {
      if (newValue !== this.getContent()) {
        this.setContent(newValue)
      }
    }
  },

  methods: {
    changeTextCase (content) {
      switch (this.textCase) {
      case 'upper':
        return content.toUpperCase()
      case 'lower':
        return content.toLowerCase()
      default:
        return content
      }
    },

    setContent (content) {
      this.$refs.editable.innerText = this.changeTextCase(content)
    },

    getContent () {
      return this.changeTextCase(this.$refs.editable.innerText)
    },

    onUpdate (event) {
      this.$emit('input', this.getContent())
    },
    onKeypress (event) {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    },
    onPaste (event) {
      event.preventDefault()
      var text = event.clipboardData.getData('text/plain')
      document.execCommand('insertText', false, text)
      this.$emit(event.type, event)
    }
  }
}
</script>
