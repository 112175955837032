<template>

<svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.2963 1.32622L15.0134 4.61228L5.10293 14.5194L2.10939 17.5131C1.91306 17.7093 1.62708 17.7859 1.35906 17.7141C1.0909 17.6422 0.881534 17.4328 0.809691 17.1648C0.737864 16.8967 0.81448 16.6107 1.01081 16.4144L17.1976 0.227526C17.3433 0.0818387 17.5408 0 17.7469 0C17.9529 0 18.1505 0.0818387 18.2962 0.227526C18.4419 0.373213 18.5238 0.570846 18.5238 0.776894C18.5238 0.982941 18.4419 1.18057 18.2962 1.32626L18.2963 1.32622Z" fill="#6C6C6C"/>
<path d="M13.0634 3.4843L2.02848 14.5355H1.98625C1.52845 14.5355 1.08949 14.3537 0.765788 14.03C0.442059 13.7064 0.260254 13.2673 0.260254 12.8097V4.94711C0.260254 4.48946 0.44207 4.05034 0.765788 3.72676C1.08952 3.40303 1.52845 3.22122 1.98625 3.22122H12.1467C12.4709 3.22166 12.7884 3.31278 13.0634 3.48444L13.0634 3.4843Z" fill="#6C6C6C"/>
<path d="M20.2955 3.49095V14.2495C20.2984 14.4468 20.2483 14.6414 20.1505 14.8128C20.0528 14.9843 19.9109 15.1265 19.7396 15.2246C19.5697 15.3227 19.3768 15.3742 19.1806 15.374C18.9853 15.3742 18.7936 15.3227 18.6247 15.2246L13.8725 12.4715V12.7965C13.8725 13.2543 13.6907 13.6933 13.3671 14.017C13.0434 14.3406 12.6044 14.5225 12.1466 14.5225H5.97095L17.1035 3.40963L18.6378 2.5255C18.8656 2.39447 19.1326 2.34818 19.3913 2.39476C19.65 2.44134 19.8839 2.57788 20.0517 2.78031C20.2195 2.98258 20.3105 3.23781 20.3083 3.50063L20.2955 3.49095Z" fill="#6C6C6C"/>
</svg>

</template>

<script>
export default {
  name: 'IconBtnCamOff',
  components: {
  },

  data: () => {
    return {
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>
