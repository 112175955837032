<template>

<svg width="27" height="15" viewBox="0 0 27 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M27 2.05944V12.9404C27 13.2841 26.8317 13.6013 26.5581 13.7731C26.4215 13.8592 26.2688 13.9019 26.1162 13.9019C25.9636 13.9019 25.811 13.8589 25.6743 13.7731L20.504 10.5252V14.0384C20.504 14.5696 20.1084 15 19.6202 15H0.883813C0.395622 15 0 14.5696 0 14.0384V0.961578C0 0.430432 0.3956 0 0.883813 0H19.6202C20.1084 0 20.504 0.430408 20.504 0.961578V4.47476L25.6743 1.22688C25.811 1.1411 25.9636 1.09807 26.1162 1.09807C26.2688 1.09807 26.4215 1.14111 26.5581 1.22688C26.8317 1.39874 27 1.7162 27 2.0596V2.05944Z" fill="white"/>
</svg>

</template>

<script>
export default {
  name: 'IconBtnCamOn',
  components: {
  },

  data: () => {
    return {
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>
