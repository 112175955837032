<style src="./TextInputDate.scss" lang="scss"></style>

<template lang="html">
  <section class="text-input-date input--wrapper">
    <label v-if="label">
      {{ label }} <span class="is--required" v-if="required">*</span>
    </label>

    <div class="input--content">
      <div v-if="hasPrependSlot" class="input--slot is--prepend">
        <slot name="prepend" />
      </div>
      <div class="picker--content">
        <DatePicker
          @focus="focus"
          type="date"
          :lang="lang"
          :format="format"
          :formatter="momentFormat"
          :disabled="disabled"
          :placeholder="placeholder"
          :inline="inline"
          value-type="format"
          v-model="innerValue"
          :disabled-date="disabledDate"
          :input-class="[
            'picker-input',
            {
              'is--append': hasAppendSlot,
              'is--prepend': hasPrependSlot
            }
          ]"
        />

        <span
          v-if="today && !inline"
          @click="setToday"
          :class="[
            'shortkey-tdy',
            {
              'is--disabled': disabled || isToday
            }
          ]"
        >
          Hoje
        </span>
      </div>

      <span
        class="is--required no-label"
        v-if="isLabelEmpty && required && !disabled"
        >*</span
      >

      <div v-if="hasAppendSlot" class="input--slot is--append">
        <slot name="append" />
      </div>

      <i
        v-if="errorMsg"
        :title="errorMsg"
        class="input--warning fas fa-exclamation-circle"
      />
    </div>
    <span class="helptext" v-if="helptext">{{ helptext }}</span>
  </section>
</template>

<script>
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import DatePicker from 'vue2-datepicker'

import InputMixin from '../InputMixin'

import lang from 'vue2-datepicker/locale/pt-br'

const format = 'DD/MM/YYYY'

export default {
  name: 'TextInputDate',
  extends: {
    props: { ...InputMixin.props },
    computed: { ...InputMixin.computed }
  },
  components: {
    DatePicker
  },
  props: {
    today: {
      type: Boolean,
      default: true
    },
    helptext: String,
    inline: Boolean,
    disabledDate: {
      type: Function,
      default: () => false
    }
  },
  data () {
    return {
      format,
      innerValue: null,
      lang: {
        ...lang,
        formatLocale: {
          ...lang['formatLocale'],
          weekdaysShort: ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'],
          weekdaysMin: ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB']
        }
      },
      momentFormat: {
        stringify: date => {
          return date ? moment(date).format(format) : ''
        },
        parse: value => {
          return value ? moment(value, format).toDate() : null
        }
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        this.innerValue = val
          ? moment(val, 'YYYY-MM-DD').format(this.format)
          : null
      }
    },
    innerValue (val) {
      const formated = val
        ? moment(val, this.format).format('YYYY-MM-DD')
        : null

      this.$emit('input', formated)
    }
  },
  computed: {
    isToday () {
      return !!(this.innerValue === moment().format(this.format))
    },
    isLabelEmpty () {
      return isEmpty(this.label)
    }
  },
  methods: {
    setToday () {
      if (!this.disabled && !this.isToday) {
        this.innerValue = moment().format(this.format)
      }
    },

    focus () {
      this.$emit('focus', true)
    }
  }
}
</script>
