import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";

// dicionario em: SELECT * FROM lista_logs;
const saveLog = async (code, timeAction, obj) => {
  const servicoHttp = new ServicoHttp(this)
  const sLogSave = new ServicoAll('/api/movement-log/save-log', servicoHttp)
  const objPayload = {
    code: code,
    timeAction: timeAction,
    payload: { ...obj }
  }
  await sLogSave.post(objPayload)
}

// *** *** ***
export default {
  saveLog
}
