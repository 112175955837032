<style src="./Video.scss" lang="scss" scoped></style>
<template>
  <section class="video-all">
    <!-- *************** -->
    <!-- *************** -->
    <!-- PRE SALA -->
    <section v-show="isWaitServiceRoom" class="pre-room">
      <div class="left">
        <div class="title">SALA DE ESPERA</div>
        <div class="text">
          Em poucos segundos será encaminhado para a Sala do Atendimento
        </div>
        <!-- <div v-if="!isDisabledBtn" class="btn" @click="actStartService">Iniciar Atendimento</div> -->
      </div>
      <div class="right">
        <div class="r-video">
          <div v-show="isCamOff" class="cam-off"><IconCamOff /></div>
          <div v-show="!isCamOff" class="local_stream" id="local_stream"></div>
        </div>
        <div v-if="!isDisabledBtn" class="r-acoes">
          <div class="btn-circle" :class="{'green': isBtnMicroOn}" @click="actCloseMicro">
            <IconBtnMicroOff v-if="!isBtnMicroOn"/>
            <IconBtnMicroOn v-else/>
          </div>
          <!-- <div class="btn-circle" :class="{'green': isBtnSoundOn}" @click="actCloseSound">
            <IconBtnSoundOff v-if="!isBtnSoundOn"/>
            <IconBtnSoundOn v-else/>
          </div> -->
          <div class="btn-circle" :class="{'green': isBtnCamOn}" @click="actCloseVideoSelf">
            <IconBtnCamOff v-if="!isBtnCamOn"/>
            <IconBtnCamOn v-else/>
          </div>
          <div class="btn-circle" @click="actCloseRoom"><IconBtnExit /></div>
        </div>
      </div>
    </section>
    <!-- *************** -->
    <!-- *************** -->
    <!-- DENTRO DA SALA -->
    <section v-show="!isWaitServiceRoom" class="room">
      <div class="video-screen">
        <IconWaitService v-show="!profissionalEntered"/>
        <div v-show="!profissionalEntered" class="profissional-entered">AGUARDANDO ATENDIMENTO...</div>
        <div v-show="profissionalEntered" class="expand_stream" id="expand_stream"></div>
      </div>

      <div class="video-local">
        <div class="local_stream" id="local_stream_big"></div>
      </div>

      <div class="acts">
        <div class="btn-circle" :class="{'green': isBtnMicroOn}" @click="actCloseMicro">
            <IconBtnMicroOff v-if="!isBtnMicroOn"/>
            <IconBtnMicroOn v-else/>
          </div>
          <!-- <div class="btn-circle" :class="{'green': isBtnSoundOn}" @click="actCloseSound">
            <IconBtnSoundOff v-if="!isBtnSoundOn"/>
            <IconBtnSoundOn v-else/>
          </div> -->
          <div class="btn-circle" :class="{'green': isBtnCamOn}" @click="actCloseVideoSelf">
            <IconBtnCamOff v-if="!isBtnCamOn"/>
            <IconBtnCamOn v-else/>
          </div>
        <div class="btn-circle red" @click="actShowCloseRoom"><i class="fas fa-door-open"></i></div>
      </div>
    </section>
    <!-- *************** -->
    <!-- *************** -->
    <!-- MODAL SAIDA -->
    <section v-if="showModalExit" class="modal-video">
      <div class="modal-video-form">
        <div class="mvf-title">Deseja Sair da Sala?</div>
        <div class="mvf-content">Ao sair da sala, seu atendimento será encerrado. Deseja continuar?</div>
        <div class="mvf-act">
          <div class="mvf-btn mr" @click="showModalExit = false">Cancelar</div>
          <div class="mvf-btn red" @click="actCloseRoom">Sair da Sala</div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import Agora from '@/helpers/agora'
import Firebase from '@/helpers/firebase'
import AgoraRTM from 'agora-rtm-sdk'

import IconWaitService from '@/assets/icons/IconWaitService'
import IconCamOff from '@/assets/icons/IconCamOff'

import IconBtnCamOff from '@/assets/icons/IconBtnCamOff'
import IconBtnMicroOff from '@/assets/icons/IconBtnMicroOff'
import IconBtnSoundOff from '@/assets/icons/IconBtnSoundOff'
import IconBtnCamOn from '@/assets/icons/IconBtnCamOn'
import IconBtnMicroOn from '@/assets/icons/IconBtnMicroOn'
import IconBtnSoundOn from '@/assets/icons/IconBtnSoundOn'
import IconBtnExit from '@/assets/icons/IconBtnExit'

export default {
  name: 'PortalVideo',
  props: {
    cpf: String,
    startRoom: Boolean
  },
  components: {
    IconWaitService,
    IconCamOff,
    IconBtnCamOff,
    IconBtnMicroOff,
    IconBtnSoundOff,
    IconBtnCamOn,
    IconBtnMicroOn,
    IconBtnSoundOn,
    IconBtnExit
  },
  
  // ****************
  data () {
    return {
      rtc: {
        client: null,
        joined: false,
        published: false,
        localStream: null,
        remoteStreams: [],
        params: {}
      },
      listRemoteStreams: [],

      videoOn: true,
      audioOn: true,

      agoraUID: null,
      channelName: null,
      clientRTM: null,
      tokenRTM: null,
      appID: '61cf3781778b4a15a3460bd5ab7814bb', // rg homologacao

      isWaitServiceRoom: false,
      isCamOff: true,
      isDisabledBtn: true,
      isBtnCamOn: true,
      isBtnSoundOn: true,
      isBtnMicroOn: true,
      profissionalEntered: false,
      qsId: null,

      showModalExit: false
    }
  },
  // ****************
  watch: {
    startRoom (val) {
      this.actStartRoom()
      // this.isDisabledBtn = false
    }
  },

  // ****************
  // ****************
  beforeDestroy() {
    if (this.rtc?.client) {
      this.actLeaveRoom()
    }
    Firebase.removeFirebaseRoom(this.agoraUID)
    Firebase.removeCallRoom(this.qsId)
  },

  beforeMount() {
    this.clientRTM = AgoraRTM.createInstance(this.appID)
    const qs = this.$route.query
    qs.env ? Firebase.setFirebaseConfig(qs.env) : Firebase.setFirebaseConfig(null)
    if (!qs.id) {
      this.qsId = 'qsId_nao_identificado'
    } else {
      this.qsId = qs.id
    }
  },

  async mounted() {
    const cpfTmp = this.cpf
    this.agoraUID = Agora.cpfClean(cpfTmp)
    this.channelName = `PepTelemdicina${this.agoraUID}`
  },
  // ****************
  // ****************
  methods: {
    // * ACOES LOCAIS
    actShowCloseRoom () {
      this.showModalExit = true
    },
    actCloseRoom () {
      this.showModalExit = false
      this.actLeaveRoom()
      this.isWaitServiceRoom = false
      Firebase.removeFirebaseRoom(this.agoraUID)
      Firebase.removeCallRoom(this.qsId)
      this.$emit('close')
    },
    actCloseMicro () {
      console.log("actCloseMicro")
      this.clickAudioOnOff()
    },
    actCloseSound () {
      console.log("actCloseSound")
    },
    actCloseVideoSelf () {
      console.log("actCloseVideoSelf")
      this.clickVideoOnOff()
    },
    actCloseVideoOther () {
      console.log("actCloseVideoOther")
    },

    actStartService () {
      this.isWaitServiceRoom = false
      this.rtc.localStream.stop()
      this.rtc.localStream.play('local_stream_big')
    },

    // *********
    // *********
    // * ACOES DE SALA
    async actStartRoom() {
      let obj = {
        canal: this.channelName,
        uid: this.agoraUID
      }
      let tmpToken = await Agora.getToken(obj)
      obj.token = tmpToken
      
      Agora.join(obj, this.rtc, this.appID, async ret => {
        this.isCamOff = false
        this.isDisabledBtn = false
        Firebase.setFirebaseRoom(this.agoraUID, this.channelName, tmpToken)
        if (ret.code !== 'error') {
          // *** PRIMEIRO ON
          this.rtc.client.on('stream-added', evt => {
            let remoteStream = evt.stream
            let id = remoteStream.getId()
            if (id !== this.rtc.params.uid) {
              this.rtc.client.subscribe(remoteStream, err => {
                console.error('stream subscribe failed', err)
              })
            }
          })
          // *** SEGUNDO ON
          this.rtc.client.on('stream-subscribed', evt => {
            this.profissionalEntered = true
            let remoteStream = evt.stream
            remoteStream.setAudioVolume(20)
            let id = remoteStream.getId()
            this.addView(id)
            remoteStream.play('remote_video_' + id)
            this.listRemoteStreams.push({
              id: id.toString(),
              stream: remoteStream,
              expand: false
            })
          })
          // *** TERCEIRO ON
          this.rtc.client.on('peer-leave', evt => {
            var uid = evt.uid
            this.removeView(uid)
          })

          this.$forceUpdate()
        } else {
          console.error('ERROR AGORA.JOIN', ret.msg)
          console.error(ret)
        }
      })

      setTimeout(()=>{
        this.actStartService()
      }, 5000)
    },

    actLeaveRoom() {
      Firebase.removeFirebaseRoom(this.agoraUID)
      Firebase.removeCallRoom(this.qsId)
      if (!this.rtc.client) {
        return
      }
      this.rtc.client.leave(
        async () => {
          this.rtc.localStream.stop()
          this.rtc.localStream.close()
          for (let i in this.listRemoteStreams) {
            let remote = this.listRemoteStreams[i]
            remote.stream.stop()
            this.removeView(remote.id)
          }
          this.rtc.client = null
          this.$forceUpdate()
        },
        err => {
          console.error('channel leave failed')
          console.error(err)
        }
      )
    },
    // *********
    // *********
    // * JQUERY
    removeView(id) {
      let temp = document.getElementById('remote_video_panel_' + id)
      if (temp) {
        document.getElementById('remote_video_panel_' + id).remove()
      }
      this.$forceUpdate()
    },

    addView(id) {
      let temp = document.getElementById(id)
      if (!temp) {
        let videoView = Agora.createVideoView(id, true)
        document.getElementById('expand_stream').appendChild(videoView)
        let videoPlaceholder = Agora.createVideoPlaceholder(id)
        videoView.appendChild(videoPlaceholder)
      }
    },
    // *********
    // *********
    // * ACOES DO RTC LOCAL
    clickVideoOnOff() {
      this.isBtnCamOn ? this.rtc.localStream.disableVideo() : this.rtc.localStream.enableVideo()
      this.isBtnCamOn = !this.isBtnCamOn
    },

    clickAudioOnOff() {
      this.isBtnMicroOn ? this.rtc.localStream.disableAudio() : this.rtc.localStream.enableAudio()
      this.isBtnMicroOn = !this.isBtnMicroOn
    },
    // *********
    // *********
  }
}
</script>
