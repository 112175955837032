<style src="./Checkbox.scss" lang="scss" scoped></style>
<template lang="html">
  <label
    ref="label"
    :disabled="disabled"
    :tabindex="disabled ? false : 0"
    @click="focus"
    @keydown.prevent.enter.space="$refs.label.click()"
    :class="[
      'checkbox',
      {
        'is--disabled': disabled,
        'is--checked': isChecked
      }
    ]"
  >
    <input
      ref="input"
      type="checkbox"
      :id="$attrs.id"
      :name="name"
      :disabled="disabled"
      :required="required"
      :value="nativeValue"
      :true-value="trueValue"
      :false-value="falseValue"
      @click.stop
      @change="onHandleChange"
      v-model="computedValue"
    />

    <span class="check" />
    <span class="control-label"><slot /></span>
  </label>
</template>

<script>
import isEqual from 'lodash/isEqual'

export default {
  name: 'Checkbox',
  inheritAttrs: false,
  props: {
    /**
     * Equivalent to the `name` attribute
     * @type String
     */
    name: String,
    /**
     * Equivalent to the `required` attribute
     * @default false
     * @type {Boolean}
     */
    required: {
      type: Boolean,
      default: false
    },
    /**
     * Disables the input if true.
     * @default false
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false
    },
    value: [String, Number, Boolean, Object, Array],
    nativeValue: [String, Number, Boolean, Object],
    trueValue: {
      type: [String, Number, Boolean, Object],
      default: true
    },
    falseValue: {
      type: [String, Number, Boolean, Object],
      default: false
    }
  },
  data () {
    return {
      newValue: this.value
    }
  },
  computed: {
    computedValue: {
      get () {
        return this.newValue
      },
      set (value) {
        this.newValue = value

        this.$emit('input', value)
      }
    },

    isChecked () {
      if (!Array.isArray(this.computedValue)) {
        return this.computedValue
      }

      let isChecked = false
      for (const item of this.computedValue) {
        if (isEqual(this.nativeValue, item)) {
          isChecked = true
          break
        }
      }

      return isChecked
    }
  },
  watch: {
    /**
     * When v-model change, set internal value.
     */
    value (value) {
      this.newValue = value
    }
  },

  methods: {
    focus () {
      // MacOS FireFox and Safari do not focus when clicked
      this.$refs.input.focus()
    },
    onHandleChange (e) {
      const event = e.target.checked ? 'onCheck' : 'onUncheck'

      this.$emit(event, this.nativeValue)
      this.$emit('change', { event, value: this.nativeValue })
    }
  }
}
</script>
