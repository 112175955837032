<template>

<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5175 0.861973H11.5548C11.3815 0.861686 11.2147 0.927341 11.0883 1.04577L6.5529 5.27905C6.29987 5.51548 5.9663 5.64694 5.6199 5.64635H0.89779C0.716566 5.64635 0.542636 5.71844 0.414484 5.8466C0.286332 5.97476 0.214233 6.14869 0.214233 6.32991V14.5321C0.214233 14.7133 0.286325 14.8872 0.414484 15.0154C0.542643 15.1436 0.716573 15.2156 0.89779 15.2156H5.6199C5.96633 15.2151 6.29988 15.3465 6.5529 15.583L11.0883 19.8162C11.2147 19.9347 11.3815 20.0003 11.5548 20H12.5175C12.6081 20 12.6951 19.9641 12.7592 19.9C12.8233 19.8358 12.8593 19.749 12.8593 19.6583V1.20341C12.8593 1.11272 12.8233 1.0259 12.7592 0.961677C12.6951 0.897597 12.6081 0.861694 12.5175 0.861694V0.861973Z" fill="white"/>
<path d="M14.7577 7.75449C14.6012 7.64965 14.3893 7.69127 14.2841 7.84761C14.1789 8.0038 14.22 8.21579 14.376 8.32147C14.4118 8.34636 15.2516 8.94311 15.2516 10.4311C15.2516 11.919 14.4118 12.5158 14.3783 12.539C14.3029 12.5893 14.2506 12.6676 14.2328 12.7564C14.2151 12.8453 14.2334 12.9376 14.2836 13.013C14.3885 13.17 14.6007 13.2125 14.7576 13.1077C14.8073 13.0756 15.9351 12.3015 15.9351 10.431C15.9351 8.56058 14.8072 7.78646 14.7576 7.75443L14.7577 7.75449Z" fill="white"/>
<path d="M17.9858 10.4311C17.9858 6.87169 15.8727 5.42228 15.7832 5.36223H15.783C15.6263 5.25724 15.414 5.29929 15.3089 5.45606C15.2039 5.61297 15.2459 5.82523 15.4027 5.93021C15.4802 5.98256 17.3022 7.25501 17.3022 10.4312C17.3022 13.6073 15.4802 14.8797 15.4037 14.9314C15.3283 14.9816 15.276 15.0598 15.2582 15.1488C15.2405 15.2376 15.2588 15.33 15.309 15.4054C15.4139 15.5624 15.6261 15.6048 15.783 15.5001C15.8726 15.4399 17.9857 13.9905 17.9857 10.4312L17.9858 10.4311Z" fill="white"/>
<path d="M16.8082 2.96988C16.7328 2.91953 16.6406 2.90108 16.5516 2.91882C16.4628 2.93641 16.3845 2.98862 16.334 3.06399C16.2292 3.22105 16.2712 3.43331 16.4282 3.53829C16.4573 3.55803 19.3526 5.56625 19.3526 10.4311C19.3526 15.2959 16.4573 17.304 16.4289 17.3235C16.3535 17.3738 16.3011 17.452 16.2834 17.5409C16.2656 17.6298 16.284 17.7221 16.3342 17.7975C16.439 17.9545 16.6513 17.9969 16.8082 17.8922C16.9398 17.8043 20.0362 15.6795 20.0362 10.4307C20.0362 5.18192 16.9398 3.05719 16.8082 2.96931L16.8082 2.96988Z" fill="white"/>
</svg>

</template>

<script>
export default {
  name: 'IconBtnSoundOn',
  components: {
  },

  data: () => {
    return {
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>
