import { initializeApp } from 'firebase/app'
import { getDatabase, ref, onValue, get, set } from 'firebase/database'

let FirebaseDB = null
const urlProfissionalSala = `videoconferencia/profissional/sala`
const urlPacienteSala = `videoconferencia/paciente/sala`
const urlStatusRoom = `videoconferencia/status/sala/paciente`
const urlLinkRoom = `videoconferencia/link/sala`
const urlInfoVirtualQueue = `videoconferencia/fila-virtual`

const setFirebaseConfig = () => {
  let config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID
  }
  const FirebaseApp = initializeApp(config)
  FirebaseDB = getDatabase(FirebaseApp)
}

const onInfoVirtualQueue = (call) => {
  const path = `${urlInfoVirtualQueue}/`
  onValue(ref(FirebaseDB, path), (snapshot) => {
    const data = snapshot.val()
    call(data)
  })
}

const onCallingCircleRoom = (funId, call) => {
  const path = `${urlProfissionalSala}/${funId}`
  onValue(ref(FirebaseDB, path), (snapshot) => {
    const data = snapshot.val()
    call(data)
  })
}

const onLinkRoom = (funId, call) => {
  const path = `${urlLinkRoom}/${funId}`
  onValue(ref(FirebaseDB, path), (snapshot) => {
    const data = snapshot.val()
    call(data)
  })
}

const onceLinkRoom = (funId, call) => {
  const path = `${urlLinkRoom}/${funId}`
  get(ref(FirebaseDB, path))
    .then((snapshot) => {
      const data = snapshot.val()
      call(data)
    })
    .catch((e) => {
      console.error('[onceLinkRoom] Error:', e)
      call({ status: 'erro' })
    })
}

const removeFirebaseLinkRoomByFunId = (funId) => {
  const ref = FirebaseDB.ref(`${urlLinkRoom}/${funId}`);
  ref
    .remove()
    .then(() => {
      console.log(
        `[removeFirebaseLinkRoomByFunId] Sucesso =) . FunId: ${funId}`,
      );
    })
    .catch((error) => {
      console.log(
        `[removeFirebaseLinkRoomByFunId Erro =( . FunId: ${funId}`,
        error,
      );
    });
};

const onPatientRoom = (pacId, call) => {
  const path = `${urlPacienteSala}/${pacId}`
  onValue(ref(FirebaseDB, path), (snapshot) => {
    const data = snapshot.val()
    call(data)
  })
}

const onceStatusRoomByPacId = (pacId, call) => {
  const path = `${urlStatusRoom}/${pacId}`
  get(ref(FirebaseDB, path))
    .then((snapshot) => {
      const data = snapshot.val()
      call(data)
    })
    .catch((e) => {
      console.error('[onceStatusRoomByPacId] Error:', e)
      call({ status: 'erro' })
    })
}

const updateStatusRoom = (pacId, status) => {
  const path = `${urlStatusRoom}/${pacId}`
  set(ref(FirebaseDB, path), { status: status })
}

// const updateStatusRoomWithFun = (pacId, funId, cliId, status) => {
//   const path = `${urlStatusRoom}/${pacId}`
//   set(ref(FirebaseDB, path), { status: status, funId: funId, cliId: cliId })
// }

const updateStatusRoomWithFunId = (pacId, funId, cliId, status) => {
  console.warn('***********')
  console.warn('updateStatusRoomWithFunId', pacId, funId, cliId, status)
  console.warn('***********')
  const path = `${urlStatusRoom}/${pacId}`
  set(ref(FirebaseDB, path), { status: status, pacId, funId, cliId })
}

export default {
  setFirebaseConfig,
  onCallingCircleRoom,
  onPatientRoom,
  onceStatusRoomByPacId,
  updateStatusRoom,
  onceLinkRoom,
  onLinkRoom,
  onInfoVirtualQueue,
  updateStatusRoomWithFunId,
  removeFirebaseLinkRoomByFunId
}
