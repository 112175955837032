import moment from 'moment'

const ValidateDate = pDate => {
  let date = moment(moment(pDate, 'DD/MM/YYYY'))
  return date.isValid() && date.isSameOrAfter(moment().format('LL'))
}

const IsBeforeThen = (pFirstDate, pSecondDate) => {
  let valid = ValidateDate(pFirstDate) && ValidateDate(pSecondDate)

  const isBefore = moment(moment(pFirstDate, 'DD/MM/YYYY'))
    .isSameOrBefore(moment(pSecondDate, 'DD/MM/YYYY'))

  return valid && isBefore
}

export default {
  ValidateDate
}

export {
  ValidateDate,
  IsBeforeThen
}
