export default {
  props: {
    noSpecialCharacter: {
      type: Boolean,
      default: false
    },

    /**
     * Define label of component
     * @type {String}
     */
    label: String,

    /**
     * Define label of component
     * @type {String}
     */
    helptext: String,
    /**
     * Add select is required.
     * @default false
     * @type {Boolean}
     */
    required: {
      type: Boolean,
      default: false
    },
    /**
     * Presets the selected options value.
     */
    value: {
      type: [String, Number, Boolean, Function, Object, Date, Array, Symbol],
      default: null
    },
    /**
     * Equivalent to the `placeholder` attribute on a `<select>` input.
     * @default ''
     * @type {String}
     */
    placeholder: {
      type: String,
      default: ''
    },
    /**
     * Disables the input if true.
     * @default false
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: [Number, String],
    errorMsg: {
      type: String,
      default: ''
    },
    textCase: {
      type: String,
      default: 'normal',
      validator: value =>
        ['normal', 'upper', 'lower', 'capitalize'].includes(value)
    }
  },
  data () {
    return {
      innerValue: this.value
    }
  },
  computed: {
    hasPrependSlot () {
      return !!this.$slots['prepend']
    },
    hasAppendSlot () {
      return !!this.$slots['append']
    },
    listeners () {
      return { ...this.$listeners, input: this.onInput }
    }
  },
  watch: {
    /**
     * When v-model is changed:
     *   1. Set the innerValue.
     *   2. If it's invalid, validate again.
     */
    value (value) {
      if (this.noSpecialCharacter) {
        let rg = /^[ a-zà-úA-ZÀ-Ú ]+(([ ',.\s-][ a-zà-úA-ZÀ-Ú\s ])?[ a-zà-úA-ZÀ-Ú ]*)*$/g
        let array = rg.exec(value)
        if (array === null) {
          value = value.substring(0, value.length - 1)
        }
      }

      this.innerValue = value
    },
    /**
     * When innerValue:
     *   1. Emit input event to update the user v-model.
     */
    innerValue (value) {
      this.$emit('input', value)
    }
  },
  methods: {
    onInput (event) {
      let value = event.target.value

      switch (this.textCase) {
      case 'upper':
        value = value.toUpperCase()
        break

      case 'lower':
        value = value.toLowerCase()
        break

      case 'capitalize':
        value = value.charAt(0).toUpperCase() + value.slice(1)
        break
      }

      this.$emit('input', value)
    }
  }
}
