<template>
  <svg
    width="53"
    height="43"
    viewBox="0 0 53 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.033 42.7652H5.71139C3.01908 42.7652 0.828125 40.5742 0.828125 37.8819V11.8379C0.828125 9.14557 3.01908 6.95462 5.71139 6.95462H12.8426L15.6439 1.34538C15.9174 0.791943 16.4822 0.443604 17.0991 0.443604H36.6485C37.2654 0.443604 37.8286 0.791943 38.1053 1.34538L40.9018 6.95462H48.033C50.7253 6.95462 52.9162 9.14557 52.9162 11.8379V37.8819C52.9162 40.5742 50.7253 42.7652 48.033 42.7652ZM49.6607 11.8379C49.6607 10.941 48.9299 10.2101 48.033 10.2101H39.8942C39.8128 10.2101 39.7428 10.1759 39.6647 10.1645C39.5491 10.1466 39.4336 10.1369 39.3229 10.0946C39.2236 10.0571 39.1406 9.99689 39.051 9.93992C38.9631 9.8862 38.8736 9.84062 38.7971 9.769C38.7108 9.68924 38.649 9.59158 38.5822 9.49391C38.5367 9.4288 38.4732 9.3816 38.4374 9.30835L35.6409 3.69911H18.1067L15.307 9.30835C15.2305 9.46299 15.1279 9.59646 15.014 9.71366C14.9912 9.73808 14.9635 9.75273 14.9391 9.77551C14.8219 9.88132 14.6933 9.96759 14.5533 10.0343C14.5061 10.0587 14.4606 10.0783 14.4101 10.0978C14.231 10.1629 14.0455 10.2085 13.8534 10.2101H13.8502H5.71139C4.81449 10.2101 4.08363 10.941 4.08363 11.8379V37.8819C4.08363 38.7788 4.81449 39.5097 5.71139 39.5097H48.033C48.9299 39.5097 49.6607 38.7788 49.6607 37.8819V11.8379ZM26.8722 34.6264C20.5891 34.6264 15.4779 29.5153 15.4779 23.2322C15.4779 16.949 20.5891 11.8379 26.8722 11.8379C33.1553 11.8379 38.2665 16.949 38.2665 23.2322C38.2665 29.5153 33.1553 34.6264 26.8722 34.6264ZM26.8722 15.0934C22.3845 15.0934 18.7334 18.7444 18.7334 23.2322C18.7334 27.7199 22.3845 31.3709 26.8722 31.3709C31.3599 31.3709 35.011 27.7199 35.011 23.2322C35.011 18.7444 31.3599 15.0934 26.8722 15.0934Z"
      fill="#9C9C9C"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCam",
  components: {},

  data: () => {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
