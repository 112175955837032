<template lang="html">
  <section class="text-input-number input--wrapper">
    <label v-if="label">
      {{ label }} <span class="is--required" v-if="required">*</span>
    </label>

    <div class="input--content">
      <div v-if="hasPrependSlot" class="input--slot is--prepend">
        <slot name="prepend" />
      </div>

      <!-- v-money="computedOptions" -->
      <input
        ref="inputNumber"
        type="tel"
        autocomplete="off"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="innerValue"
        @change="change"
        :class="{
          'is--append': hasAppendSlot,
          'is--prepend': hasPrependSlot
        }"
      />

      <div v-if="hasAppendSlot" class="input--slot is--append">
        <slot name="append" />
      </div>

      <i
        v-if="errorMsg"
        :title="errorMsg"
        class="input--warning fas fa-exclamation-circle"
      />
    </div>

    <span v-if="helptext" class="helptext">{{ helptext }}</span>
  </section>
</template>

<script>
// import { VMoney } from 'v-money'
import cloneDeep from 'lodash/cloneDeep'
import { format, unformat } from './utils'

import InputMixin from '../InputMixin'

const defaultOptions = Object.freeze({
  precision: 2,
  decimal: ',',
  thousands: '.',
  allowBlank: true
})

export default {
  name: 'TextInputFloat',
  inheritAttrs: false,
  directives: {
    // money: VMoney
  },
  extends: {
    props: { ...InputMixin.props },
    computed: { ...InputMixin.computed }
  },
  props: {
    decimalLimit: {
      type: Number,
      default: 0
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      lastValue: null,
      innerValue: this.value
    }
  },
  computed: {
    computedOptions () {
      const options = {
        ...defaultOptions,
        ...this.options,
        ...(this.decimalLimit !== null && {
          precision: this.decimalLimit,
          masked: true
        })
      }

      return cloneDeep(options)
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (newValue) {
        const formatted = format(newValue, this.computedOptions)
        if (formatted !== this.formattedValue) {
          this.innerValue = formatted
          this.lastValue = formatted
        }
      }
    }
  },

  methods: {
    prepareValue (evt) {
      const { precision } = this.computedOptions
      const value = evt.target.value
      return value === '' ? value : unformat(evt.target.value, precision)
    },

    change (evt) {
      const value = this.prepareValue(evt)
      if (value !== this.lastValue) {
        this.$emit('input', value)
        this.lastValue = value
      }
    }
  }
}
</script>
