<style src="./PatientNps.scss" lang="scss" scoped></style>
<template>
  <section class="patient-nps">
    <!-- HEADER -->
    <PatientHeader />
    <!-- CONTENT -->

    <section v-if="firstRate == null" class="content">
      <img src="../../../assets/smile.svg" alt="">
      <div class="txt">
        Como você avalia nosso atendimento?

      </div>
      <div class="divspaces">
        <span @click="selectStar(1)" @mouseover="mouseOverStar(1)" @mouseleave="mouseLeaveStar">
          <i :class="iconstar1"></i>
        </span>
        <span @click="selectStar(2)" @mouseover="mouseOverStar(2)" @mouseleave="mouseLeaveStar">
          <i :class="iconstar2"></i>
        </span>
        <span @click="selectStar(3)" @mouseover="mouseOverStar(3)" @mouseleave="mouseLeaveStar">
          <i :class="iconstar3"></i>
        </span>
        <span @click="selectStar(4)" @mouseover="mouseOverStar(4)" @mouseleave="mouseLeaveStar">
          <i :class="iconstar4"></i>
        </span>
        <span @click="selectStar(5)" @mouseover="mouseOverStar(5)" @mouseleave="mouseLeaveStar">
          <i :class="iconstar5"></i>
        </span>

      </div>

      <div class="back" @click="gotoHome">Avaliar mais tarde</div>
    </section>

    <section v-if="firstRate != null && secondRate == null" class="content">
      <img src="../../../assets/smile.svg" alt="">
      <div class="txt">
        Em uma escala de 0 a 10, o quanto você indicaria a nossa empresa a um amigo ou colega?
      </div>
      <div class="scale">
        <div class="sbtn" v-for="rate in rates" :key="rate.rate" @click="selectRate(rate.rate)">{{ rate.rate }}</div>
      </div>

      <div class="back" @click="gotoHome">Avaliar mais tarde</div>
    </section>

    <section v-if="secondRate != null" class="content">

      <img src="../../../assets/smile.svg" alt="">
      <div class="txt divspaces">
        Obrigado!
      </div>
      <!-- <div class="divspaces">
        Gostaria de nos avaliar na App Store também?
      </div>

      <div class="divspaces">
        <button class="pl-btn btn primary">OK, claro</button>
      </div> -->

      <!-- <div class="back" @click="gotoHome">Avaliar mais tarde</div> -->
    </section>

  </section>
</template>

<script>
import { ServicoHttp } from "@/axios/servico-http";
import { ServicoAll } from "@/axios/servico-all";
import MovementLogs from "@/helpers/movement_logs";
import moment from 'moment'

import PatientHeader from '../components/header/PatientHeader'

export default {
  name: 'PatientNPS',
  components: {
    PatientHeader
  },

  data() {
    return {
      qsSystemFrom: null,
      iconstar1: 'far fa-star',
      iconstar2: 'far fa-star',
      iconstar3: 'far fa-star',
      iconstar4: 'far fa-star',
      iconstar5: 'far fa-star',

      firstRate: null,

      rates: [
        { rate: 0 },
        { rate: 1 },
        { rate: 2 },
        { rate: 3 },
        { rate: 4 },
        { rate: 5 },
        { rate: 6 },
        { rate: 7 },
        { rate: 8 },
        { rate: 9 },
        { rate: 10 },
      ],
      secondRate: null,

    }
  },

  computed: {
    isDifferentSystemaPA () {
      const from = process.env.VUE_APP_SYSTEM_FROM_PA
      const to = process.env.VUE_APP_SYSTEM_TO_PA
      return from.toUpperCase() !== to.toUpperCase()
    },
    objSystemFromTo () {
      const from = process.env.VUE_APP_SYSTEM_FROM_PA
      const to = process.env.VUE_APP_SYSTEM_TO_PA
      return {
        systemFrom: from.toUpperCase(),
        systemTo: to.toUpperCase()
      }
    }
  },

  async mounted() {
    this.servicoHttp = new ServicoHttp(this);
    this.sPepNpsSave = new ServicoAll(
      "/api/pep/nps/save",
      this.servicoHttp
    );
    this.qsSystemFrom = this.$route.query.from || '';

    // ! Firebase LOG
    const pac = await this.$store.dispatch("getLocalStoragePatient");
    await Firebase.updatePatientSpecificKey(pac.pac_id, "comando", null);
    const objLog = { ...pac }
    const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
    MovementLogs.saveLog(1800, timeAction, objLog)
  },

  methods: {
    async gotoHome() {
      if (this.isDifferentSystemaPA || this.qsSystemFrom.toUpperCase() === 'AXIA') {
        if (this.objSystemFromTo.systemFrom.toUpperCase() === 'AXIA' || this.qsSystemFrom.toUpperCase() === 'AXIA') {
          const url = `${process.env.VUE_APP_AXIA_PACIENTE}/portal-paciente`;
          window.location.href = url;
        } else {
          console.error('[PatientNps]', 'sistemas diferentes, mas nao medicar - tem q voltar para axia', this.objSystemFromTo)
        }
        return
      }
      // ! Firebase LOG
      const pac = await this.$store.dispatch("getLocalStoragePatient");
      const objLog = { ...pac }
      const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
      MovementLogs.saveLog(1802, timeAction, objLog)

      this.$router.push({ name: 'pacienteHome' })
    },

    async onClickSuport() {
      // ! Firebase LOG
      const pac = await this.$store.dispatch("getLocalStoragePatient");
      const objLog = { ...pac }
      const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
      MovementLogs.saveLog(1600, timeAction, objLog)

      const numWhatsApp = `+${process.env.VUE_APP_WHATSAPP_NUMBER}`;
      const numWhatsAppReplace = numWhatsApp.replace(/\s+/g, "")
      const urlWhatsApp = `https://wa.me/${numWhatsAppReplace}`;
      window.open(urlWhatsApp, "_blank");
    },

    mouseOverStar: function (value) {
      if (value == 1) {
        this.iconstar1 = 'fas fa-star'
        this.iconstar2 = 'far fa-star'
        this.iconstar3 = 'far fa-star'
        this.iconstar4 = 'far fa-star'
        this.iconstar5 = 'far fa-star'
      }

      if (value == 2) {
        this.iconstar1 = 'fas fa-star'
        this.iconstar2 = 'fas fa-star'
        this.iconstar3 = 'far fa-star'
        this.iconstar4 = 'far fa-star'
        this.iconstar5 = 'far fa-star'
      }

      if (value == 3) {
        this.iconstar1 = 'fas fa-star'
        this.iconstar2 = 'fas fa-star'
        this.iconstar3 = 'fas fa-star'
        this.iconstar4 = 'far fa-star'
        this.iconstar5 = 'far fa-star'
      }

      if (value == 4) {
        this.iconstar1 = 'fas fa-star'
        this.iconstar2 = 'fas fa-star'
        this.iconstar3 = 'fas fa-star'
        this.iconstar4 = 'fas fa-star'
        this.iconstar5 = 'far fa-star'
      }

      if (value == 5) {
        this.iconstar1 = 'fas fa-star'
        this.iconstar2 = 'fas fa-star'
        this.iconstar3 = 'fas fa-star'
        this.iconstar4 = 'fas fa-star'
        this.iconstar5 = 'fas fa-star'
      }

    },

    mouseLeaveStar: function () {
      this.iconstar1 = 'far fa-star'
      this.iconstar2 = 'far fa-star'
      this.iconstar3 = 'far fa-star'
      this.iconstar4 = 'far fa-star'
      this.iconstar5 = 'far fa-star'
    },

    selectStar(value) {
      this.firstRate = value;
    },

    async selectRate(value) {
      this.secondRate = value;

      const pac = await this.$store.dispatch("getLocalStoragePatient");
      const ateId = this.$route.query.video || null
      // console.log(pac);
      const obj = {
        nps_id_atendimento: ateId,
        nps_id_paciente: pac.pac_id,
        nps_atendimento_nota: this.firstRate,
        nps_indicacao_nota: this.secondRate,
      };

      // console.log(obj)
      const ret = await this.sPepNpsSave.post(obj);
      // ! Firebase LOG
      const objLog = { ...pac, ...obj }
      const timeAction = moment().format('YYYY-MM-DD HH:mm:ss')
      MovementLogs.saveLog(1801, timeAction, objLog)

      if (ret.data.status === true) {
        this.msg = "Informações registradas com sucesso";
        this.$toast.success(this.msg);
        this.patient = {
          ...obj,
        };
        setTimeout (() => {
          this.gotoHome()
        }, 2 * 1000)
      } else {
        this.msg = "Não foi possível atualizar informações";
      }
    }
  }
}
</script>

