<template>
  <svg
    width="35"
    height="38"
    viewBox="0 0 35 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3177 0.705566C16.6855 0.768572 16.5537 0.688383 16.7522 0.871264L33.2414 23.6876C34.8339 25.8891 35.2692 28.5562 34.5514 31.1517C33.9982 33.1516 32.5823 35.072 30.7272 36.3305C26.4904 39.2042 20.7132 38.2624 17.767 34.1858L1.62103 11.8453C0.556456 10.3721 0.146843 8.58335 0.469044 6.81305C0.791245 5.04316 1.8081 3.49665 3.32747 2.46646C6.46713 0.336532 10.8094 1.07828 13.0074 4.11975C13.0826 4.22326 15.615 7.72704 19.7918 13.5064C21.2761 15.5606 22.8668 17.7609 24.4571 19.9616C25.3583 21.2087 25.3583 21.2087 25.9943 22.0887C26.4714 22.749 26.4714 22.749 26.5774 22.8955C28.1698 25.0979 27.9237 27.7249 25.7937 29.1691C24.7658 29.8663 23.6561 30.0643 22.3293 29.8376C21.0531 29.6196 19.9387 28.9314 19.1959 27.9028L8.4548 12.9831C8.32136 12.7978 8.36824 12.5425 8.55953 12.4132L10.4264 11.1523C10.6177 11.023 10.8808 11.0684 11.0142 11.2537L21.7533 26.1714C22.0185 26.5384 22.4146 26.7826 22.8711 26.8604C23.4069 26.952 23.6747 26.916 24.0036 26.693C24.6742 26.2377 24.6649 25.5197 24.0218 24.6298C23.9158 24.4833 23.9158 24.4833 23.4386 23.823C22.8022 22.943 22.8022 22.943 21.9011 21.6959C20.3108 19.4952 18.7205 17.2949 17.2361 15.2411C13.0589 9.46134 10.5269 5.95756 10.4526 5.85405C9.86522 5.04111 8.98857 4.50024 7.97974 4.328C6.97175 4.15575 5.95659 4.37382 5.11794 4.94292C4.28013 5.5112 3.72145 6.36178 3.54283 7.33837C3.36505 8.31537 3.59012 9.29851 4.1771 10.111L20.3226 32.452C22.2799 35.1592 26.1074 35.7733 28.9367 33.854C31.801 31.9111 32.6203 28.0972 30.6858 25.4219L14.1961 2.60556C14.0627 2.42023 14.1087 2.16534 14.3 2.03565L16.164 0.770618L16.3177 0.705566Z"
      :fill="svgColor"
      fill-opacity="0.79"
    />
  </svg>
</template>

<script>
export default {
  name: "IconAttachment",
  components: {},
  data: () => {
    return {
    };
  },
  mounted() {},
  methods: {},
};
</script>
