<style lang="scss" src="./InputFile.scss" scoped></style>

<template>
  <section class="input-file-document" v-if="!multipleMax">
    <label for="idInputFile" class="input-document">
      <!-- <img v-if="base64" class="document-image" :src="base64" /> -->
      <div v-if="!base64" class="icon-attachment">
        <IconAttachment class="icon" />
      </div>
      <span v-if="!base64"> Adicionar anexo </span>
      <span v-else> Arquivo anexado </span>
    </label>

    <input
      id="idInputFile"
      class="input"
      type="file"
      accept="image/jpeg, image/png, application/pdf"
      :disabled="isDisabled || disabledFile"
      @change="previewFile"
    />
  </section>

  <section v-else class="multiple-input-file">
    <div
      class="input-file mr"
      v-for="item in boxToMultipleFiles"
      :key="item.key"
    >
      <div class="box-img" v-if="aBase64[item.index]">
        <div v-if="isBase64Pdf(aBase64[item.index])" class="is-pdf">
          <IconPdf/>
        </div>
        <img v-else class="img" :src="aBase64[item.index]" />
        <label
          v-if="aBase64[item.index]"
          class="pencil"
          @click="clickMultRemove(item.index)"
        >
          <i class="fas fa-trash" />
        </label>
      </div>

      <div v-else class="empty-image">
        <label v-if="!aBase64[item.index]" :for="item.inputId">
          <div class="info">
            <div class="icon-attachment"><IconAttachment class="icon" /></div>
            <span @click="clickSetSelectIdx(item.index)">
              Clique aqui para adicionar um arquivo.<br />(Exame, documentos e
              etc)
            </span>
          </div>
        </label>
      </div>

      <input
        :id="item.inputId"
        class="input"
        type="file"
        accept="image/jpeg, image/png, application/pdf"
        :disabled="isDisabled || disabledFile"
        @change="previewMultipleFile"
      />
    </div>
  </section>
</template>

<script>
import IconAttachment from "@/assets/icons/IconAttachment";
import IconPdf from "@/assets/icons/IconPdf";
export default {
  name: "InputFile",
  components: { IconAttachment, IconPdf },
  props: {
    fileBase64: String,
    filesBase64: Array,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    multipleMax: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      file: null,
      base64: null,
      selectIdx: null,
      aBase64: {},
      inputFile: null,
      disabledFile: false,
      fileName: null,
    };
  },

  computed: {
    multipleFileSize() {
      return this.filesBase64.length;
    },
    boxToMultipleFiles() {
      const size =
        this.multipleFileSize < this.multipleMax
          ? this.multipleFileSize + 1
          : this.multipleMax;

      return Array.from({ length: size }).map((_, index) => ({
        index,
        key: `if-${index}`,
        inputId: `idInputFile_${index}`,
      }));
    },
  },

  watch: {
    fileBase64(val) {
      this.base64 = val;
    },
    filesBase64(val) {
      this.setABase64(val);
    },
  },

  methods: {
    clickSetSelectIdx(idx) {
      this.disabledFile = false;
      this.selectIdx = idx;
    },

    async previewMultipleFile(fileTmp) {
      const idx = this.selectIdx;
      let file = fileTmp.target.files;
      if (file.length > 0) {
        file = file[0];
        this.file = file;
        this.fileToBase64(
          file,
          (res) => {
            this.aBase64[idx] = res;
            this.selectIdx = null;
            this.$emit("change", this.aBase64, file);
            this.$forceUpdate();
          },
          (err) => {
            console.log("ERRO", err);
          }
        );
      }
    },

    async previewFile(fileTmp) {
      let file = fileTmp.target.files;
      this.base64 = true
      if (file && file.length > 0) {
        file = file[0];
        this.file = file;
        this.fileToBase64(
          file,
          (res) => {
            this.base64 = res;
            this.$emit("change", this.base64, file);
          },
          (err) => {
            console.log("ERRO", err);
          }
        );
      }
    },

    fileToBase64(file, resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    },

    setABase64(val) {
      if (!val || val.length <= 0) {
        this.aBase64 = [];
      }

      this.aBase64 = [];

      for (let idx in val) {
        if (val[idx].url) {
          this.aBase64[Number(idx)] = val[idx].url;
        } else if (val[idx].base64) {
          this.aBase64[Number(idx)] = val[idx].base64;
        } else {
          console.error(
            "[InputFile] imagem nao contem key url ou base64",
            val[idx]
          );
        }
      }

      if (!val || val.length <= 0) {
        this.aBase64 = [];
      }

      this.$forceUpdate();
    },

    isBase64Pdf(encodedData) {
      const isPdf = encodedData.includes('data:application/pdf');
      return isPdf;
    },

    clickMultRemove(idx) {
      const input = document.getElementById(`idInputFile_${idx}`);

      if (input) {
        input.value = "";
      }

      this.disabledFile = true

      this.$emit("remove", { file: this.filesBase64[idx], idx });
    },

    clear () {
      this.file = null
      this.base64 = null
      this.selectIdx = null
      this.aBase64 = {}
      this.inputFile = null
      this.disabledFile = false
      this.fileName = null
    }
  },
};
</script>
