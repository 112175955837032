import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue, get } from "firebase/database";

let FirebaseDB = null
// const urlProfissionalSala = `videoconferencia/profissional/sala`
const urlPacienteSala = `videoconferencia/paciente/sala`
const urlStatusRoom = `videoconferencia/status/sala/paciente`

const setFirebaseConfig = async (QSenv) => {
  let qsTmp = QSenv
  if (qsTmp && isNaN(qsTmp)) {
    qsTmp = QSenv.toUpperCase()
  }
  let config = {}
  switch (qsTmp) {
    case 'A':
      config = {
        apiKey: process.env.VUE_APP_A_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_A_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_A_FIREBASE_DATABASE_URL,
        projectId: process.env.VUE_APP_A_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_A_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_A_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_A_FIREBASE_APP_ID
      }
      break
    case 'B':
      config = {
        apiKey: process.env.VUE_APP_B_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_B_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_B_FIREBASE_DATABASE_URL,
        projectId: process.env.VUE_APP_B_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_B_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_B_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_B_FIREBASE_APP_ID
      }
      break
    default:
      config = {
        apiKey: process.env.VUE_APP_A_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_A_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_A_FIREBASE_DATABASE_URL,
        projectId: process.env.VUE_APP_A_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_A_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_A_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_A_FIREBASE_APP_ID
      }
  }
  const FirebaseApp = await initializeApp(config)
  FirebaseDB = await getDatabase(FirebaseApp)
}

const setFirebaseRoom = (uid, channel, token) => {
  const path = `users/${uid}/telessaude/sala/${uid}`
  set(ref(FirebaseDB, path), { uid: uid, channel, token });
}

const removeFirebaseRoom = (uid) => {
  const path = `users/${uid}/telessaude/sala/${uid}`
  set(ref(FirebaseDB, path), { uid: null, channel: null, token: null });
}

const onCallRoom = (id, call) => {
  const path = `videoconferencia/sala/${id}`
  onValue(ref(FirebaseDB, path), (snapshot) => {
    const data = snapshot.val();
    call(data)
  });
}

const removeCallRoom = (id) => {
  const path = `videoconferencia/sala/${id}`
  set(ref(FirebaseDB, path), { cpf: null });
}

const onPatientRoom = (pacId, call) => {
  const path = `${urlPacienteSala}/${pacId}`
  onValue(ref(FirebaseDB, path), (snapshot) => {
    console.log('onPatientRoom - snap', snapshot)
    const data = snapshot.val()
    call(data)
  })
}

const updateStatusRoom = (pacId, status) => {
  const path = `${urlStatusRoom}/${pacId}`
  set(ref(FirebaseDB, path), { status: status, pacId });
}

const onceStatusRoomByPacId = (pacId, call) => {
  const path = `${urlStatusRoom}/${pacId}`
  get(ref(FirebaseDB, path))
    .then((snapshot) => {
      const data = snapshot.val()
      call(data)
    })
    .catch((e) => {
      console.error('[onceStatusRoomByPacId] Error:', e)
      call({ status: 'erro' })
    })
}

export default {
  setFirebaseRoom,
  removeFirebaseRoom,
  onCallRoom,
  removeCallRoom,
  setFirebaseConfig,
  onPatientRoom,
  updateStatusRoom,
  onceStatusRoomByPacId
}