var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"text-input input--wrapper"},[(_vm.label)?_c('label',[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"is--required"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"input--content"},[(_vm.hasPrependSlot)?_c('div',{staticClass:"input--slot is--prepend"},[_vm._t("prepend")],2):_vm._e(),_c(_vm.tag,_vm._b({ref:"editable",tag:"component",class:[
        'input',
        {
          'is--append': _vm.hasAppendSlot,
          'is--prepend': _vm.hasPrependSlot,
          disabled: _vm.disabled
        }
      ],style:({ textTransform: _vm.textTransform }),attrs:{"contenteditable":!_vm.disabled,"placeholder":_vm.placeholder},on:{"input":_vm.onUpdate,"blur":_vm.onUpdate,"keypress":_vm.onKeypress,"paste":_vm.onPaste}},'component',_vm.$attrs,false)),(_vm.hasAppendSlot)?_c('div',{staticClass:"input--slot is--append"},[_vm._t("append")],2):_vm._e(),(_vm.errorMsg)?_c('i',{staticClass:"input--warning fas fa-exclamation-circle",attrs:{"title":_vm.errorMsg}}):_vm._e()],1),(_vm.helptext)?_c('span',{staticClass:"helptext"},[_vm._v(_vm._s(_vm.helptext))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }