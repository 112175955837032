class ServicoAll {
  constructor (url, servicoHttp) {
    this.url = url
    this.servicoHttp = servicoHttp
  }

  async get (filter) {
    const list = await this.servicoHttp.get({
      url: this.url,
      params: filter
    })

    return list.data
  }

  async post (body) {
    const respLogin = await this.servicoHttp.post({
      url: this.url,
      body: body
    })
    if (respLogin.data) {
      return {
        data: respLogin.data,
        status: respLogin.status
      }
    }
    return respLogin
  }
  
  async uploadFile (body) {
    const respLogin = await this.servicoHttp.uploadFile({
      url: this.url,
      body: body
    })
    if (respLogin.data) {
      return {
        data: respLogin.data,
        status: respLogin.status
      }
    }
    return respLogin
  }
}

export { ServicoAll }
