<template>

<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.10008e-05 6.35843V14.9326C4.10008e-05 15.1962 0.104805 15.4489 0.290965 15.6352C0.477323 15.8216 0.730224 15.9263 0.993598 15.9263H4.72152L12.1032 21.1133H12.103C12.3027 21.2524 12.5463 21.3132 12.7879 21.2846C13.0293 21.2559 13.2519 21.1399 13.4135 20.9581C13.5751 20.7763 13.6644 20.5416 13.6646 20.2984V0.992321C13.6642 0.748729 13.5745 0.513852 13.4123 0.332224C13.2503 0.150424 13.0271 0.0345671 12.7853 0.00664233C12.5433 -0.0214797 12.2995 0.0403091 12.1003 0.180326L4.71856 5.36731L0.993577 5.36711C0.730578 5.36711 0.478276 5.47148 0.292111 5.65724C0.105753 5.84301 0.000790899 6.09491 0 6.3581L4.10008e-05 6.35843Z" fill="#6C6C6C"/>
<path d="M20.8878 6.73056L18.378 9.24035L15.8653 6.73056L14.46 8.13588L16.9725 10.6457L14.46 13.1554L15.8653 14.5608L18.378 12.051L20.8878 14.5608L22.2931 13.1554L19.7833 10.6457L22.2931 8.13588L20.8878 6.73056Z" fill="#6C6C6C"/>
</svg>

</template>

<script>
export default {
  name: 'IconBtnSoundOff',
  components: {
  },

  data: () => {
    return {
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>
