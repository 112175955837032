<template>
  <div class="taggable">
    <Combobox
      ref="combobox"
      :placeholder="placeholder"
      :label="label"
      @change="changeInfo"
      v-model="info"
      :options="items"
      :taggable="taggable"
      :multiple="multiple"
      :createOption="createOption"
      :required="required"
      :shouldSkipValidation="shouldSkipValidation"
      :disabled="isDisabled"
    />
  </div>
</template>
<script>
import Combobox from "@/components/combobox/Combobox";

export default {
  name: 'Taggable',
  components: {
    Combobox
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Combo'
    },
    taggable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    createOption: {
      type: Function,
      default: () => {}
    },
    main: {
      type: Number,
      default: 0
    },
    required: {
      type: Boolean,
      default: false
    },
    shouldSkipValidation: {
      type: Boolean,
      default: false
    },
    isDisabled: Boolean,
    list: null,
    value: null
  },
  data: () => {
    return {
      info: null,
      items: [],
      mainValue: null
    }
  },
  mounted () {
    this.items = this.list
    this.mainValue = this.main
  },
  watch: {
    info: function () {
      this.changeInfo()
    },
    value: function () {
      this.info = this.value
    },
    list: function () {
      this.items = this.list
    }
  },
  methods: {
    changeInfo () {
      this.$emit('changeInfo', this.info, this.mainValue)
    },
    reset () {
      this.$refs.combobox.reset()
    },
    resetValidation () {
      this.$refs.combobox.resetValidation()
    }
  }
}
</script>
<style lang="scss" scoped>
.taggable {
  width: 100%;

  ::v-deep .v-select .dropdown-toggle {
    height: rem(45px);
    border: 1px solid 3;
  }

  ::v-deep .v-select .open-indicator:before {
    border-color:#7C7171;
  }
}
</style>
