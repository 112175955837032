<style src="./TextInput.scss" lang="scss"></style>

<template lang="html">
  <section class="text-input input--wrapper">
    <label v-if="label">
      {{ label }} <span class="is--required" v-if="required">*</span>
    </label>

    <div class="input--content">
      <div v-if="hasPrependSlot" class="input--slot is--prepend">
        <slot name="prepend" />
      </div>

      <input v-if="mask"
        autocomplete="off"
        :class="{
          'is--append': hasAppendSlot,
          'is--prepend': hasPrependSlot,
          'is--disabled': disabled
        }"
        :type="type"
        :disabled="disabled"
        :maxlength="maxlength"
        :placeholder="placeholder"
        v-mask="mask"
        v-on="listeners"
        v-bind="$attrs"
        v-model="innerValue"
      />
      <input v-else
        autocomplete="off"
        :class="{
          'is--append': hasAppendSlot,
          'is--prepend': hasPrependSlot,
          'is--disabled': disabled
        }"
        :type="type"
        :disabled="disabled"
        :maxlength="maxlength"
        :placeholder="placeholder"
        v-on="listeners"
        v-bind="$attrs"
        v-model="innerValue"
      />

      <div v-if="hasAppendSlot" class="input--slot is--append">
        <slot name="append" />
      </div>

      <i
        v-if="errorMsg"
        :title="errorMsg"
        class="input--warning fas fa-exclamation-circle"
      />
    </div>
    <span class="helptext" v-if="helptext">{{ helptext }}</span>
  </section>
</template>

<script>
import { mask } from 'vue-the-mask'
import InputMixin from '../InputMixin'

export default {
  name: 'TextInput',
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'text'
    },
    mask: {
      type: String,
      default: null
    },
  },
  directives: { mask },
  mixins: [InputMixin]
}
</script>
