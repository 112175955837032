<style src="./SelectInput.scss" lang="scss"></style>

<template lang="html">
  <section
    class="select-input input--wrapper"
    v-bind:style="{ minWidth: minWidth + 'px' }"
  >
    <label v-if="label">
      {{ label }} <span class="is--required" v-if="required">*</span>
    </label>

    <div class="input--content">
      <div
        :class="[
          'select-input__multiselect',
          {
            'has--prepend': hasPrepend,
            'has--append': hasAppend,
          },
        ]"
      >
        <div v-if="hasPrepend" class="input--slot is--prepend">
          <slot name="prepend" />
        </div>

        <!-- NOT GROUP -->
        <template v-if="!group">
          <multiselect
            v-if="single"
            :show-labels="showLabels"
            v-model="selected"
            :placeholder="placeholder"
            :disabled="isDisabled"
            :loading="!hasOptions"
            :multiple="multiple"
            :options="dataOptions"
            :max-height="maxHeight"
            :open-direction="openDirection"
            :preselect-first="preselectFirst"
          >
            <slot name="noOptions" slot="noOptions"> Sem opções </slot>
            <slot name="noResult" slot="noResult">
              <div @click="onClickNoResult">
                {{ txtNoResult || "Sem resultados" }}
              </div>
            </slot>
          </multiselect>

          <multiselect
            v-else
            v-model="selected"
            :show-labels="showLabels"
            track-by="name"
            label="name"
            :placeholder="placeholder"
            :disabled="isDisabled"
            :loading="!hasOptions"
            :multiple="multiple"
            :options="dataOptions"
            :max-height="maxHeight"
            :open-direction="openDirection"
            :preselect-first="preselectFirst"
            class="select"
          >
            <slot name="noOptions" slot="noOptions"> Sem opções </slot>
            <slot name="noResult" slot="noResult">
              <div @click="onClickNoResult">
                {{ txtNoResult || "Sem resultados" }}
              </div>
            </slot>

            <template slot="singleLabel" slot-scope="props">
              <span :title="props.option.name">{{ props.option.name }}</span>
            </template>

            <template slot="option" slot-scope="props">
              <span :title="props.option.name">{{ props.option.name }}</span>
            </template>
          </multiselect>
        </template>

        <!-- GROUP -->
        <multiselect
          v-else
          :show-labels="showLabels"
          v-model="selected"
          :placeholder="placeholder"
          :disabled="isDisabled"
          :loading="!hasOptions"
          :options="dataOptions"
          group-values="values"
          track-by="name"
          label="name"
          group-label="title"
          :multiple="multiple"
          :max-height="maxHeight"
          :preselect-first="preselectFirst"
          :group-select="false"
          class="select"
        >
          <slot name="noOptions" slot="noOptions">Sem opções</slot>
          <slot name="noResult" slot="noResult">Sem resultados</slot>
        </multiselect>

        <div v-if="hasAppend" class="input--slot is--append">
          <slot name="append" />
        </div>
      </div>

      <i
        v-if="errorMsg"
        :title="errorMsg"
        class="input--warning fas fa-exclamation-circle"
      />
    </div>
  </section>
</template>

<script>
import { size } from "lodash";
import Multiselect from "vue-multiselect";

export default {
  name: "SelectInput",
  components: { Multiselect },
  props: {
    txtNoResult: {
      type: String,
      default: null,
    },
    /**
     * Define label of component
     * @type {String}
     */
    label: String,
    /**
     * Add select multiple.
     * @default false
     * @type {Boolean}
     */
    multiple: {
      type: Boolean,
      default: false,
    },
    /**
     * Add select is required.
     * @default false
     * @type {Boolean}
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Array of available options: Objects, Strings or Integers.
     * If array of objects, visible label will default to option.label.
     * If `labal` prop is passed, label will equal option['label']
     * @type {Array|String}
     */
    options: {
      type: [Array, String],
      required: true,
    },
    /**
     * Presets the selected options value.
     * @type {Object||Array||String||Integer}
     */
    value: {
      type: null,
      default() {
        return [];
      },
    },
    /**
     * Equivalent to the `placeholder` attribute on a `<select>` input.
     * @default 'Select option'
     * @type {String}
     */
    placeholder: {
      type: String,
      default() {
        return "";
      },
    },
    /**
     * Disables the multiselect if true.
     * @default false
     * @type {Boolean}
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Define if labels is to show.
     * @default false
     * @type {Boolean}
     */
    showLabels: {
      type: Boolean,
      default: false,
    },
    /**
     * Define if options is of single elements.
     * @default false
     * @type {Boolean}
     */
    single: {
      type: Boolean,
      default: false,
    },
    /**
     * Define if options is of single elements.
     * @default false
     * @type {Boolean}
     */
    group: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: "",
    },
    minWidth: {
      type: Number,
      default: 0,
    },
    /**
     * forces the multiselect to always open below.
     * Use top or above to always open above.
     * By default the multiselect will open whereever there is more space once there is not enough space below to open at maxHeight.
     * @default 'top'
     * @type {String}
     */
    openDirection: {
      type: String,
      default: "",
    },

    /**
     * Sets maxHeight style value of the dropdown
     * @default 300
     * @type {Integer}
     */
    maxHeight: {
      type: Number,
      default: 300,
    },
    preselectFirst: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    dataOptions() {
      return Array.isArray(this.options) ? this.options : [];
    },
    isDisabled() {
      return !!this.disabled;
    },
    hasOptions() {
      if (this.disabled) {
        return true;
      } else {
        return Array.isArray(this.options) ? size(this.options) : true;
      }
    },
    hasPrepend() {
      return !!this.$slots["prepend"];
    },
    hasAppend() {
      return !!this.$slots["append"];
    },
  },
  watch: {
    /**
     * When v-model is changed:
     *   1. Set the selected option.
     *   2. If it's invalid, validate again.
     */
    value(value) {
      this.selected = value;
    },
    /**
     * When selected:
     *   1. Emit input event to update the user v-model.
     *   2. If it's invalid, validate again.
     */
    selected(value) {
      this.$emit("input", value);
    },

    options(value) {
      if (this.preselectFirst && value && value.length === 1) {
        this.selected = value[0];
      }
    },
  },
  methods: {
    onClickNoResult() {
      this.$emit("clickNoResult");
    },
  },
};
</script>
