export const html = `
<div>
<p><h2><b>TERMOS E CONDIÇÕES DE USO – AXIA</b></h2>

</p><p>TERMO DE ACEITE PARA TRATAMENTO DE DADOS PESSOAIS E DADOS PESSOAIS SENSÍVEIS

</p><p>ESTE DOCUMENTO VISA REGISTRAR A MANIFESTAÇÃO LIVRE, INFORMADA E INEQUÍVOCA PELA QUAL O USUÁRIO CONCORDA COM O TRATAMENTO DE  SEUS DADOS PESSOAIS E PESSOAIS SENSÍVEIS PARA AS FINALIDADES ESPECÍFICAS, EM CONFORMIDADE COM A LEI Nº 13.709 – LEI GERAL DE PROTEÇÃO DE DADOS PESSOAIS (LGPD).

</p><p>Ao aceitar o presente termo, o usuário consente e concorda que a empresa AXIA SERVIÇOS LTDA, de denominação fantasia AXIA SAÚDE, pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 53.460.617/0001-56, com sede situada à Rua Silva Jardim, n° 83,  Bela Vista, Rio Bonito-RJ, CEP 28.800-000, - doravante denominada CONTROLADORA, tome   decisões referentes ao tratamento de seus dados pessoais e pessoais sensíveis, bem como realize o tratamento de seus dados, envolvendo operações como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.

</p><p>

</p><p>DADOS PESSOAIS:

</p><p>A CONTROLADORA fica autorizada a tomar decisões referentes ao tratamento e a realizar o tratamento dos seguintes dados pessoais e dados pessoais sensíveis do TITULAR:
</p><p>•	Nome completo;
</p><p>•	Data de nascimento;
</p><p>•	Nome de sua genitora;
</p><p>•	Número do Cadastro de Pessoas Físicas (CPF) ou cadastro de Pessoas

</p><p>•	Jurídicas (CNPJ);
</p><p>•	Endereço pessoal/sede e endereço de e-mail;
</p><p>•	Telefones de contato;
</p><p>•	Histórico médico completo;
</p><p>•	Antecedentes clínicos e cirúrgicos;
</p><p>•	Hospitalizações;
</p><p>•	Hábitos sociais;
</p><p>•	Hábitos alimentares;
</p><p>•	Hábitos sexuais;
</p><p>•	Uso de álcool e tabaco;
</p><p>•	Prática de Atividades físicas;
</p><p>•	Uso de medicamentos;
</p><p>•	Informações sobre doenças pré-existentes do TITULAR;
</p><p>•	Informações sobre exames realizados;
</p><p>•	Histórico de doenças familiares;
</p><p>•	Histórico profissional;

</p><p>

</p><p>FINALIDADES DO TRATAMENTO DOS DADOS:

</p><p>O tratamento dos dados listados neste Termo possui as seguintes finalidades:
</p><p>•	Possibilitar que a CONTROLADORA identifique e entre em contato com o TITULAR para fins de relacionamento, aqui entendido como comercial/vendas e pós-vendas;
</p><p>•	Permitir que a CONTROLADORA identifique e entre em contato com o TITULAR, em razão da contratação dos serviços de atenção à saúde disponibilizados por ela;
</p><p>•	Para cumprimento, pela CONTROLADORA, de obrigações impostas por órgãos de fiscalização;
</p><p>•	Quando necessário para executar um contrato, no qual seja parte o TITULAR;

</p><p>•	Para o exercício regular de direitos em processo judicial, administrativo ou arbitral quando necessário;
</p><p>•	Possibilitar a identificação do TITULAR para a tutela da saúde, exclusivamente, em procedimento realizado por profissionais de saúde, serviços de saúde ou autoridade sanitária;
</p><p>•	Permitir a criação de um cadastro personalizado para o TITULAR dos dados, contendo todo seu histórico médico e inclinações para possíveis doenças com base no histórico familiar, possibilitando um serviço de atenção à saúde primária exclusiva para o TITULAR;
</p><p>•	Quando necessário, para atender aos interesses legítimos da CONTROLADORA ou de terceiros, exceto no caso de prevalecerem direitos e liberdades fundamentais do TITULAR que exijam a proteção dos dados pessoais;
</p><p>•	Permitir que a CONTROLADORA utilize esses dados para a contratação e prestação de serviços diversos dos inicialmente ajustados, desde que o TITULAR também demonstre interesse em contratar novos serviços;
</p><p>•	Permitir que a CONTROLADORA utilize esses dados para a confecção de cadastros juntos aos seus sistemas integrados, para a criação de login e senha para acesso do TITULAR aos seus serviços.
</p><p>•	Permitir que a CONTROLADORA, juntamente com sua equipe multidisciplinar de profissionais de saúde, utilize as informações disponibilizadas nas consultas para desenvolvimento de um serviço personalizado e integrado de assistência à saúde, preservando sempre que possível o anonimato das informações.
</p><p>•	Permitir a criação de um banco de dados para análise e projeções em proveito da sociedade empresária, priorizando a anonimização dos dados e a segurança com o manuseio e o armazenamento dessas informações através de softwares com criptografia e limitação de acesso.
</p><p>Em caso de alteração na finalidade, que esteja em desacordo com o consentimento original, a CONTROLADORA deverá comunicar o TITULAR, que poderá revogar o consentimento, conforme previsto no §2º do artigo 9º da Lei n° 13.709/2018.

</p><p>

</p><p>COMPARTILHAMENTO DE DADOS:

</p><p>A CONTROLADORA fica autorizada a compartilhar os dados pessoais do TITULAR com outros agentes de tratamento de dados, caso seja necessário, para as finalidades listadas neste termo, observados os princípios e as garantias estabelecidas pela   Lei nº 13.709.
Caso seja necessário o compartilhamento de dados com terceiros que não tenham sido relacionados nesse termo ou qualquer alteração contratual posterior, será ajustado novo termo de consentimento para este fim (§ 6º do artigo 8° da Lei n° 13.709/2018).

</p><p>

</p><p>SEGURANÇA DOS DADOS:

</p><p>A CONTROLADORA responsabiliza-se pela manutenção de medidas de segurança, técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.
</p><p>Em conformidade ao art. 48 da Lei nº 13.709, a CONTROLADORA comunicará ao TITULAR e à Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de segurança que possa acarretar risco ou dano relevante ao TITULAR.

</p><p>

</p><p>TÉRMINO DO TRATAMENTO DOS DADOS:

</p><p>A CONTROLADORA poderá manter e tratar os dados pessoais do TITULAR durante todo o período em que os dados forem pertinentes ao alcance das finalidades listadas neste Termo. Dados pessoais anonimizados, sem possibilidade de associação ao indivíduo, poderão ser mantidos por período indefinido.

</p><p>O TITULAR poderá solicitar a CONTROLADORA, via e-mail contato@axiasaude.com e  correspondência , a qualquer momento, que sejam eliminados os seus dados pessoais não anonimizados.
</p><p>DIREITOS DO TITULAR:
</p><p>O TITULAR tem direito a obter da CONTROLADORA, em relação aos dados por ele tratados, a qualquer momento e mediante requisição:
</p><p>•	Confirmação da existência de tratamento;
</p><p>•	Acesso aos dados;
</p><p>•	Correção de dados incompletos, inexatos ou desatualizados;
</p><p>•	Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei nº 13.709/2018;
</p><p>•	Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial;
</p><p>•	Eliminação dos dados pessoais tratados com o consentimento do TITULAR, exceto nas hipóteses previstas no art. 16 da Lei nº 13.709;
</p><p>•	Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;
</p><p>•	Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;
</p><p>•	Revogação do consentimento, nos termos do § 5º do art. 8º da Lei nº 13.709.
</p><p>DIREITO DE REVOGAÇÃO DO CONSENTIMENTO
</p><p>Este consentimento poderá ser revogado pelo usuário, a qualquer momento, mediante solicitação via e-mail contato@axiasaude.com e correspondência.

</p><p>

</p><p><h3><b>TERMOS E CONDIÇÕES GERAIS DE USO DO APLICATIVO AXIA SAÚDE</b></h3>
</p><p>Por meio deste instrumento, a pessoa jurídica AXIA SERVICOS LTDA, pessoa jurídica de direito privado, regularmente inscrita no CNPJ sob o nº 53.460.617/0001-56, estabelece os Termos de Uso aplicáveis ao site e/ou aplicativo AXIA SAÚDE, de modo a descrever as obrigações, direitos e responsabilidades de seus Usuários.
</p><p>•	DO OBJETO
</p><p>1.1	A plataforma visa licenciar o uso de seu software, aplicativos, website e demais ativos de sua propriedade intelectual ou os quais possui autorização, fornecendo ferramentas para auxiliar e dinamizar o dia a dia dos seus usuários.
</p><p>1.2	Desta forma, a plataforma AXIA SAÚDE realiza a prestação dos seguintes serviços: Serviços de Teleconsultas Médicas, disponibilizando, também, monitoramento feito por uma equipe de enfermagem, Teleconsultas de Nutrição e Psicologia, todos através do aplicativo “AXIA SAÚDE” e de uma “Central Telefônica”.
</p><p>•	DA ACEITAÇÃO
</p><p>2.1	O presente Termo estabelece obrigações contratadas de livre e espontânea vontade, durante o tempo de vigência contratado pelo usuário, entre a plataforma e os usuários do aplicativo AXIA SAÚDE.
</p><p>2.2	Ao utilizar a plataforma, o usuário aceita integralmente as presentes normas e compromete-se a observá-las, sob o risco de aplicação das penalidades cabíveis.
</p><p>2.3	A aceitação do presente instrumento é imprescindível para o acesso e para a utilização de quaisquer serviços fornecidos pela empresa. Caso não concorde com as disposições deste instrumento, o usuário não deve utilizá-los.

</p><p>

</p><p>•	DO ACESSO DOS USUÁRIOS
</p><p>3.1	Serão utilizadas todas as soluções técnicas à disposição do responsável pela plataforma para permitir o acesso aos serviços 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana. No entanto, a navegação na plataforma ou em alguma de suas páginas poderá ser interrompida, limitada ou suspensa para atualizações, modificações ou qualquer ação necessária ao seu bom funcionamento.
</p><p>•	DO CADASTRO

</p><p>

</p><p>4.1	Para que o usuário tenha acesso às funcionalidades da plataforma “AXIA SAÚDE”, este deverá, de forma obrigatória, realizar seu cadastro prévio, assim como o pagamento do valor do serviço contratado. O usuário deverá, também, aceitar o Contrato de Prestação de Serviços da AXIA SAÚDE e demais termos de consentimento, a fim de que possa concluir o pagamento.
</p><p>4.2	Ao realizar o seu cadastro, o usuário deverá informar seus dados completos, recentes, verdadeiros e válidos, sendo de sua exclusiva responsabilidade manter os referidos dados atualizados.
</p><p>4.3	O usuário se compromete a não informar seus dados cadastrais e/ou de acesso à plataforma a terceiros, responsabilizando-se integralmente pelo uso que deles seja feito.
</p><p>4.4	Mediante a realização do cadastro, o usuário declara e garante expressamente ser plenamente capaz, podendo exercer e usufruir livremente dos serviços e produtos.
</p><p>4.5	Menores de 18 anos, bem como aqueles que não possuírem plena capacidade civil deverão obter previamente o consentimento expresso de seus responsáveis legais para utilização da plataforma e dos serviços ou produtos, sendo de responsabilidade exclusiva destes o eventual acesso por menores de idade e por aqueles que não possuem plena capacidade civil sem a prévia autorização.
</p><p>4.6	O usuário deverá fornecer um endereço de e-mail válido, através do qual o site realizará todas as comunicações necessárias.
</p><p>4.7	Após a confirmação do cadastro, o usuário possuirá um login e uma senha pessoal, a qual assegura ao usuário o acesso individual à mesma. Desta forma, compete ao usuário exclusivamente a manutenção de referida senha de maneira confidencial e segura, evitando o acesso indevido às informações pessoais.
</p><p>4.8	Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário, que deverá informar prontamente a plataforma em caso de uso indevido da respectiva senha.
</p><p>4.9	Não será permitido ceder, vender, alugar ou transferir, de qualquer forma, a conta, que é pessoal e intransferível.
</p><p>4.10	Caberá ao usuário assegurar que o seu equipamento seja compatível com as características técnicas que viabilize a utilização da plataforma e dos serviços ou produtos.

</p><p>

</p><p>•	DOS SERVIÇOS PRESTADOS
</p><p>5.1	A AXIA SAÚDE oferecerá ao usuário os seguintes serviços, abaixo listados:
</p><p>Serviços de Pronto Atendimento 24h: O profissional médico responsável pelo Pronto Atendimento 24h, que será ofertado em todos os pacotes, deverá informar, recomendar, orientar virtualmente por meio do chat de mensagens ou videochamada, aos beneficiários da CONTRATADA, a respeito de enfermidades, sintomas, dúvidas ou inquietações de saúde, sobre as providências e ações preliminares convenientes que devam ser adotadas e orientação sobre medicamentos de venda livre (OTC) já em uso pelo usuário, sempre seguindo protocolos de  teleatendimento baseados em evidências científicas reconhecidas nacional e internacionalmente, de acordo com o art. 3º e parágrafos da Resolução CFM nº 2314/202.
</p><p>•	Os serviços de Teleconsulta Médica de Atenção Primária compreendem consultas de:
</p><p>- Demanda Espontânea: O beneficiário será direcionado no mesmo dia para a classificação de risco com o profissional enfermeiro e conforme a mesma poderá: ser encaminhado para a emergência/urgência, ser atendido no mesmo dia, ser agendado ou ter sua demanda resolvida. São os seguintes casos:
</p><p>(a) Cefaleias; (b) Febre; (c) Diarreia (d) Vômitos; (e) Queixas urinárias; (f) Lesões de pele (por exemplo: escabiose, herpes zoster); (g) Dor abdominal leve a moderada; (h) Dor de garganta; (i) Dor lombar; (j) Alterações leve da pressão, excetuando as urgência/emergências hipertensivas; (k) Mordedura de animais (Orientações); (l) Queimaduras leves/ primeiro grau; (m) Resfriados; (n) Queixas oftalmológicas “olho vermelho sem dor”; (o) Alergias leves;
</p><p>c)	Agendamento de Demandas Programadas: O beneficiário deverá solicitar o agendamento da teleconsulta através do aplicativo “AXIA SAÚDE”.
</p><p>d)	Serviços de Monitoramento realizado por uma equipe de enfermagem;
</p><p>e)	Teleconsultas de Psicologia e Serviços de Nutrição, todos através do aplicativo “AXIA SAÚDE”.

</p><p>

</p><p>6. DOS PREÇOS
</p><p>6.1	Os preços dos serviços estão descritos no site institucional da empresa. Os valores aplicados são aqueles que estão em vigor no momento do pedido.
</p><p>6.2	Na contratação dos serviços, a plataforma poderá solicitar as informações financeiras do usuário, como CPF, endereço de cobrança e dados de cartões. Ao inserir referidos dados o usuário concorda que sejam cobrados,
</p><p>de acordo com a forma de pagamento que venha a ser escolhida, os preços então vigentes e informados quando da contratação. Referidos dados financeiros poderão ser armazenados para facilitar acessos e contratações futuras.

</p><p>

</p><p>7. DO CANCELAMENTO
</p><p>7.1	O usuário poderá cancelar a contratação dos serviços de acordo com os termos que forem definidos no momento de sua contratação, através do Contrato de Prestação de Serviços. Ainda, o usuário também poderá cancelar os serviços em até 7 (sete) dias corridos após a contratação, mediante contato com a Contratada.
</p><p>7.2	Além disso, o Contrato poderá ser cancelado nas seguintes situações:
</p><p>a)	Violação dos Termos de Uso pelo Usuário: os serviços serão cessados imediatamente.
	•	Nas demais situações listadas no Contrato de Prestação de Serviços.

</p><p>

</p><p>8. DO SUPORTE
</p><p>8.1	Em caso de qualquer dúvida, sugestão ou problema com a utilização da plataforma, o usuário poderá entrar em contato com o suporte, através do e-mail:contato@axiasaude.com. Estes serviços de atendimento ao usuário estarão disponíveis em dias úteis, das 09h00 às 17h00.

</p><p>

</p><p>9. DAS RESPONSABILIDADES
</p><p>9.1	É de responsabilidade do usuário:
</p><p>a)	Defeitos ou vícios técnicos originados no próprio sistema do usuário;
</p><p>b)	A correta utilização da plataforma, dos serviços ou produtos oferecidos, prezando pela boa convivência, pelo respeito e cordialidade entre os usuários;
</p><p>c)	Pelo cumprimento e respeito ao conjunto de regras disposto nesse Termo de Condições Geral de Uso, na respectiva Política de Privacidade e na legislação nacional e internacional;
</p><p>d)	Pela proteção aos dados de acesso à sua conta/perfil (login e senha).
</p><p>9.2	É de responsabilidade da plataforma AXIA SAÚDE:
</p><p>a)	Indicar as características do serviço ou produto;
</p><p>b)	Os defeitos e vícios encontrados no serviço ou produto oferecido desde que lhe tenha dado causa;
</p><p>c)	As informações que foram por ele divulgadas, sendo que os comentários ou informações divulgadas por usuários são de inteira responsabilidade dos próprios usuários;
</p><p>d)	Os conteúdos ou atividades ilícitas praticadas através da sua plataforma.
</p><p>9.3	A plataforma não se responsabiliza por links externos contidos em seu sistema que possam redirecionar o usuário à ambiente externo a sua rede.
</p><p>9.4	Não poderão ser incluídos links externos ou páginas que sirvam para fins comerciais ou publicitários ou quaisquer informações ilícitas, violentas, polêmicas, pornográficas, xenofóbicas, discriminatórias ou ofensivas.

</p><p>

</p><p>10. DOS DIREITOS AUTORAIS
</p><p>10.1	O presente Termo de Uso concede aos usuários uma licença não exclusiva, não transferível e não sublicenciável, para acessar e fazer uso da plataforma e dos serviços e produtos por ela disponibilizados.
</p><p>10.2	A estrutura do site ou aplicativo, as marcas, logotipos, nomes comerciais, layouts, gráficos e design de interface, imagens, ilustrações, fotografias, apresentações, vídeos, conteúdos escritos e de som e áudio, programas de computador, banco de dados, arquivos de transmissão e quaisquer outras informações e direitos de propriedade intelectual da razão social AXIA SERVICOS LTDA., observados os termos da Lei da Propriedade Industrial (Lei nº 9.279/96), Lei de Direitos Autorais (Lei nº 9.610/98) e Lei do Software (Lei nº 9.609/98).
</p><p>10.3	Este Termos de Uso não cede ou transfere ao usuário qualquer direito, de modo que o acesso não gera qualquer direito de propriedade intelectual ao usuário, exceto pela licença limitada ora concedida.
</p><p>10.4	O uso da plataforma pelo usuário é pessoal, individual e intransferível, sendo vedado qualquer uso não autorizado, comercial ou não-comercial. Tais usos consistirão em violação dos direitos de propriedade intelectual da razão social AXIA SERVICOS LTDA., puníveis nos termos da legislação aplicável.

</p><p>

</p><p>11. DAS SANÇÕES
</p><p>11.1	Sem prejuízo das demais medidas legais cabíveis, a razão social AXIA SERVICOS LTDA. poderá, a qualquer momento, advertir, suspender ou cancelar a conta do usuário:
</p><p>a)	Que violar qualquer dispositivo do presente Termo;
</p><p>b)	Que descumprir os seus deveres de usuário;
</p><p>c)	Que tiver qualquer comportamento fraudulento, doloso ou que ofenda a terceiros.

</p><p>

</p><p>12. DA RESCISÃO
</p><p>12.1	A não observância das obrigações pactuadas neste Termo de Uso ou da legislação aplicável poderá, sem prévio aviso, ensejar a imediata rescisão unilateral por parte da razão social AXIA SERVICOS LTDA e o bloqueio de todos os serviços prestados ao usuário.

</p><p>

</p><p>13. DAS ALTERAÇÕES
</p><p>13.1	Os itens descritos no presente instrumento poderão sofrer alterações, unilateralmente e a qualquer tempo, a fim de adequar ou modificar os serviços, bem como para atender novas exigências legais. As alterações serãoveiculadas pelo site ou pelo aplicativo AXIA SAÚDE, e o usuário poderá optar por aceitar o novo conteúdo ou por cancelar o uso dos serviços, caso seja assinante de algum serviço.

</p><p>

</p><p>14. DA POLÍTICA DE PRIVACIDADE
</p><p>14.1	Além do presente Termo, o usuário deverá consentir com as disposições contidas na respectiva Política de Privacidade a ser apresentada a todos os interessados dentro da interface da plataforma.

</p><p>

</p><p>15. DO FORO
</p><p>15.1	Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro.
</p><p>15.2	Os eventuais litígios deverão ser apresentados no foro da Comarca de Rio Bonito-RJ, com expressa renúncia de qualquer outro, ainda que seja mais privilegiado.

</p><p>ACEITE: Em virtude das cláusulas do presente Contrato, o USUÁRIO declara que concorda integralmente com todas as disposições nele contidas, obrigando-se, desde já, a respeitá-las durante toda a vigência de contratação da AXIA SAÚDE.

</p>
</div>
`;
