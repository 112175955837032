<style src="./Term.scss" lang="scss"></style>

<template>
  <section class="">

    <img class="logo" :src="appLogoWhite" />

    <p class="firstheading">
      TERMOS E CONDIÇÕES DE USO – MEDICAR
    </p>
    <p class="paragraph">
      Estes Termos e Condições de Uso que dispõem acerca da utilização da
      Plataforma MEDICAR devem ser lidos com atenção.
    </p>
    <p class="subHeading">
      1. CONCORDÂNCIA COM OS TERMOS E CONDIÇÕES DE USO
    </p>
    <p class="subParagraph">
      1.1. O uso da Plataforma MEDICAR, ainda que parcialmente ou a título de
      teste, significará que o Usuário está plenamente ciente de todas as
      disposições, significando também plena e integral concordância com os
      Termos e Condições de Uso aqui apresentadas.
    </p>
    <p class="subParagraph">
      1.2. Uma vez que o uso da Plataforma MEDICAR apenas pode ser utilizada
      após a leitura destes Termos e Condições de Uso, restando claro que sua
      utilização significará a concordância integral com os Termos e Condições
      de Uso apresentados. Caso o Usuário não concorde com qualquer uma das
      disposições nestes Termos e Condições de Uso, ele não deverá utilizar a
      Plataforma MEDICAR. Sendo assim, fica claro e evidente que o uso da
      Plataforma MEDICAR não está autorizado em caso de não concordância com
      estes Termos e Condições de Uso.
    </p>
    <p class="subParagraph">
      1.3. Caso o Usuário venha a discordar dos Termos e Condições de Uso
      posteriormente ao início da utilização da Plataforma MEDICAR, o uso
      deste deverá ser imediatamente interrompido, sendo certo que estes
      Termos e Condições de Uso terão vigência durante todo o período de uso,
      não cabendo ao Usuário alegar o desconhecimento destes Termos e
      Condições de Uso.
    </p>
    <p class="subHeading">2. DEFINIÇÕES</p>
    <p class="subParagraph">
      Os termos aqui relacionados, no singular ou no plural terão o seguinte
      significado:
    </p>
    <p class="definition">
      Assinatura Mensal: modalidade de adesão do Usuário aos Serviços da
      MEDICAR, cujos valores estarão dispostos em local visível na Plataforma
      MEDICAR.
    </p>
    <p class="definition">
      MEDICAR: Plataforma de telemedicina já qualificada no início destes
      Termos e Condições de Uso.
    </p>
    <p class="definition">
      Instituições: instituições da área de saúde, tais como consultórios,
      clínicas, hospitais e convênios que firmaram contrato com a MEDICAR, de
      modo a disponibilizar a utilização da Plataforma MEDICAR aos seus
      Profissionais de Saúde e a outras pessoas que a eles se vinculem.
    </p>
    <p class="definition">
      Plataforma MEDICAR: é o Software as a Service (SaaS) de titularidade da
      MEDICAR, denominado “MEDICAR”, disponibilizado em forma de plataforma
      web ou de Aplicativo, que possibilita aos Profissionais de Saúde
      vinculados às Instituições a prática da Telemedicina no atendimento aos
      Usuários, por meio de Teleconsultas de vídeo, voz e chat.
    </p>
    <p class="definition">
      Profissionais de Saúde: Médicos e demais profissionais da saúde
      devidamente registrados em seus respectivos órgãos/conselhos de classe,
      vinculados a uma Instituição que contratam com a MEDICAR a fim de
      prestar serviços de Teleconsulta aos Usuários.
    </p>
    <p class="definition">
      Serviços: Serviços de licenciamento e intermediação voltados à
      facilitação da interconexão online entre Instituições, Profissionais de
      Saúde a elas vinculados e os Usuários, por meio da Plataforma MEDICAR
      para a oferta de Teleconsulta na modalidade de Assinatura Mensal ou de
      Teleconsulta Avulsa.
    </p>
    <p class="definition">
      Teleconsulta: modalidade de consulta médica realizada à distância, com o
      auxílio da tecnologia da MEDICAR.
    </p>
    <p class="definition">
      Teleconsulta Avulsa: modalidade de consulta médica realizada à
      distância, com o auxílio da tecnologia da MEDICAR, por meio dos
      Profissionais de Saúde diretamente vinculados à Instituições, de modo
      alternativo e não vinculado à Assinatura Mensal, cujos valores estarão
      dispostos em local visível na Plataforma MEDICAR.
    </p>
    <p class="definition">
      Telemedicina: o exercício da medicina mediado por Tecnologias Digitais,
      de Informação e de Comunicação (TDICs), para fins de assistência,
      educação, pesquisa, prevenção de doenças e lesões, gestão e promoção de
      saúde, nos termos do art. 1º, Resolução CFM nº 2.314, de 20 de Abril de
      2022.
    </p>
    <p class="definition">
      Termos e Condições de Uso: o presente documento, que regula o uso da
      Plataforma MEDICAR, por meio do qual o Usuário expressa sua
      concordância, bem como contém informações diversas.
    </p>
    <p class="definition">
      Usuário: é a pessoa física que possui acesso à Plataforma MEDICAR.
    </p>
    <p class="subHeading">3. MODALIDADE DE SERVIÇOS</p>
    <p class="subParagraph">
      3.1. Os Serviços prestados pela MEDICAR são realizados e oferecidos na
      modalidade de Assinatura Mensal e de Teleconsulta Avulsa ao Usuário,
      cujas condições e valores estarão disponíveis em local apropriado e
      acessíveis ao Usuário na Plataforma MEDICAR.
    </p>
    <p class="subParagraph">
      3.2. A MEDICAR poderá incluir nos Serviços, produtos ou serviços
      adicionais e/ou complementares aos pactuados, durante a vigência da
      Assinatura Mensal.
    </p>
    <p class="subParagraph">
      3.3. Os serviços adicionais e/ou complementares poderão ser gratuitos ou
      não, sendo certo que aqueles não gratuitos somente poderão ser inclusos
      nos Serviços mediante a concordância expressa do Usuário.
    </p>
    <p class="subParagraph">
      3.4. O acesso, pelo Usuário, às Teleconsultas oferecidas pelos
      Profissionais de Saúde vinculados às Instituições, na modalidade de
      Assinatura Mensal, se dará de forma ilimitada, ressalvado o direito de a
      MEDICAR advertir o Usuário que utilizar a plataforma MEDICAR de forma
      indevida ou com abuso de direito.
    </p>
    <p class="subHeading">4. LICENÇA DE USO</p>
    <p class="subParagraph">
      4.1. Estes Termos e Condições de Uso regulamentam a forma pela qual a
      MEDICAR concede ao Usuário uma licença de uso da Plataforma MEDICAR, em
      caráter não exclusivo e intransferível, o qual deverá ser utilizado em
      conformidade com as regras presentes destes Termos e Condições de Uso.
    </p>
    <p class="subParagraph">
      4.2. A Plataforma MEDICAR não é, em hipótese alguma, vendida para o
      Usuário, e sim licenciada para o uso do Usuário, em conformidade com a
      legislação brasileira. Sua propriedade não será transferida a quem quer
      que seja sem consentimento prévio, expresso e específico da MEDICAR.
      Assim sendo, é vedada a cessão e/ou comercialização da Plataforma
      MEDICAR, seja total ou parcialmente, a terceiros, sob pena de
      responsabilização por perdas e danos.
    </p>
    <p class="subParagraph">
      4.2.1. Por adquirir apenas uma licença de uso, o Usuário em nenhuma
      hipótese, terá acesso ao código fonte dos Softwares da Plataforma
      MEDICAR.
    </p>
    <p class="subParagraph">
      4.3. O uso da Plataforma MEDICAR deverá observar estritamente todos os
      termos e disposições dos presentes Termos e Condições de Uso. Em caso de
      uso não autorizado nestes Termos e Condições de Uso, o Usuário estará
      sujeito a todas as penalidades previstas nestas condições gerais e na
      legislação brasileira, assim como ao pagamento de eventuais perdas e
      danos que a MEDICAR vier a sofrer.
    </p>
    <p class="subParagraph">
      4.4. O uso da Plataforma MEDICAR está condicionado a uma conexão de
      internet ativa do Usuário, sendo que a MEDICAR não se responsabiliza
      pela prestação de serviços de acesso da internet, sendo de total
      responsabilidade do Usuário possuir internet com capacidade de conexão.
    </p>
    <p class="subParagraph">
      4.4.1. A MEDICAR não se responsabilizará por impossibilidade de
      utilização da Plataforma MEDICAR em caso de falta de conexão com a
      internet, seja esta falha momentânea ou não.
    </p>
    <p class="subHeading">5. CADASTRO</p>
    <p class="subParagraph">
      5.1 O acesso à Plataforma MEDICAR será realizado pelo Usuário através de
      uma conta, com login e senha, a qual será criada pelo próprio Usuário,
      sendo a conta criada de uso pessoal e intransferível.
    </p>
    <p class="subParagraph">
      5.2. Ao se cadastrar, você autoriza o tratamento dos seus dados
      pessoais, apenas os necessários para a execução dos serviços, além disso
      ao anexar sua imagem, esta poderá ser utilizada para fins de
      identificação dentro do aplicativo. Seus dados de saúde somente serão
      compartilhados com a sua permissão e serão armazenados com segurança, em
      conformidade com nossa Política de Privacidade e a Lei Geral de Proteção
      de Dados Pessoais – Lei nº 13.709/2018.
    </p>
    <p class="subParagraph">
      5.3. O cadastro e a utilização da Plataforma MEDICAR deverão ser
      realizados com informações, declarações e documentos válidos, completos
      e atualizados, responsabilizando-se a Instituição e o Usuário, civil e
      criminalmente, pela veracidade e precisão das informações, isentando a
      MEDICAR e os parceiros desta por qualquer dano ou prejuízo que a falta
      de informações corretas causar.
    </p>
    <p class="subParagraph">
      5.4. O Usuário sempre será integralmente responsável pela utilização de
      sua conta na Plataforma MEDICAR, devendo adotar as medidas de segurança
      necessárias para impedir o uso por terceiros não autorizados. Caso
      suspeitem que um terceiro esteja utilizando indevidamente sua conta na
      Plataforma MEDICAR, deverá efetuar a imediata troca de sua senha pessoal
      e comunicar tal fato à MEDICAR.
    </p>
    <p class="subParagraph">
      5.5. O uso da Plataforma MEDICAR de forma indevida, irregular, ilegal ou
      em desacordo às condições previstas neste Termos e Condições de Uso
      implicará a exclusão do cadastro e a rescisão imediata do acesso do
      Usuário à Plataforma MEDICAR, sujeito ainda às cominações legais.
    </p>
    <p class="subParagraph">
      5.6. O Usuário ou a Instituição deverá indenizar a MEDICAR seus sócios,
      colaboradores, empregados, clientes e/ou terceiros prejudicados por
      qualquer perda, reclamação ou demanda, bem como por todos e quaisquer
      prejuízos resultantes da utilização indevida da Plataforma MEDICAR em
      discordância com o disposto no presente instrumento e na legislação
      aplicável.
    </p>
    <p class="subHeading">6. MEIOS DE PAGAMENTO</p>
    <p class="subParagraph">
      6.1. A MEDICAR aceita pagamentos através de cartões de crédito, dentre
      outros de livre escolha da MEDICAR que poderão estar disponíveis no site
      ou Plataforma MEDICAR no momento da realização do pagamento pelo
      Usuário.
    </p>
    <p class="subParagraph">
      6.2. O acesso à Plataforma MEDICAR estará disponível a partir do momento
      em que a MEDICAR verificar o pagamento feito pelo Usuário. Se o
      pagamento não puder ser verificado, por qualquer motivo, a MEDICAR
      poderá, a seu critério, decidir não permitir o acesso do Usuário à
      Plataforma MEDICAR até que possa verificar o pagamento efetuado pelo
      Usuário. No caso de não confirmação do pagamento, a MEDICAR poderá
      comunicar o Usuário por e-mail, SMS ou “push”, ferramenta de comunicação
      direta do app com o usuário.
    </p>
    <p class="subHeading">
      7. REQUISITOS MÍNIMOS PARA UTILIZAÇÃO DA PLATAFORMA MEDICAR
    </p>
    <p class="subParagraph">
      7.1. Para utilizar a Plataforma MEDICAR, o Usuário deverá atender as
      exigências mínimas de configuração de equipamento (hardware), de sistema
      operacional (software), devendo ainda possuir uma conexão ativa com a
      Internet.
    </p>
    <p class="subParagraph">
      7.2. A exigência para utilização do Software é o acesso à Plataforma
      MEDICAR por plataforma web ou por Aplicativo de propriedade da MEDICAR
      em uma Loja de Aplicativos Móveis, seja a Apple Store, Google Play ou
      qualquer outra loja onde o Aplicativo do Usuário esteja disponível para
      download.
    </p>
    <p class="subHeading">8. SUPORTE TÉCNICO</p>
    <p class="subParagraph">
      8.1. A MEDICAR dará suporte técnico relativo ao Software remotamente,
      por telefone e pela Internet, no horário comercial, isto é, das 08h00min
      às 18h00min, em dias úteis, de segunda à sexta-feira, excluídos vésperas
      de feriados e feriados.
    </p>
    <p class="subParagraph">
      8.2. Para os fins destes Termos e Condições de Uso, serão considerados
      os dias úteis do Município de Vitória/ES.
    </p>
    <p class="subParagraph">
      8.3. Eventuais atendimentos solicitados fora do horário padrão de
      atendimento, isto é, dias úteis, das 08h00min às 18h00min, estarão
      sujeitos à disponibilidade da MEDICAR e ao pagamento de quantia
      adicional, a ser devidamente informada na ocasião da solicitação do
      serviço.
    </p>
    <p class="subParagraph">
      8.4. O serviço de suporte técnico consistirá em técnicos treinados para
      solucionar dúvidas e orientar o Usuário na operação da Plataforma
      MEDICAR.
    </p>
    <p class="subHeading">9. PROPRIEDADE INTELECTUAL</p>
    <p class="subParagraph">
      9.1 O Usuário não adquire, pela utilização da Plataforma MEDICAR,
      qualquer direito de propriedade intelectual ou outros direitos
      exclusivos, incluindo patentes, desenhos, marcas, direitos autorais ou
      quaisquer direitos sobre informações confidenciais ou segredos de
      negócio, bem como todo o conteúdo disponibilizado no Site, incluindo,
      mas não se limitando a pos, gráficos, imagens, logotipos, ícones,
      fotografias, conteúdo editorial e qualquer outro material relacionado a
      Plataforma MEDICAR.
    </p>
    <p class="subParagraph">
      9.2. Todo o conteúdo disponibilizado na Plataforma MEDICAR, incluindo,
      mas não se limitando a, pos, gráficos, imagens, logos, ícones,
      fotografias, conteúdo editorial, notificações, softwares e qualquer
      outro material é de propriedade exclusiva da MEDICAR ou está devidamente
      licenciado por esta, não podendo ser copiado, plagiado, reproduzido ou
      publicado em qualquer meio (físico ou virtual).
    </p>
    <p class="subParagraph">
      9.3. É vedado ao Usuário modificar, copiar, distribuir, transmitir,
      exibir, realizar, reproduzir, publicar, disponibilizar, licenciar ou
      criar obras derivadas a partir das informações coletadas na Plataforma
      MEDICAR, bem como transferir ou utilizar para fins comerciais tais
      informações, softwares, produtos ou serviços, sob pena de violação deste
      instrumento e caracterização de infração legal.
    </p>
    <p class="subParagraph">
      9.4. A MEDICAR reserva a si todos os direitos da Plataforma MEDICAR.
    </p>
    <p class="subParagraph">
      9.5. É expressamente proibida a utilização de ferramentas, scripts,
      softwares, robôs ou meios que contenham qualquer vírus, worms, malwares,
      trojans e/ou outros programas de computador que possam causar danos a
      Plataforma MEDICAR.
    </p>
    <p class="subHeading">10. ATUALIZAÇÃO</p>
    <p class="subParagraph">
      10.1. A Plataforma MEDICAR, embora com garantia de bom funcionamento,
      está sujeita a constantes modificações, decorrentes de otimizações e
      aperfeiçoamentos, que visam facilitar e tornar mais segura e eficiente a
      sua operação, melhor aproveitamento dos recursos disponibilizados, bem
      como efetuar as alterações pertinentes por conta das modificações na
      legislação.
    </p>
    <p class="subParagraph">
      10.2. O Usuário, enquanto durar sua licença de uso, terá direito de
      acessar as novas versões da Plataforma MEDICAR.
    </p>
    <p class="subParagraph">
      10.3. O Usuário desde já concorda com as modificações, melhorias ou
      correções no modelo de Software utilizado pela MEDICAR, ficando a
      critério da MEDICAR o gerenciamento e implementação dessas atualizações.
    </p>
    <p class="subHeading">
      11. ACESSO E RESTRIÇÕES DE FUNCIONALIDADE
    </p>
    <p class="subParagraph">
      11.1. Não é permitido acessar as áreas de programação da Plataforma
      MEDICAR, seu banco de dados ou qualquer outro conjunto de informações
      que faça parte da programação do sistema, realizar ou permitir que se
      realize qualquer tipo de engenharia reversa, tradução, decompilação,
      cópia, modificação, reprodução, locação, sublocação, sublicenciamento,
      publicação, divulgação, transmissão, empréstimo, distribuição ou, de
      qualquer outra forma, a disposição de ferramentas de consulta desta
      plataforma e de suas funcionalidades para terceiros sem a prévia e
      expressa autorização da MEDICAR, ficando sujeito quem o fizer à
      responsabilização criminal e civil sob a legislação brasileira, sem
      prejuízo da obrigação de reparar os danos que causarem. Esta restrição
      inclui qualquer tentativa de incorporar quaisquer informações da
      Plataforma MEDICAR em qualquer outro diretório, produto ou serviço.
    </p>
    <p class="subParagraph">
      11.2. É proibida a utilização de programas do tipo spider/crawler ou de
      mineração de dados, que tenham como finalidade a quebra de sistemas de
      segurança, senha, captcha, ou ainda de qualquer tipo ou espécie
      correlato, além de outros aqui não tipificados, mas que atue de modo
      automatizado, tanto para realizar operações massificadas ou para
      quaisquer outras finalidades, sob pena de aplicação da legislação
      correlata de proteção dos interesses da MEDICAR, sem prejuízo da
      obrigação de reparar quaisquer perdas e danos eventualmente apurados
      e/ou suportados pela MEDICAR e que decorrerem desta utilização indevida.
    </p>
    <p class="subParagraph">
      11.3. É proibida ainda a coleta de quaisquer informações de
      identificação pessoal e cadastro de outros Usuários da Plataforma
      MEDICAR para quaisquer fins.
    </p>
    <p class="subHeading">12. OBRIGAÇÕES DO USUÁRIO</p>
    <p class="subParagraph">
      São obrigações do Usuário, sem prejuízo das demais previstas nestes
      Termos e Condições de Uso e na legislação pertinente:
    </p>
    <p class="subParagraph">12.1. O Usuário se obriga a:</p>
    <p class="subParagraph">
      (a) respeitar às disposições deste Termo e as leis que regem a
      utilização deste, exclusivamente empregando-os para a finalidade para
      qual foi destinado o serviço;
    </p>
    <p class="subParagraph">
      (b) não divulgar a terceiros seus dados de acesso, nem permitir o uso de
      tais informações por terceiros, responsabilizando-se pelas consequências
      do uso de seu cadastro, uma vez que estes são pessoais e
      intransferíveis, devendo notificar a MEDICAR imediatamente, através de
      meio seguro, a respeito de qualquer uso ou acesso não autorizado de sua
      conta por terceiros;
    </p>
    <p class="subParagraph">
      (c) para que ocorra a Teleconsulta com segurança, o Usuário deverá estar
      conectado à rede de internet segura, num local iluminado, silencioso e
      privado. A relação do Profissional de Saúde com o Usuário deve ser
      preservada e, caso haja quebra desta relação, seja por comportamento ou
      ambiente inadequado, o Profissional de Saúde poderá interromper a
      Teleconsulta a qualquer momento.
    </p>
    <p class="subParagraph">
      12.2. Utilizar a Plataforma MEDICAR exclusivamente ao fim a que ele se
      destina, abstendo-se de trafegar e armazenar dados e informações que
      importem em prejuízo a terceiros; violem a lei, a moral, os bons
      costumes; a propriedade intelectual; que incorporem vírus ou outros
      elementos físicos ou eletrônicos que possam danificar ou impedir o seu
      uso normal;
    </p>
    <p class="subParagraph">
      12.3. Utilizar somente equipamentos hábeis e capazes de atender às
      exigências mínimas de configuração necessárias à utilização do software,
      bem como pelo manuseio correto do programa;
    </p>
    <p class="subParagraph">
      12.4. Não acessar a Plataforma MEDICAR sob informações de outra pessoa,
      utilizando CPF e número de Celular de outro Usuário que não seja
      responsável legal. Caso tenha acesso a dados de Usuário, vedar o uso da
      Plataforma MEDICAR, saindo imediatamente da área restrita do Usuário.
    </p>
    <p class="subParagraph">
      12.5. Não acessar, desconstituir, alterar, modificar, seccionar ou de
      qualquer forma adulterar os códigos fonte da Plataforma MEDICAR.
    </p>
    <p class="subParagraph">
      12.6. Manter o cadastro de seu CPF e Número de Celular atualizados, de
      forma a impossibilitar o acesso de outra pessoa ao seu cadastro de
      Usuário;
    </p>
    <p class="subParagraph">
      12.7. Manter seu computador ou dispositivo móvel livre de vírus ou
      similares, propiciando assim maior segurança ao tráfego e armazenamento
      dos dados/informações;
    </p>
    <p class="subParagraph">
      12.8. Manter os seus dados cadastrais devidamente atualizados, sob pena
      de, não o fazendo, considerarem-se válidos todos os avisos e
      notificações enviados para os endereços constantes do referido cadastro;
    </p>
    <p class="subParagraph">
      12.9. Autorizar que a MEDICAR proceda as necessárias modificações,
      reparações e implementações de novos recursos à Plataforma MEDICAR, caso
      este apresente alguma anomalia ou irregularidade, de modo a torná-la
      mais segura, eficiente e eficaz, mesmo que acarrete sua
      indisponibilidade temporária;
    </p>
    <p class="subParagraph">
      12.10. Reconhecer que todas as modificações, melhorias e correções
      efetuadas na Plataforma MEDICAR serão de propriedade da MEDICAR, sendo,
      portanto, objeto de licença de uso, nos mesmos termos e sujeitando-se
      aos presentes Termos e Condições de Uso, podendo inclusive ser
      disponibilizadas pela MEDICAR a outros Usuários que utilizem a
      Plataforma MEDICAR;
    </p>
    <p class="subParagraph">13. GARANTIAS E RESPONSABILIDADES</p>
    <p class="subParagraph">
      13.1. A MEDICAR assegura a funcionalidade da Plataforma MEDICAR, desde
      que devidamente utilizado pelo Usuário de acordo com as instruções da
      MEDICAR, pelo período que durar a licença de uso contratada pelo
      Usuário.
    </p>
    <p class="subParagraph">
      13.2. A mencionada garantia não será aplicável nos casos em que qualquer
      não conformidade ou defeito for exclusivamente causado por:
    </p>
    <p class="subParagraph">
      (a) Qualquer programa de computador associado ou complementar cujo uso
      conjunto não tenha sido expressamente autorizado pela MEDICAR;
    </p>
    <p class="subParagraph">
      (b) Modificação não autorizada ou uso da Plataforma MEDICAR em desacordo
      com as regras e disposições destes Termos e Condições de Uso e/ou das
      instruções que sejam fornecidas pela MEDICAR.
    </p>
    <p class="subParagraph">
      13.3. A MEDICAR não se responsabiliza por danos causados ao sistema por
      vírus de computador, falhas de energia elétrica, interrupções na conexão
      da Internet, atualizações ou falhas inerentes ao servidor de hospedagem
      terceirizado ou qualquer outra causa que não seja associada diretamente
      à MEDICAR.
    </p>
    <p class="subParagraph">
      13.4. A MEDICAR não garante que a Plataforma MEDICAR está livre de
      erros. A MEDICAR, no entanto, envidará seus maiores esforços para sanar
      qualquer erro de programação que a Plataforma MEDICAR venha a
      apresentar.
    </p>
    <p class="subParagraph">
      13.5. Assume-se, para efeito destas condições gerais, e o Usuário
      expressamente concorda, a existência de um consenso mundial acerca de
      não haver programa de computador totalmente isento de erros ou
      totalmente invulnerável; à luz de tal consenso, na hipótese da
      ocorrência de qualquer defeito no software, a MEDICAR se reserva o
      direito de ser chamada para tentar resolvê-lo antes que lhe seja
      atribuída qualquer responsabilidade ou se lhe requeira qualquer
      ressarcimento ou indenização.
    </p>
    <p class="subParagraph">
      13.6. Esta garantia pressupõe que a plataforma MEDICAR, objeto deste
      contrato, seja operada em ambiente de software e hardware de origem
      idônea, em sistema de rede e instalações elétricas adequadas e isentas
      de problemas, e dentro da configuração mínima exigida pela MEDICAR.
      Considera-se hardware não idôneo aquele originário de fabricante
      desconhecido ou não legalmente constituído, ou montado em instalações
      clandestinas, originário de contrabando ou qualquer outro em que não
      exista garantia expressa e válida de fabricação de seu todo ou partes.
    </p>
    <p class="subParagraph">
      13.7. As garantias estipuladas na presente cláusula não abrangem
      problemas, erros, danos ou prejuízos advindos de decisões tomadas com
      base em informações, quaisquer que sejam fornecidas pelo(s) programa(s),
      assim como não abrangem defeitos ou erros decorrentes de negligência,
      imprudência ou imperícia do Usuário na utilização da Plataforma MEDICAR,
      e também problemas provenientes de caso fortuito ou força maior.
    </p>
    <p class="subParagraph">
      13.8. O Usuário é integralmente responsável pelas informações inseridas
      na Plataforma MEDICAR. Toda e qualquer informação inserida na Plataforma
      MEDICAR pelo Usuário não será revisada pela MEDICAR, que em hipótese
      alguma será responsável pelo conteúdo inserido pelo Usuário na
      Plataforma MEDICAR.
    </p>
    <p class="subParagraph">
      13.9. Em nenhuma circunstância a MEDICAR será considerada responsável
      por quaisquer danos, diretos ou indiretos, físicos, técnicos,
      econômicos, ou fiscais, tais como, sem se resumir a, perdas e danos,
      lucros cessantes, danos emergentes, interrupção de negócios ou outros
      prejuízos decorrentes de uso ou da impossibilidade do uso da Plataforma
      MEDICAR.
    </p>
    <p class="subParagraph">
      13.10. Os documentos médicos eventualmente gerados na Teleconsulta
      (receitas, atestados, pedidos de exames e encaminhamentos) estarão
      disponíveis para download imediatamente após a consulta, na área do
      Usuário dentro da Plataforma MEDICAR.
    </p>
    <p class="subParagraph">
      13.11. É de responsabilidade da MEDICAR garantir ao Usuário, durante 24
      (vinte e quatro) horas, 07 (sete) dias por semana, a disponibilidade dos
      Profissionais de Saúde vinculados às Instituições.
    </p>
    <p class="subParagraph">
      13.12. As Instituições oferecerão ao Usuário, pelos Profissionais de
      Saúde a elas vinculados, por meio da Plataforma MEDICAR, especialidades
      médicas dispostas em locais visíveis na Plataforma MEDICAR.
    </p>
    <p class="subParagraph">
      13.13. A Plataforma MEDICAR ficará disponível pelo período de 24 (vinte
      e quatro) horas por dia para a realização das consultas médicas à
      distância e online prestadas pelas Instituições, por meio dos
      Profissionais de Saúde a elas vinculados.
    </p>
    <p class="subParagraph">
      14. LIMITAÇÃO DE RESPONSABILIDADE DA MEDICAR
    </p>
    <p class="subParagraph">
      14. LIMITAÇÃO DE RESPONSABILIDADE DA MEDICAR
    </p>
    <p class="subParagraph">
      14.1. O Usuário desde já declara conhecer e concordar que a Plataforma
      MEDICAR é tão somente uma plataforma web ou por Aplicativo, que facilita
      a interconexão entre as Instituições, os Profissionais de Saúde a ela
      vinculados e os Usuários, a quem disponibiliza funcionalidades para
      auxiliar a realização de Teleconsulta pelos Profissionais de Saúde.
    </p>
    <p class="subParagraph">
      14.2. A Plataforma MEDICAR não é responsável pela disponibilidade,
      qualidade e manutenção de tais serviços de telecomunicação e o Usuário
      entende que as condições do serviço de telecomunicações poderão afetar a
      experiência do Serviço. A MEDICAR não será responsabilizada por qualquer
      problema relacionado ao Serviço decorrente direta ou indiretamente de
      inconsistências ou falhas nos dispositivos de telefonia móvel e/ou nos
      serviços de telecomunicação. Além disso, a MEDICAR não será
      responsabilizada por eventuais erros e inconsistências das informações
      oriundas de terceiros relativas ao geoposicionamento via sistema GPS e
      aos mapas integrados ao sistema.
    </p>
    <p class="subParagraph">
      14.3. Em função do disposto no item supra a MEDICAR não se
      responsabilizará:
    </p>
    <p class="subParagraph">
      (a) Pela falha de operação, operação por pessoas não autorizadas ou
      qualquer outra causa em que não exista culpa da MEDICAR;
    </p>
    <p class="subParagraph">
      (b) ocorrências por “caso fortuito” ou “força maior”;
    </p>
    <p class="subParagraph">
      (c) problemas decorrentes de ações de terceiros que possam interferir na
      qualidade do serviço;
    </p>
    <p class="subParagraph">
      (d) danos causados a terceiros em razão de serviços contratados e
      conteúdo gerado através da Plataforma MEDICAR;
    </p>
    <p class="subParagraph">
      (e) revisar as informações fornecidas pelos Usuários, Instituições e
      Profissionais de Saúde, seja no que tange à veracidade, exatidão ou
      completude dos dados, legalidade ou violação legal em função do
      fornecimento destas informações;
    </p>
    <p class="subParagraph">
      (f) danos pessoais ou qualquer prejuízo incidental, especial, indireto
      ou consequente, incluindo, sem se resumir a, prejuízos por perda de
      lucro, corrupção ou perda de dados, falha de transmissão ou recepção de
      dados, não continuidade do negócio ou qualquer outro prejuízo
      decorrentes ou relacionados ao seu uso ou inabilidade do Usuário,
      Instituições e Profissionais de Saúde a elas vinculados que utilizarem a
      Plataforma MEDICAR;
    </p>
    <p class="subParagraph">
      (g) qualquer fraude, declaração fraudulenta ou violação do dever, ou
      violação de qualquer uma das condições destes Termos e Condições de Uso
      pelo Usuário, Instituições e Profissionais de Saúde a elas vinculados;
    </p>
    <p class="subParagraph">
      (h) falhas de conexões, especialmente no que tange a impossibilidade de
      acesso à internet pelos Usuários; e
    </p>
    <p class="subParagraph">
      (i) quaisquer problemas relacionados à prestação de serviços realizada
      pelas Instituições e os Profissionais de Saúde a elas vinculados aos
      Usuários.
    </p>
    <p class="subParagraph">
      14.4. A MEDICAR irá se esforçar para manter o seu conteúdo atualizado e
      completo, livre de quaisquer defeitos ou vírus, não se
      responsabilizando, contudo, por esses e quaisquer outros possíveis
      problemas que não sejam causadas diretamente e exclusivamente pela
      MEDICAR. Fica desde já acordado que a MEDICAR poderá alterar o conteúdo
      a qualquer momento, sem prévio aviso.
    </p>
    <p class="subParagraph">
      14.5. A MEDICAR declara inexistência de relação de trabalho, vínculo de
      emprego e outros entre as Instituições e os Profissionais de Saúde a
      elas vinculados com a MEDICAR, bem como inexistência de qualquer vínculo
      de natureza societária, empregatícia e/ou econômica. A MEDICAR não
      possui uma rede de Profissionais de Saúde ou Instituições, prestando
      exclusivamente os serviços de licenciamento e intermediação voltados à
      interconexão online entre as Instituições, os Profissionais de Saúde a
      elas vinculados e os Usuários que tiverem acesso à Plataforma MEDICAR.
    </p>
    <p class="subParagraph">
      14.5.1. A MEDICAR não se responsabiliza por quaisquer perdas, prejuízos
      ou danos de qualquer natureza que sejam decorrentes da relação entre
      Usuário, Instituições e os Profissionais de Saúde a elas vinculados. O
      Usuário entende e concorda que a MEDICAR não será responsável por
      quaisquer danos ou prejuízos que venham a lhe ser causados pelas
      Instituições e os Profissionais de Saúde a elas vinculados, uma vez que
      a relação entre os Profissionais de Saúde e os Usuários é regida pelos
      preceitos e normas estabelecidos pelo CFM (Conselho Federal de
      Medicina).
    </p>
    <p class="subParagraph">
      14.6. A Teleconsulta será realizada por Profissional de Saúde vinculado
      à Instituições, que se responsabilizam integral e solidariamente pelo
      atendimento realizado, respondendo civilmente, criminalmente e perante
      os respectivos conselhos de classe por eventuais danos causados.
    </p>
    <p class="subParagraph">15. CANCELAMENTO</p>
    <p class="subParagraph">
      15.1. O cancelamento dos Serviços poderá ser requerido pelo Usuário,
      observado o prazo mínimo de 12 (doze) meses a partir da liberação do
      acesso pelo Usuário à Plataforma MEDICAR, para que o cancelamento se dê
      sem quaisquer ônus ou multa para o Usuário.
    </p>
    <p class="subParagraph">
      15.2. Se o Usuário, sem justa causa, decidir por cancelar os Serviços
      antes do prazo a que se refere o item 15.1., ficará obrigado a pagar, a
      título de multa por quebra contratual, o equivalente a 04 (quatro)
      Assinaturas Mensais.
    </p>
    <p class="subParagraph">
      15.3. Se o Usuário decidir cancelar os Serviços da MEDICAR após 03
      (três) meses do seu início, ficará obrigada a pagar, em favor da outra,
      os valores proporcionais até o fim do prazo a que se refere o item 15.1.
    </p>
    <p class="subParagraph">
      15.4. Os pagamentos efetuados pelos Serviços não são passíveis de
      reembolso, inclusive pelo cancelamento unilateral do Usuário.
    </p>
    <p class="subParagraph">16. POLÍTICA DE PRIVACIDADE</p>
    <p class="subParagraph">
      16.1. Favor consultar a Política de Privacidade da MEDICAR no seguinte
      link: http://www.medicar.com.br/politica-de-privacidade, que constitui
      parte integrante destes Termos e Condições de Uso.
    </p>
    <p class="subParagraph">17. DEMAIS CONDIÇÕES</p>
    <p class="subParagraph">
      17.1. O Usuário concorda que a MEDICAR lhe envie ou simplesmente
      disponibilize, por meio da Plataforma MEDICAR, artigos, pos, notícias
      ou quaisquer outras formas de comunicação, desenvolvidas pela própria
      MEDICAR ou por parceiros desta, sempre que julgar que tais comunicações
      sejam de interesse do Usuário.
    </p>
    <p class="subParagraph">
      17.2. A MEDICAR poderá modificar estes Termos de Condições e Uso a
      qualquer momento, publicando a nova versão em seu website. Os novos
      Termos e Condições de Uso revisados entrarão em vigor dentro de 48
      (quarenta e oito) horas contados da data de sua publicação ou entrega ao
      Usuário, exceto se o Usuário expressamente aceitar o contrato revisado
      antes disso ao clicar no botão de aceitar. A aceitação expressa ou o uso
      contínuo da Plataforma MEDICAR depois da expiração do prazo de 48
      (quarenta e oito) horas do aviso constituirá aceitação integral destes
      Termos e Condições de Uso.
    </p>
    <p class="subParagraph">
      17.3. As disposições destes Termos e Condições de Uso são irrevogáveis e
      irretratáveis e obrigam as partes e seus sucessores a qualquer título.
      As partes reconhecem, para todos os fins e efeitos de direito, que os
      presentes Termos e Condições de Uso constituem título executivo
      extrajudicial, estando sujeito à execução específica, nos termos do
      disposto no Código de Processo Civil.
    </p>
    <p class="subParagraph">
      17.4. Fica eleito o foro da Comarca de Vitória/ES, com renúncia de
      qualquer outro, por mais privilegiado que o seja, para resolver qualquer
      questão resultante dos presentes Termos e Condições de Uso.
    </p>
    <br>

    <img class="logo" :src="appLogoWhite" />
    <br>
    <br>
    <br>
    <a href="https://app.medicar.com.br/" class="link">
      <span>https://app.medicar.com.br/</span>
    </a>
    <br>
    <br>
    <a href="https://app.medicar.com.br/politica-de-privacidade" class="link">
      <span>Política de Privacidade</span>
    </a>
    <br>
    <br>

    <a href="https://app.medicar.com.br/termos-de-uso" class="link">
      <span>Termos de Uso</span>
    </a>
    <br>
    <br>

    <a href="mailto:telemedicina@medicar.com.br" class="link">
      <span>telemedicina@medicar.com.br</span>
    </a>
    <br>
    <br>

    <a href="https://www.medicar.com.br/" class="link">
      <span> medicar.com.br</span>
    </a>
    <br>
    <br>


  </section>
</template>

<script>

export default {
  name: 'TermMedicar',
  components: {},
}
</script>
