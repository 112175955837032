<template lang="html">
  <section class="text-input input--wrapper">
    <label v-if="label">
      {{ label }} <span class="is--required" v-if="required">*</span>
    </label>

    <div class="input--content">
      <div v-if="hasPrependSlot" class="input--slot is--prepend">
        <slot name="prepend" />
      </div>

      <input
        type="password"
        autocomplete="off"
        :disabled="disabled"
        :maxlength="maxlength"
        :placeholder="placeholder"
        v-on="listeners"
        v-bind="$attrs"
        v-model="innerValue"
        :class="{ 'is--append': hasAppendSlot, 'is--prepend': hasPrependSlot }"
      />

      <div v-if="hasAppendSlot" class="input--slot is--append">
        <slot name="append" />
      </div>

      <i
        v-if="errorMsg"
        :title="errorMsg"
        class="input--warning fas fa-exclamation-circle"
      />
    </div>
    <span class="helptext" v-if="helptext">{{ helptext }}</span>
  </section>
</template>

<script>
import InputMixin from '../InputMixin'

export default {
  name: 'TextInputPassword',
  inheritAttrs: false,
  mixins: [InputMixin]
}
</script>
