<template>

<svg width="139" height="149" viewBox="0 0 139 149" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M129.115 91.123C122.758 84.7605 114.132 81.1842 105.138 81.1827C96.1437 81.1796 87.5165 84.7514 81.1558 91.1107C74.7951 97.4701 71.2217 106.096 71.2217 115.092C71.2217 124.086 74.7949 132.712 81.1558 139.071C87.5165 145.431 96.1437 149.003 105.138 148.999C114.132 148.998 122.758 145.421 129.115 139.059C135.447 132.689 139 124.072 139 115.092C139 106.11 135.447 97.4933 129.115 91.123ZM113.983 122.16C112.966 123.508 111.365 124.288 109.676 124.259C108.443 124.283 107.238 123.894 106.252 123.154L101.724 119.619C100.361 118.553 99.5509 116.932 99.5139 115.201V104.93C99.5232 101.883 101.99 99.4162 105.036 99.4071C106.506 99.3932 107.919 99.9696 108.958 101.008C109.996 102.048 110.574 103.461 110.559 104.93V112.551L112.989 114.429V114.428C114.154 115.317 114.915 116.634 115.101 118.087C115.288 119.539 114.885 121.006 113.983 122.16ZM19.8813 35.2342H19.8798C19.8798 25.89 23.5917 16.9268 30.1989 10.3194C36.8077 3.71198 45.7695 0 55.1131 0C64.4567 0 73.4184 3.71206 80.0273 10.3194C86.6345 16.9268 90.3464 25.8904 90.3464 35.2342C90.3464 44.5781 86.6344 53.5401 80.0273 60.149C73.4184 66.7564 64.4567 70.4684 55.1131 70.4684C45.7679 70.4684 36.8061 66.7564 30.1989 60.149C23.5917 53.5417 19.8782 44.5781 19.8782 35.2342H19.8813ZM73.2283 146.901C74.0018 147.675 74.7753 148.337 75.5473 149H16.5674C12.1711 149.006 7.95394 147.263 4.84598 144.154C1.73652 141.046 -0.0062943 136.829 1.70823e-05 132.432V120.173C0.0108032 109.923 4.08782 100.096 11.3345 92.8493C18.5811 85.6025 28.4072 81.5252 38.6572 81.5145H71.5715H71.57C72.7163 81.4776 73.8628 81.5515 74.9938 81.7349C74.3559 82.191 73.7626 82.7088 73.2279 83.282C64.8101 91.7276 60.0829 103.167 60.0829 115.093C60.0829 127.016 64.8101 138.456 73.2279 146.902L73.2283 146.901Z" fill="#6C6C6C"/>
</svg>

</template>

<script>
export default {
  name: 'IconWaitService',
  components: {
  },

  data: () => {
    return {
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>
