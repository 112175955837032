<style lang="scss" src="./InputFileProfile.scss" scoped></style>

<template>
  <section class="input-file" v-if="!multipleMax">
    <label class="box-img" for="idInputFile">
      <img v-if="base64" class="img" :src="base64" />
      <div v-else class="img empty">
        <IconCam />
        <span>Adicionar Foto</span>
      </div>
      <!-- <label for="idInputFile" class="pencil"
        ><i class="fas fa-pencil-alt"></i
      ></label> -->
    </label>

    <input
      id="idInputFile"
      class="input"
      type="file"
      accept="image/jpeg, image/png"
      :disabled="isDisabled"
      @change="previewFile"
    />
  </section>

  <section v-else class="multiple-input-file">
    <div
      class="input-file mr"
      v-for="item in boxToMultipleFiles"
      :key="item.key"
    >
      <div class="box-img">
        <img
          v-if="aBase64[item.index]"
          class="img"
          :src="aBase64[item.index]"
        />
        <div v-else class="img empty">Adicionar Foto</div>
        <label
          v-if="!aBase64[item.index]"
          :for="item.inputId"
          class="pencil"
          @click="clickSetSelectIdx(item.index)"
        >
          <i class="fas fa-pencil-alt" />
        </label>
        <label
          v-if="aBase64[item.index]"
          class="pencil"
          @click="clickMultRemove(item.index)"
        >
          <i class="fas fa-trash" />
        </label>
      </div>

      <input
        :id="item.inputId"
        class="input"
        type="file"
        accept="image/jpeg, image/png"
        :disabled="isDisabled"
        @change="previewMultipleFile"
      />
    </div>
  </section>
</template>

<script>
import IconCam from "@/assets/icons/IconCam";

export default {
  name: "ProviderInputFile",
  components: { IconCam },
  props: {
    fileBase64: String,
    filesBase64: Array,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    multipleMax: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      file: null,
      base64: null,

      selectIdx: null,
      aBase64: {},
      inputFile: null,
    };
  },

  computed: {
    multipleFileSize() {
      return this.filesBase64.length;
    },
    boxToMultipleFiles() {
      const size =
        this.multipleFileSize < this.multipleMax
          ? this.multipleFileSize + 1
          : this.multipleMax;

      return Array.from({ length: size }).map((_, index) => ({
        index,
        key: `if-${index}`,
        inputId: `idInputFile_${index}`,
      }));
    },
  },

  watch: {
    fileBase64(val) {
      this.base64 = val;
    },
    filesBase64(val) {
      this.setABase64(val);
    },
  },

  methods: {
    clickSetSelectIdx(idx) {
      this.selectIdx = idx;
    },

    async previewMultipleFile(fileTmp) {
      const idx = this.selectIdx;
      let file = fileTmp.target.files;
      if (file.length > 0) {
        file = file[0];
        this.file = file;
        this.fileToBase64(
          file,
          (res) => {
            this.aBase64[idx] = res;
            this.selectIdx = null;
            this.$emit("change", this.aBase64);
            this.$forceUpdate();
          },
          (err) => {
            console.log("ERRO", err);
          }
        );
      }
    },

    async previewFile(fileTmp) {
      let file = fileTmp.target.files;
      if (file.length > 0) {
        file = file[0];
        this.file = file;
        this.fileToBase64(
          file,
          (res) => {
            this.base64 = res;
            this.$emit("change", this.base64);
          },
          (err) => {
            console.log("ERRO", err);
          }
        );
      }
    },

    fileToBase64(file, resolve, reject) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    },

    setABase64(val) {
      this.aBase64 = [];
      for (let idx in val) {
        if (val[idx].url) {
          this.aBase64[Number(idx)] = val[idx].url;
        } else if (val[idx].base64) {
          this.aBase64[Number(idx)] = val[idx].base64;
        } else {
          console.error(
            "[InputFile] imagem nao contem key url ou base64",
            val[idx]
          );
        }
      }

      if (!val || val.length <= 0) {
        this.aBase64 = [];
      }

      this.$forceUpdate();
    },

    clickMultRemove(idx) {
      const input = document.getElementById(`idInputFile_${idx}`);

      if (input) {
        input.value = "";
      }

      this.$emit("remove", { file: this.filesBase64[idx], idx });
    },
  },
};
</script>
