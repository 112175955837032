<template>
  <section class="terms-section">
    <iframe v-if="privacyForm" :src="privacyForm" frameborder="0"></iframe>
    <div v-else>Termo de Privacidade não encontrado</div>
  </section>
</template>

<script>
export default {
  name: 'PrivacyTermIframe',
  data() {
    return {
    };
  }
};
</script>

<style src="./Term.scss" lang="scss"></style>
