<template lang="html">
  <section class="text-input-number input--wrapper">
    <label v-if="label">
      {{ label }} <span class="is--required" v-if="required">*</span>
    </label>

    <div class="input--content">
      <div v-if="hasPrependSlot" class="input--slot is--prepend">
        <slot name="prepend" />
      </div>

      <input
        ref="inputNumber"
        type="number"
        autocomplete="off"
        :disabled="disabled"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :min="min"
        :max="max"
        v-bind="$attrs"
        v-model="innerValue"
        v-on:change="onChange"
        @keydown="checkValue"
        :class="{
          'is--append': hasAppendSlot,
          'is--prepend': hasPrependSlot
        }"
      />

      <div v-if="hasAppendSlot" class="input--slot is--append">
        <slot name="append" />
      </div>

      <i
        v-if="errorMsg"
        :title="errorMsg"
        class="input--warning fas fa-exclamation-circle"
      />
    </div>

    <span v-if="helptext" class="helptext">{{ helptext }}</span>
  </section>
</template>

<script>
import InputMixin from '../InputMixin'

export default {
  name: 'TextInputNumber',
  mixins: [InputMixin],
  inheritAttrs: false,
  props: {
    helptext: String, // legenda da sigla
    min: {
      type: [Number, String],
      default: Number.MIN_SAFE_INTEGER
    },
    max: {
      type: [Number, String],
      default: Number.MAX_SAFE_INTEGER
    }
  },
  watch: {
    /**
     * When innerValue:
     *   1. Emit input event to update the user v-model.
     */
    innerValue (value) {
      this.checkInput(value)
    }
  },

  mounted () {
    this.$refs.inputNumber.addEventListener('mousewheel', this.handleScroll)
  },
  beforeDestroy () {
    this.$refs.inputNumber.removeEventListener('mousewheel', this.handleScroll)
  },
  methods: {
    handleScroll () {
      this.$refs.inputNumber.blur()
    },

    checkValueExist () {
      if (this.innerValue === '') {
        this.innerValue = ''
      } else {
        this.innerValue = this.innerValue * 1
      }
      return true
    },
    onChange (event) {
      const inputvalue = this.checkValue(event.currentTarget.value)
      const minmax = this.checkValueExist(event.currentTarget.value)

      if (parseFloat(event.currentTarget.value) < this.min) {
        this.innerValue = this.min
      } else if (parseFloat(event.currentTarget.value) > this.max) {
        this.innerValue = this.max
      }

      if (inputvalue === true && minmax === true) {
        this.$emit('input', this.innerValue)
      } else {
        return false
      }
    },
    checkInput (value) {
      if (value !== '' && parseFloat(value) > 0) {
        let newValue = value

        if (this.decimalLimit) {
          var regex = new RegExp(
            '^-?\\d+(?:\\.\\d{0,' + (this.decimalLimit || -1) + '})?'
          )
          newValue = parseFloat(newValue).toString().match(regex)[0]
        }

        if (this.maxlength && newValue.length > this.maxlength) {
          newValue = newValue.slice(0, this.maxlength)
        }

        this.innerValue = Number(newValue)
      }
    },
    checkValue (e) {
      if (e) {
        if (
          [46, 8, 9, 27, 13, 110, 108, 188].includes(e.keyCode) ||
          // Allow: Ctrl/cmd+A
          (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
          // Allow: Ctrl/cmd+C
          (e.keyCode === 67 && (e.ctrlKey === true || e.metaKey === true)) ||
          // Allow: Ctrl/cmd+X
          (e.keyCode === 88 && (e.ctrlKey === true || e.metaKey === true)) ||
          // Allow: home, end, left, right
          (e.keyCode >= 35 && e.keyCode <= 39)
        ) {
          // let it happen, don't do anything
          return
        }

        // Ensure that it is a number and stop the keypress
        if (
          (e.shiftKey ||
            e.keycode === 16 ||
            e.keyCode === 229 ||
            e.keyCode < 48 ||
            e.keyCode > 57) &&
          (e.keyCode < 96 || e.keyCode > 105)
        ) {
          e.preventDefault()
        }
      }
    }
  }
}
</script>
