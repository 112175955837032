<style src="./Button.scss" lang="scss"></style>

<template lang="html">
  <button
    class="pl-btn"
    :class="[
      { primary: primary },
      { red: red },
      { blue: blue },
      { orange: orange },
      { gray: gray },
      { ml: ml },
      { square: square },
      { actived: actived },
      { disabled: disabled },
    ]"
    :disabled="disabled"
    :title="title"
    @click="onClick"
  >
    <i v-if="arrowLeft" :class="{ 'no-line': noLine }" class="fas fa-chevron-left"/>
    <span v-if="label && label.length > 0">{{ label }}</span>
    <i v-if="cancel" :class="{ 'no-line': noLine }" class="fas fa-times icon-right"/>
    <i v-if="check" :class="{ 'no-line': noLine }" class="fas fa-check icon-right"/>
    <i v-if="trash" :class="{ 'no-line': noLine }" class="fas fa-trash icon-right"/>
    <i v-if="plus" :class="{ 'no-line': noLine }" class="fas fa-plus icon-right"/>
    <i v-if="refresh" :class="{ 'no-line': noLine }" class="fas fa-sync icon-right"/>
    <i v-if="save" :class="{ 'no-line': noLine }" class="fas fa-check icon-right"/>
    <i v-if="history" :class="{ 'no-line': noLine }" class="fas fa-history icon-right"/>
    <i v-if="cross" :class="{ 'no-line': noLine }" class="fas fa-times icon-right"/>
    <i v-if="search" :class="{ 'no-line': noLine }" class="fas fa-search icon-right"/>
    <i v-if="arrowRight" :class="{ 'no-line': noLine }" class="fas fa-chevron-right icon-right"/>
    <IconBroom v-if="clean" :class="{'no-line': isSmallOrNoLine, 'icon-right': !isSmallOrNoLine}" class="icon broom" />
  </button>
</template>

<script>
import IconBroom from "@/assets/icons/IconBroom";

export default {
  name: "Button",
  components: {
    IconBroom
  },
  props: {
    label: String,
    title: String,
    actived: Boolean,
    disabled: Boolean,

    noLine: Boolean,

    square: Boolean,
    ml: Boolean,

    red: Boolean,
    primary: Boolean,
    blue: Boolean,
    orange: Boolean,
    gray: Boolean,

    arrowLeft: Boolean,
    arrowRight: Boolean,

    check: Boolean,
    plus: Boolean,
    refresh: Boolean,
    trash: Boolean,
    cancel: Boolean,
    history: Boolean,
    save: Boolean,
    cross: Boolean,
    clean: Boolean,
    search: Boolean,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
