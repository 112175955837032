<template>

<svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.7235 13.2073V11.2535H11.9564V8.86999H16.7235V6.91625L20.5528 10.0423L16.7235 13.2073ZM13.3241 19.1075H10.589V5.54857C10.589 5.15771 10.3545 4.76714 9.96369 4.6107L1.9143 1.13306H13.3241V8.08835H14.4571L14.4574 0.586009C14.4574 0.273376 14.1838 0 13.8714 0H0.586008C0.273376 0 0 0.273656 0 0.586009V19.6935C0 20.0062 0.273655 20.2796 0.586008 20.2796H1.91448L9.18237 23.4056C9.84661 23.6793 10.5891 23.2102 10.5891 22.4677V20.2796H13.9106C14.2232 20.2796 14.4966 20.006 14.4966 19.6936L14.4963 12.035H13.3633L13.363 19.1075H13.3241Z" fill="#FF4242"/>
</svg>

</template>

<script>
export default {
  name: 'IconBtnExit',
  components: {
  },

  data: () => {
    return {
    }
  },

  mounted () {
  },

  methods: {
  }
}
</script>
