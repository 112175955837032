// *** *** ***
const PACIENTE_STATUS = {
  NULL: null,
  LIGANDO: 'ligando',
  LIGACAO: 'ligação'
}

// *** *** ***
// * COLECOES DO FIRESTORE
const COLLECTION = {
  PROFESSIONAL: 'funcionarios'
}


export default {
  PACIENTE_STATUS,
  COLLECTION
}